import React, { useState, useCallback, useEffect, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {PostDetailContext } from "../../App";
import { authorize } from "../../shared/authorize";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { _imagesURL, adminBaseURL } from "../../constants/API";
import ImageComponent from "../common/ImageComponent";
import { PROFILE } from "../../constants/RoutesUrl";
import { useNavigate } from "react-router-dom";
import Nowebinar from "../../assets/images/no_webinar.svg";

const WebinarInvited = ({ webinarId }) => {
  const [inviteList, setInviteList] = useState([]);
  const [inviteData, setInviteData] = useState(null);
  const [invitePage, setInvitePage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
  const { postModalClose } = useContext(PostDetailContext);

  const reservedUrl = "user/post/webinar/reserved/users/";

  const handleInvitationList = useCallback(
    async (page = invitePage) => {
      if (searchTerm) return;

      setIsLoadingMore(true);

      try {
        const res = await axios.get(
          `${adminBaseURL}${reservedUrl}${webinarId}?page=${page}`,
          authorize()
        );

        if (res.data.status) {
          if (page === 1) {
            console.log(res.data.data.data);
            setInviteList(res.data.data.data);
          } else {
            setInviteList((prev) => [...prev, ...res.data.data.data]);
          }
          setInviteData(res.data.data);
          setInvitePage(res.data.data.current_page + 1);
        } else {
          toast.error(res.data.error);
        }
      } catch (err) {
        toast.error(err.message);
      } finally {
        setIsLoadingMore(false);
      }
    },
    [webinarId, searchTerm, adminBaseURL]
  );

  useEffect(() => {
    handleInvitationList(1);
  }, [handleInvitationList]);

  const handleLoadMore = () => {
    handleInvitationList(invitePage);
  };

  if (!inviteList.length && isLoadingMore) {
    return (
      <div className="d-flex justify-content-center p-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <small
        className="mb-4 roboto-medium"
        style={{ borderBottom: ".3px solid #1DC1DD", paddingBottom: "3px" }}
      >
        {inviteData?.total || 0} Number of Attendees
      </small>
      <hr className="w-100 mt-0" />

      <div
        className="scroll-hide"
        style={{ height: "calc(62vh - 130px)", overflowY: "auto" }}
      >
        {(!inviteList || inviteList.length === 0) && !isLoadingMore ? (
          <div className="d-flex justify-content-center align-items-center">
            <img src={Nowebinar} alt="No Webinar" />
          </div>
        ) : (
          inviteList &&
          inviteList.map((attendee) => (
            <div
              key={attendee.id}
              className="list-group-item d-flex align-items-center gap-3 py-3 cursor manue-hover rounded px-2"
              onClick={() => {
                postModalClose();
                navigate(PROFILE + "/" + attendee.id);
              }}
            >
                <div
                className="bg-white rounded-circle align-self-center"
                style={{
                  padding: "2px",
                  width: "48px",
                  height: "48px",
                  border: "1px solid rgba(29, 193, 221, 1)",
                }}
              >
              <ImageComponent
                src={attendee?.image ? _imagesURL + attendee.image : Avatar}
                compClassName="rounded-circle border"
                img_height="100%"
                img_width="100%"
                roundShimmerSize={38}
              />
              </div>

              <div className="flex-grow-1">
                <h6 className="mb-0"> {attendee.name && attendee.name.length > 20 ? attendee.name.substring(0, 20) + "..."  : attendee.name}</h6>
                <small className="text-muted">
                  {attendee.subcategory && attendee.subcategory.length > 25
                    ? attendee.subcategory.substring(0, 25) + "..."
                    : attendee.subcategory}
                </small>
              </div>

              <button
                className="btn btn-sm roboto-medium fs-12"
                style={{
                  backgroundColor: "#F3FCFD",
                  border: "1px solid #D1D1D1",
                }}
              >
                View Profile
              </button>
            </div>
          ))
        )}
      </div>

      {inviteData && inviteData.current_page < inviteData.last_page && (
        <div className="text-center mt-4">
          <button
            className="btn btn-primary"
            onClick={handleLoadMore}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </>
            ) : (
              "Load More"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default WebinarInvited;
