import React, { useRef, useState } from 'react';
import { BiVideo } from 'react-icons/bi';
import Avatar from "../../../assets/images/icon/placeholder_Awatar.png";
import close from "../../../assets/images/icon/close-icon.svg";
import Cropper from "../../../../src/cropper/components/CroppersWizard.tsx"
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Language, LoginUserData } from '../../../App';
import { useContext } from 'react';
import { _imagesURL, adminBaseURL } from '../../../constants/API';
import ImageComponent from '../../common/ImageComponent';
import PostImageModal from './PostImageModal';
import { CiCamera } from 'react-icons/ci';
import { toast } from 'react-toastify';
import axios from 'axios';
import ImageShowModal from '../../common/ImageShowModal.jsx';

const PostTab = ({ onHide, setProgress, handleApiCall }) => {
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const { data: userToken } = JSON.parse(localStorage.getItem("SignupData"));
  const childRef = useRef(null);
  const [caption, setCaption] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const maxNumber = 6;
   const [images, setImages] = useState([]);
   const [imagesArr, setImagesArr] = useState([]);
   const [fileUploadRefs, setFileUploadRefs] = useState({});
   const [numberOfImages, setNumberOfImages] = useState(
     Object.keys(images).length < maxNumber
       ? Object.keys(images).length
       : maxNumber
   );
  const [ar, setAR] = useState(undefined);
  const [minARatio, setMinAR] = useState(undefined);
  const [maxARatio, setMaxAR] = useState(undefined);
  const [height, setHeight] = useState(undefined);
  const [width, setWidth] = useState(undefined);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [resizable, setResizable] = useState(true);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = useState(true);
  const [hasSettings, setHasSettings] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);

   // States for Image Preview in Modal
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const toggleImagePreview = () => setIsImagePreviewOpen(!isImagePreviewOpen);
    const handleImageClick = (img) => {
      setPreviewImage(img); // Set the clicked image for preview
      toggleImagePreview();
    };

  // Handle closing the cropper modal
  const handleClose = () => {
    setCropperOpen(false); // Close the cropper modal
  };
  const onImageRemove = (index) => {
    // Create copies of existing arrays/objects
    const updatedImages = [...imagesArr];
    const updatedFileUploadRefs = { ...fileUploadRefs };

    // Remove the image from arrays
    updatedImages.splice(index, 1);
    
    // Reset the file input value for the removed index
    if (fileUploadRefs[index+1] && fileUploadRefs[index+1].current) {
      fileUploadRefs[index+1].current.value = ''; // Clear the file input
    }
    
    // Update all states related to images
    setImages(updatedImages);
    setImagesArr(updatedImages);
    
    // Reorganize the fileUploadRefs
    const newFileUploadRefs = {};
    Object.keys(updatedFileUploadRefs)
      .filter(key => key !== index.toString())
      .forEach((key, newIndex) => {
        newFileUploadRefs[newIndex] = updatedFileUploadRefs[key];
      });
    
    setFileUploadRefs(newFileUploadRefs);
    
    // Update the number of images
    // setNumberOfImages(updatedImages.length + 1);

    // Reset settings if no images left
    if (updatedImages.length === 0) {
      setHasSettings(true);
      setAR(undefined);
      setMinAR(undefined);
      setMaxAR(undefined);
      setWidth(undefined);
      setHeight(undefined);
      setTop(0);
      setLeft(0);
      
      // Reset the cropper state
      setCropperOpen(false);
    }
  };

  const handleImageUploadClick = () => {
    const currentIndex = numberOfImages-1;
    if (fileUploadRefs[currentIndex] && fileUploadRefs[currentIndex].current) {
      // Reset the file input value before triggering the click
      fileUploadRefs[currentIndex].current.value = '';
      fileUploadRefs[currentIndex].current.click();
    }
  };
  const getVideoSource = (src) => {
    const url = URL.createObjectURL(src);
    setVideoSrc(src)
    setVideoUrl(url)
  }
  const handleUpload = async () => {
    // event.preventDefault();

    if (!videoSrc && imagesArr.length === 0) {
      toast.error(lang.media_is_required);
      return;
    }

    try {

      const data = {
        mediaBase64: imagesArr,
        media: [videoSrc],
        type: imagesArr.length > 0 ? "image" : "video",
        caption,
      };
      const response = await axios.post(
        adminBaseURL + "user/post/create",
        data,
        {
          onUploadProgress: (progressEvent) => {
            setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken?.token}`,
          },
        }
      );
      if (response.data.status) {
  
        return response; // Explicitly return the response

      } else {
        toast.error(response.data.error);


      }
    } catch (error) {
      console.error("Error uploading:", error);
      toast.error(lang?.failed_upload_please_try_again_later);

    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {

      setSelectedVideo(file);
      const url = URL.createObjectURL(file);
      setVideoSrc(file)
      setVideoUrl(url)
    }
  };
  const _handleSubmit = async () => {
    onHide(); // Close the popup
    await handleApiCall(() => handleUpload(), { type: "post" })
  }
  return (
    <>
    <div className="container">
  {/* Upper Section */}
  <div className="d-flex gap-3 align-items-center mb-3">
    {/* User Avatar */}
    <div
      className="rounded-circle"
      style={{
        border: "1px solid #1DC1DD",
        maxWidth: "48px",
        maxHeight: "48px",
        padding: "1px",
      }}
    >
      <ImageComponent
        src={
          splashData && splashData.user && splashData.user.image !== ""
            ? _imagesURL + splashData.user.image
            : Avatar
        }
        compClassName="rounded-circle"
        loader_height="44px"
        loader_width="44px"
        img_height="44px"
        img_width="44px"
        roundShimmerSize={44}
      />
    </div>

    {/* User Name and Username */}
    <div>
      <h6 className="mb-0">{splashData?.user?.name || "User Name"}</h6>
      <small className="text-muted mb-0">@{splashData?.user?.user_name || "username"}</small>
    </div>
  </div>

  {/* Text Area */}
  <div className="w-100">
    <textarea
      className="form-control border-0 shadow-none"
      placeholder={`${lang.whats_on_your_mind + " "+ splashData?.user?.name?.split(' ')[0]}?`}
      value={caption}
      onChange={(e) => setCaption(e.target.value)}
      rows="3"
      style={{ resize: "none" }}
    />
    <hr className="text-muted" style={{ marginLeft: "-96px", marginRight: "-37px" }} />
  </div>

  {/* Lower Section - Media Display */}
  <div
    className="d-flex gap-3 scroll-hide"
    style={{
      // marginLeft: "-82px",
      overflowX: "auto",
      overflowY: "hidden",
      flexWrap: "nowrap",
      maxWidth: "100%",
      whiteSpace: "nowrap",
    }}
  >
    
    {/* Display Images */}
    {imagesArr.length > 0 && (
      <div className="d-flex gap-1">
        {imagesArr.map((img, index) => (
          <div
            key={index}
            className="upload-poll-image-container"
            style={{
              flexShrink: 0,
              display: "inline-block",
              width: "auto",
              position: "relative",
            }}
          >
            <PhotoProvider speed={() => 800}>
              <PhotoView src={img}>
                <img
                  className="custom-image cursor"
                  onClick={() => handleImageClick(img)}
                  src={img}
                  alt="Uploaded"
                  style={{
                    width: "110px",
                    height: "110px",
                    objectFit: "cover",
                    border: "1px solid #a5a0a0",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
            <img
              className="c-pointer"
              aria-label="Close"
              src={close}
              style={{
                position: "absolute",
                top: "-1px",
                right: "-3px",
                width: "26px",
                height: "26px",
              }}
              alt="Remove"
              onClick={() => onImageRemove(index)}
            />
          </div>
        ))}
      </div>
    )}

    {/* Display Video */}
    {videoUrl && (
      <div
        className="custom-card mt-4 position-relative"
        style={{ flexShrink: 0, maxWidth: "220px", marginRight: "15px" }}
      >
        <video
          src={videoUrl}
          className="custom-image"
          style={{
            position: "relative",
            height: "93px",
            width: "100%",
            border: "1px solid #a5a0a0",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
            overflow: "hidden",
          }}
          controls
        />
        <img
          className="c-pointer"
          aria-label="Close"
          src={close}
          style={{
            position: "absolute",
            top: "-1px",
            right: "-3px",
            width: "26px",
            height: "26px",
          }}
          alt="Remove"
          onClick={() => {
            setVideoSrc(null);
            setVideoUrl(null);
            setSelectedVideo(null);
          }}
        />
      </div>
    )}
      {!videoUrl &&<>
      <Cropper
                  images={images}
                  setImages={setImages}
                  imagesArr={imagesArr}
                  setImagesArr={setImagesArr}
                  max={maxNumber}
                  allowCrop={true}
                  ar={ar}
                  minARatio={minARatio}
                  maxARatio={maxARatio}
                  setAR={setAR}
                  setMaxAR={setMaxAR}
                  setMinAR={setMinAR}
                  resizable={resizable}
                  setResizable={setResizable}
                  height={height}
                  setHeight={setHeight}
                  width={width}
                  setWidth={setWidth}
                  top={top}
                  setTop={setTop}
                  left={left}
                  setLeft={setLeft}
                  hasSettings={hasSettings}
                  setHasSettings={setHasSettings}
                  updateCoordinatesCheck={updateCoordinatesCheck}
                  setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
                  handleClose={handleClose}
                  fileUploadRefs={fileUploadRefs}
                  setFileUploadRefs={setFileUploadRefs}
                  numberOfImages={numberOfImages}
                  setNumberOfImages={setNumberOfImages}
                  cropperOpen={cropperOpen}
                  setCropperOpen={setCropperOpen}
                />
      </>}
  </div>

  {/* Footer Buttons */}
  <div className="d-flex justify-content-between align-items-center mt-3">
    {/* Left Buttons */}
    <div className="d-flex gap-3">
      {/* Image Upload Button */}
      <button
        onClick={() => {
          handleImageUploadClick()
          // fileUploadRefs[numberOfImages - 1].current.click();
        }}
        disabled={selectedVideo||imagesArr.length ===5}
        className="btn btn-light rounded-pill text-decoration-none text-secondary"
      >
        <CiCamera size={20} className="me-2" />
        {lang.images}
        {imagesArr.length > 0 && (
          <span
            className="px-2"
            style={{
              backgroundColor: "red",
              color: "white",
              borderRadius: "17px",
              border: "2px solid #fff",
              marginLeft: "5px",
            }}
          >
            {imagesArr.length}
          </span>
        )}
        
      </button>
    
      {/* Video Upload Button */}
      <button
        className="btn btn-light rounded-pill text-decoration-none text-secondary"
        onClick={() => {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = "video/*";
          input.addEventListener("change", handleFileChange);
          input.click();
        }}
        disabled={imagesArr.length > 0}
      >
        <BiVideo className="me-2" />
        {lang.videos}
      </button>
    </div>

    {/* Right Buttons */}
    <div className="d-flex gap-1">
      {/* Cancel Button */}
      
        <button
          style={{ color: '#848798', border: "1px solid #848798" }}
          className="btn px-3 me-2"
          onClick={onHide}
        >
          {lang.cancel}
        </button>
        <button
          className="btn px-4 btn-info text-white"
          disabled={!(imagesArr.length > 0 || videoSrc)}
          onClick={_handleSubmit}
        >
          {lang.upload}
        </button>
    </div>
  </div>
</div>

      <PostImageModal ref={childRef}  getVideoSource={getVideoSource} showModal={showImageModal} setShowModal={setShowImageModal} />
      <ImageShowModal
        isOpen={isImagePreviewOpen}
        toggle={toggleImagePreview}
        imageSrc={previewImage}
      />
    </>
  );
};

export default PostTab;

