import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import add_comment_img from "../../assets/images/icons/add-comment-img.png";
import { FaTimes } from 'react-icons/fa';  // Cross icon from react-icons for removing the image
import { AddCommentApi } from '../../constants/API';
import { authorize } from '../../shared/authorize';
import "../feeds/comments/commentsection.css"
import axios from 'axios';
import { ShimmerThumbnail } from "react-shimmer-effects";
import "../course-details/course-content-details/listShimmer.css";
import { Language } from '../../App';

const AddComment = ({setParentId, postId, replyId, comment, setComment, selectedImage, setSelectedImage, commentLoading, replyingTo, setReplyingTo,handleGetComments, parentId, handleGetReplies }) => {
  const [image, setImage] = useState(null); // State for image upload
  const [paddingLeft, setPaddingLeft] = useState(0); // Dynamic paddingLeft for the input
  const [loading,setLoading] = useState(false)
  const replyTextRef = useRef(null); // Ref to the "Replying to" text span for width calculation
  const textareaRef = useRef(null); // Ref to the textarea for height calculation
  // const [selectedImage, setSelectedImage] = useState(null)
    const { lang } = useContext(Language);

  // Update paddingLeft based on the length of the "Replying to" text
  useEffect(() => {
    if (replyingTo) {
      const width = replyTextRef.current ? replyTextRef.current.offsetWidth : 0;
      setPaddingLeft(width + 10); // Set padding to fit the "Replying to" text
    } else {
      setPaddingLeft(10); // Default padding
    }
  }, [replyingTo]);

  // Handle image upload click
  const handleImageClick = () => {
    document.getElementById('file-input').click();  // Trigger the hidden file input
  };

  // Focus on the textarea when replying to a comment
  useEffect(() => {
    if (replyingTo && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [replyingTo]);

  // Handle image change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.info(file)
      setImage(file)
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);  // Set the image preview
      };
      reader.readAsDataURL(file);  // Read the file as data URL
    }
  };

  // Handle comment text change
  const handleCommentChange = (event) => {
    let newValue = event.target.value;
    
    // If replyingTo exists, remove the "Replying to" prefix from the value before storing it in comment
    if (replyingTo) {
      const prefix = `${lang?.reply_to} ${replyingTo} `;
      if (newValue.startsWith(prefix)) {
        newValue = newValue.slice(prefix.length);
      }
    }
    
    setComment(newValue);
    
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      const lineHeight = 20; // Approximate height of one line in pixels
      const maxLines = 4;
      const newHeight = Math.min(textareaRef.current.scrollHeight, lineHeight * maxLines);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  // Handle removing the image preview
  const handleRemoveImage = () => {
    setImage(null);
    setSelectedImage(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && comment === "" && replyingTo) {
      event.preventDefault();
      setReplyingTo("");
      setParentId("")
    }
  };

  // Handle comment submission
  const handleSubmit = () => {
    if (!comment.trim() && !image) return; // Prevent empty submission
    
    console.log('Posted Comment:', comment);
    if (image) {
      console.log('Image Preview URL:', image);
    }
  
    // Reset the form after submission
    handleAddComment();
  };
  
  const handleAddComment = useCallback(async () => {
    if (loading) return;
  
    setLoading(true);
  
    try {
      // Create FormData to send the image properly
      const formData = new FormData();
      formData.append("comment", comment);
      formData.append("post_id", postId);
      
      if (image) {
        formData.append("image", image);
      }
  
      if (parentId) {
        formData.append("parent", parentId);
      }
      if (replyId) {
        formData.append("mentions", replyId)
      }
  
      const response = await axios.post(
        `${AddCommentApi}`,
        formData,
        {
          headers: {
            ...authorize().headers, // Merge existing headers
            "Content-Type": "multipart/form-data", // Ensure multipart data
          },
        }
      );
  
      if (response.data.status) {
        setComment("");
        handleGetComments();
        parentId && handleGetReplies(parentId);
        setImage(null);
        setReplyingTo('');
        setSelectedImage(null);
        setParentId('');
      }
    } catch (error) {
      console.error("Error uploading comment:", error);
    } finally {
      setLoading(false);
    }
  }, [comment, postId, image, parentId]);
  
  return (
    <>
    {commentLoading ? 
    <div className="d-flex align-items-center justify-content-between p-2 custom_list_shimmer">
      <div className="d-flex align-items-center gap-3 custom_list_shimmer">
        <ShimmerThumbnail height={30} width={40} rounded />
        <ShimmerThumbnail height={30} width={160} rounded />
      </div>
      <ShimmerThumbnail height={30} width={70} rounded />
    </div>
    :

      <div className="d-flex mb-2 mt-2 align-items-center p-2 gap-2">
        {/* Image upload trigger */}
        <div style={{ position: "relative" }}>
          {selectedImage ? (
            <div>
              <img
                src={selectedImage}
                alt="preview"
                width={30}
                height={32}
                style={{ borderRadius: "4px", border: "1px solid #e5e7e7" }}
              />
              <FaTimes
                onClick={handleRemoveImage}
                style={{
                  background: "#4e4f4f",
                }}
                className="position-absolute top-0 end-0 rounded-circle text-white fs-15 cursor"
              />
            </div>
          ) : (
            <>
              {!replyingTo && (
                <img
                  className="cursor"
                  width={28}
                  onClick={handleImageClick}
                  src={add_comment_img}
                  alt="add comment"
                />
              )}
            </>
          )}
        </div>

        {/* Hidden file input for selecting an image */}
        <input
          type="file"
          id="file-input"
          style={{ display: "none" }}
          onChange={handleImageChange}
          accept="image/*"
        />

        {/* Text input for the comment */}
        <div className='comment_add' style={{ position: "relative", width: "100%" }}>
          <textarea
            type="text"
            value={replyingTo ? `${lang?.reply_to} ${replyingTo} ${comment}` : comment}
            onChange={handleCommentChange}
            onKeyDown={handleKeyDown}
            placeholder={lang?.add_comment}
            className="form-control outfit-regular border-0 text-black flex-grow-1 scroll-hide position-relative overflow-y-auto"
            ref={textareaRef}
            style={{
              paddingLeft: `${paddingLeft}px`,
              minHeight: "20px",
              maxHeight: "80px",
              lineHeight: "20px",
            }}
            rows={1}
          />

        </div>
          {/* "Replying to" text */}
          {/* {replyingTo && (
            <div
              ref={replyTextRef}
              style={{
                position: "absolute",
                top: "5px",
                left: "6px",
                color: "deepskyblue",
                zIndex: 0,
                pointerEvents: "none",
              }}
            >
              {lang?.reply_to} {replyingTo}
            </div>
          )} */}

        {/* Post button */}
        <button
          className="border-0 bg-transparent roboto-bold text-black me-2"
          disabled={(!comment.trim() && !image) || loading}
          onClick={handleSubmit}
        >
          {loading ? <span class="post_loader"></span> : lang?.post}
        </button>
      </div>
    }
    </>
  );
};

export default AddComment;