import React, { useState } from "react";
import linkedinLogo from "../../assets/images/medicalradaricon.png"; // Ensure the path is correct

const MedicalRadarLoader = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center" style={{height: '80vh'}}> 
      <img
        src={linkedinLogo}
        alt="Medical Radar Logo"
        style={{
          width: "250px",
          marginBottom: "25px",
          
          display: imageLoaded ? "block" : "none",
        }}
        onLoad={() => setImageLoaded(true)}
      />
      {imageLoaded && (
        <div
          style={{
            width: "150px",
            height: "4px",
            backgroundColor: "#e0e0e0",
            borderRadius: "5px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {/* <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#1dc1dd",
              position: "absolute",
              transform: "translateX(-100%)",
              animation: "loaderAnimation 2s infinite linear",
            }}
          ></div> */}
          <div
  style={{
    width: "50%",
    height: "100%",
    backgroundColor: "#1dc1dd",
    position: "absolute",
    transform: "translateX(-50%)",
    animation: "loaderAnimation 2s infinite linear",
  }}
></div>
        </div>
      )}

      {/* Inject CSS for animation */}
      <style>
        {/* {`
          @keyframes loaderAnimation {
            0% { transform: translateX(-50%); }
            50% { transform: translateX(100%); }
            100% { transform: translateX(-50%); }
          }
        `} */}
         {`
    @keyframes loaderAnimation {
      0% { transform: translateX(-50%); }
      50% { transform: translateX(120%);}
      100% { transform: translateX(-50%);}
    }
  `}
      </style>
    </div>
  );
};

export default MedicalRadarLoader;
