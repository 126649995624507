import React from "react";
import { Modal } from "reactstrap"; // Ensure you have reactstrap installed

const ImageShowModal = ({ isOpen, toggle, imageSrc }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <img
        src={imageSrc}
        alt="Full Preview"
        className="img-fluid rounded object-fit-cover"
        style={{ maxHeight: "80vh", maxWidth: "100%" }}
      />
    </Modal>
  );
};

export default ImageShowModal;
