import React, { FC } from 'react';
import cn from 'classnames';
import { FlipHorizontalIcon } from '../../../icons/FlipHorizontalIcon.tsx';
import { RotateRightIcon } from '../../../icons/RotateRightIcon.tsx';
import { RotateLeftIcon } from '../../../icons/RotateLeftIcon.tsx';
import { FlipVerticalIcon } from '../../../icons/FlipVerticalIcon.tsx';
import { ResetIcon } from '../../../icons/ResetIcon.tsx';
import './Navigation.scss';

interface Props {
  onRotate?: (angle: number) => void;
  onFlip?: (horizontal: boolean, vertical: boolean) => void;
  onReset?: () => void;
  changed?: boolean;
  className?: string;
  disabled?: unknown;
}

export const Navigation: FC<Props> = ({ className, disabled, changed, onReset, onRotate, onFlip }) => {
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleAction = (action) => {
    if (isProcessing || disabled) return;
    setIsProcessing(true);
    action();
    setTimeout(() => setIsProcessing(false), 300);
  };

  const rotateLeft = () => {
    if (onRotate && !disabled && !isProcessing) {
      handleAction(() => onRotate(-90));
    }
  };

  const rotateRight = () => {
    if (onRotate && !disabled && !isProcessing) {
      handleAction(() => onRotate(90));
    }
  };

  const flipHorizontal = () => {
    if (onFlip && !disabled && !isProcessing) {
      handleAction(() => onFlip(true, false));
    }
  };

  const flipVertical = () => {
    if (onFlip && !disabled && !isProcessing) {
      handleAction(() => onFlip(false, true));
    }
  };

  const reset = () => {
    if (onReset && !disabled && !isProcessing) {
      handleAction(onReset);
    }
  };

  return (
    <div className={cn('default-cropper-navigation', className)}>
      <button
        className={cn(
          'default-cropper-navigation__button',
          isProcessing && 'default-cropper-navigation__button--disabled'
        )}
        onClick={flipHorizontal}
        disabled={isProcessing || disabled}
      >
        <FlipHorizontalIcon />
      </button>
      <button
        className={cn(
          'default-cropper-navigation__button',
          isProcessing && 'default-cropper-navigation__button--disabled'
        )}
        onClick={rotateRight}
        disabled={isProcessing || disabled}
      >
        <RotateRightIcon />
      </button>
      <div className="default-cropper-navigation__delimiter">
        <div
          className={cn(
            'default-cropper-navigation__dot',
            changed && 'default-cropper-navigation__dot--hidden'
          )}
        />
        <button
          className={cn(
            'default-cropper-navigation__reset-button',
            !changed && 'default-cropper-navigation__reset-button--hidden',
            isProcessing && 'default-cropper-navigation__button--disabled'
          )}
          onClick={reset}
          disabled={isProcessing || disabled}
        >
          <ResetIcon />
        </button>
      </div>
      <button
        className={cn(
          'default-cropper-navigation__button',
          isProcessing && 'default-cropper-navigation__button--disabled'
        )}
        onClick={rotateLeft}
        disabled={isProcessing || disabled}
      >
        <RotateLeftIcon />
      </button>
      <button
        className={cn(
          'default-cropper-navigation__button',
          isProcessing && 'default-cropper-navigation__button--disabled'
        )}
        onClick={flipVertical}
        disabled={isProcessing || disabled}
      >
        <FlipVerticalIcon />
      </button>
    </div>
  );
};