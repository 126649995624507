
import axios from "axios";
import { LOGIN } from "../constants/RoutesUrl";
import { authorize } from "../shared/authorize";
import { api, apiAdmin } from "./api";
import { toast } from 'react-toastify';
import { adminBaseURL, FeedPostApi, FollowApi } from "../constants/API";
import { getToken } from "../shared/token";


export const fetchFeedData = async ({feedType}) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
const {data} = JSON.parse(localStorage.getItem("SignupData"));

    try {
        const response = await apiAdmin.post(
            `user/web/home`,
            { user_id,type:feedType }, {
                headers: {
                  Authorization: `Bearer ${data.token}`, // Ensure token is passed correctly
                },
              });
        return response.data;
    } catch (error) {
        toast.error('Error fetching feed data:', error);
        throw error;
    }


};
export const fetchFeedPostData = async ({feedType, pageParam = 1}) => {
    const response = await axios.get(`${FeedPostApi + feedType}?page=${pageParam}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
export const likePostApi = async ({post_id}) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await apiAdmin.get(`user/post/like/${post_id}`,authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching feed data:', error);
        throw error;
    }
};

export const savePostApi = async ({post_id}) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await apiAdmin.get(`user/post/save/${post_id}`,authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching feed data:', error);
        throw error;
    }
};

export const repostPostApi = async ({post_id}) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await apiAdmin.get(`user/post/repost/${post_id}`,authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching feed data:', error);
        throw error;
    }
}

export const usersLikedPostApi = async ({post_id, page}) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await apiAdmin.get(`user/post/likes/${post_id}?page=${page}`,authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching liked users data:', error);
        throw error;
    }
}

export const reserveWebinarApi = async ({post_id}) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await apiAdmin.get(`user/post/reserve/${post_id}`,authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching reserve webinar data:', error);
        throw error;
    }
}

export const followUserApi = async (id) => {
    try {
        const response = await axios.get(`${FollowApi}${id}`,authorize());
        return response.data;
    } catch (error) {
        toast.error('Something went Wrong', error);
        throw error;
    }
}

