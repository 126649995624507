import React from 'react'

function RadioOption({ option, isSelected, disabled, border }) {

    return (
      <div className="d-flex align-items-center">
        <input
          type="radio"
          className="me-3 fs-20"
          checked={isSelected}
          style={{
            border: border,
            width: "20px",
            height: "20px",
          }}
          disabled={disabled}
        />
        <label
          className={`fs-20 text-black ${isSelected ? "fw-bold" : "fw-medium"}`}
        >
          {option}
        </label>
      </div>
    );
  }
  
  export default RadioOption;
