import { useContext, useEffect, useState } from 'react';
import { Language } from '../App';

const usePollRemainingTime = (timestamp, duration) => {
    const {lang} = useContext(Language)
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    const calculateRemainingTime = () => {
      const givenDate = new Date(timestamp * 1000); // Convert to milliseconds
      let timeInterval = 0;
      const durationComponents = duration?.split(' ');

      if (!durationComponents || durationComponents.length !== 2) {
        setRemainingTime(null); // Invalid duration format
        return;
      }

      const value = parseInt(durationComponents[0]);
      if (isNaN(value)) {
        setRemainingTime(null); // Invalid duration value
        return;
      }

      const unit = durationComponents[1].toLowerCase();

      // Calculate the time interval based on the unit (day, week)
      if ((value === 1 && unit === 'day') || (value > 1 && unit === 'days')) {
        timeInterval = value * 24 * 60 * 60 * 1000; // Days to milliseconds
      } else if ((value === 1 && unit === 'week') || (value > 1 && unit === 'weeks')) {
        timeInterval = value * 7 * 24 * 60 * 60 * 1000; // Weeks to milliseconds
      } else {
        setRemainingTime(null); // Unsupported or mismatched duration unit
        return;
      }

      // Calculate the expiration date
      const expirationDate = new Date(givenDate.getTime() + timeInterval);

      // Calculate the remaining time
      const now = new Date();
      if (expirationDate > now) {
        const remainingInterval = expirationDate - now;

        if (remainingInterval < 24 * 60 * 60 * 1000) {
          // Less than 24 hours
          const remainingHours = Math.floor(remainingInterval / (60 * 60 * 1000));
          if (remainingHours < 1) {
            // Less than 1 hour
            const remainingMinutes = Math.floor(remainingInterval / 60000);
            const minuteLabel = remainingMinutes > 1 ? 'minutes left' : 'minute left';
            const minuteLabelEs = remainingMinutes > 1 ? 'minutos' : 'minuto';

            let result;
            if (navigator.language.startsWith('en')) {
              result = `${remainingMinutes} ${minuteLabel}`;
            } else {
              const quedaOrQuedan = remainingMinutes === 1 ? 'Queda' : 'Quedan';
              result = `${quedaOrQuedan} ${remainingMinutes} ${minuteLabelEs}`;
            }

            setRemainingTime(result);
          } else {
            const hourLabel = remainingHours > 1 ? 'hours left' : 'hour left';
            const hourLabelEs = remainingHours > 1 ? 'horas' : 'hora';

            let result;
            if (navigator.language.startsWith('en')) {
              result = `${remainingHours} ${hourLabel}`;
            } else {
              const quedaOrQuedanHour = remainingHours === 1 ? 'Queda' : 'Quedan';
              result = `${quedaOrQuedanHour} ${remainingHours} ${hourLabelEs}`;
            }

            setRemainingTime(result);
          }
        } else {
          // Calculate remaining days, weeks, and days in the current week
          const remainingDays = Math.floor(remainingInterval / (24 * 60 * 60 * 1000));
          const remainingWeeks = Math.floor(remainingDays / 7);
          const remainingDaysInWeek = remainingDays % 7;
        
          // Labels for English
          const dayLabel = remainingDays > 1 ? 'days left' : 'day left';
          const weekLabel = remainingWeeks > 1 ? 'weeks left' : 'week left';
        
          // Labels for Spanish
          const dayLabelEs = remainingDays > 1 ? 'días' : 'día';
          const weekLabelEs = remainingWeeks > 1 ? 'semanas' : 'semana';
        
          let result;
        
          if (navigator.language.startsWith('en')) {
            if (remainingWeeks > 0 && remainingDaysInWeek === 0) {
              // Only full weeks left
              result = `${remainingWeeks} ${weekLabel}`;
            } else if (remainingWeeks > 0) {
              // Total days calculation
              const totalDays = remainingWeeks * 7 + remainingDaysInWeek;
              result = `${totalDays} ${dayLabel}`;
            } else {
              // Only days left
              result = `${remainingDays} ${dayLabel}`;
            }
          } else {
            const quedaOrQuedanDays = remainingDays === 1 ? 'Queda' : 'Quedan';
            const quedaOrQuedanWeeks = remainingWeeks === 1 ? 'Queda' : 'Quedan';
        
            if (remainingWeeks > 0 && remainingDaysInWeek === 0) {
              // Only full weeks left
              result = `${quedaOrQuedanWeeks} ${remainingWeeks} ${weekLabelEs}`;
            } else if (remainingWeeks > 0) {
              // Total days calculation
              const totalDays = remainingWeeks * 7 + remainingDaysInWeek;
              result = `${quedaOrQuedanDays} ${totalDays} ${dayLabelEs}`;
            } else {
              // Only days left
              result = `${quedaOrQuedanDays} ${remainingDays} ${dayLabelEs}`;
            }
          }
        
          setRemainingTime(result);
        }
      } else {
        setRemainingTime(lang?.poll_closed || 'Poll closed'); // Poll is closed if expiration is in the past
      }
    };

    calculateRemainingTime();

    // Update the remaining time every minute
    const interval = setInterval(calculateRemainingTime, 60000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [timestamp, duration]);

  return remainingTime;
};

export default usePollRemainingTime;