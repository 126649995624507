import axios from "axios";
import { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SpinnerGif from "../../assets/images/Spinner.gif";
import { _imagesURL, adminBaseURL, BaseURL, RecomendationApi } from "../../constants/API";
import { Language } from "../../App";
import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { authorize } from "../../shared/authorize";

export default function ProfileSuggestionModal({
  preview,
  updatePreview,
  list,
  updateList,
  toFollow,
  loading,
}) {
  const userToken = JSON.parse(localStorage.getItem("SignupData"));
  const user_id = userToken.data.id;
  const { lang } = useContext(Language);

  const [hasMoreFollowing, setHasMoreFollowing] = useState(true);
  const [pagefollowing, setPagefollowing] = useState(1);

  const handleRecommendationList = () => {
    axios
      .get(
        RecomendationApi +
          "?page=" +
          JSON.stringify(pagefollowing + 1)
      ,authorize())
      .then((res) => {
        const updatedValue = res.data.data.data;
        updateList((prevData) => [...prevData, ...updatedValue]);
        setPagefollowing(pagefollowing + 1);

        if (res.data.data.current_page >= res.data.data.last_page) {
          setHasMoreFollowing(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  

  return (
    <Modal
      scrollable
      centered
      zIndex={9}
      isOpen={preview}
      toggle={updatePreview}
      backdrop="static"
    >
      <ModalHeader toggle={updatePreview}>{lang?.recommendations}</ModalHeader>
      <ModalBody className="scroll-hide">
        <InfiniteScroll
          pageStart={0}
          loadMore={handleRecommendationList}
          hasMore={hasMoreFollowing}
          loader={
            <div className="w-100 text-center" key={0}>
              <img src={SpinnerGif} alt="..." width={30} height={30} />
            </div>
          }
          useWindow={false}
        >
          {list &&
            list.map((value, index) => (
              <>
                <div
                  className="d-flex p-2 justify-content-between manue-hover rounded"
                  key={index}
                >
                  <Link
                    to={PROFILE + "/" + value.id}
                    className="d-flex gap-2 w-100 text-dark"
                  >
                    <div
                      className="bg-white dropdown-avatar-shadow align-self-center rounded"
                      style={{
                        maxWidth: "3rem",
                        maxHeight: "3rem",
                        padding: "1.8px",
                      }}
                    >
                      <ImageComponent
                        src={value?.image ? _imagesURL + value.image : Avatar}
                        compClassName="img-fluid rounded"
                        loader_height="3rem"
                        loader_width="3rem"
                      />
                    </div>
                    

                    <div className="align-self-center">
                      <div className="fs-17 roboto-bold text-dark text-ellips-110px">
                        {value.name}
                      </div>
                      <p className="mb-0 fs-12 roboto-regular text-ellips-110px">
                        {value.user_name}
                      </p>
                    </div>
                  </Link>
                  <div className="align-self-center">
                    <button
                      disabled={loading}
                      className="btn btn-white border border-1 px-3 py-1"
                      onClick={() => {
                        toFollow(value.id);
                      }}
                    >
                      <span className="roboto-medium">
                        {value.follow ? lang?.following : lang?.follow}
                      </span>
                    </button>
                  </div>
                </div>
                {index !== 3 && (
                  <hr className="m-0" style={{ opacity: "6%" }} />
                )}
              </>
            ))}
        </InfiniteScroll>
      </ModalBody>
    </Modal>
  );
}
