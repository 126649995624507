import { useContext, useState } from "react";
import { Language } from "../../App";

export const ReadMore = ({ text }) => {
    const [expanded, setExpanded] = useState(false);
    const { lang } = useContext(Language);
  
    const toggleReadMore = () => {
      setExpanded(!expanded);
    };
    const processTextWithHashtags = (text) => {
      return text?.split(/(#[\w]+)|(\n)|(http\S+)/g)?.map((part, index) => {
        if (!part) return null; // Skip empty parts
  
        // If the part is a hashtag
        if (part.startsWith("#")) {
          return (
            <span key={index} className="cursor" style={{ color: "#1DC1DD" }}>
              {part}
            </span>
          );
        }
  
        // If the part is a URL
        if (part.startsWith("http")) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#1dc1dd", textDecoration: "underline" }}
            >
              {part}
            </a>
          );
        }
  
        // If the part is a newline
        if (part === "\n") {
          return <br key={index} />;
        }
  
        // Regular text
        return part;
      });
    };
  
    return (
      <div>
        {expanded ? (
          <div>
            <p
              className="roboto-regular mb-0 fs-14"
              style={{
                wordWrap: "break-word",
                color: "#434343",
              }}
            >
              {processTextWithHashtags(text)}
            <span
              onClick={toggleReadMore}
              className="ms-2 roboto-bold text-blue cursor fs-14 cursor"
              style={{ color: "#1DC1DD" }}
            >
              {lang?.see_less}
            </span>
            </p>
          </div>
        ) : (
          <div>
            <p
              className="roboto-regular mb-0 fs-14"
              style={{ wordWrap: "break-word", color: "#434343" }}
            >
              {processTextWithHashtags(text?.slice(0, 240))}
              {text?.length > 240 && (
                <>
                  ...{" "}
                  <span
                    onClick={toggleReadMore}
                    className="roboto-bold text-blue cursor fs-14"
                    style={{ color: "#1DC1DD" }}
                  >
                    {lang?.see_more}
                  </span>
                </>
              )}
            </p>
          </div>
        )}
      </div>
    );
  };