import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from 'react-icons/io';
import { Carousel } from 'react-responsive-carousel';
import ImageComponent from '../components/common/ImageComponent';
import VideoWithAspectRatio from '../components/common/VideowithAspectRatio.tsx';
import ImageWithAspectRatio from '../components/common/ImagewithAspectRatio';
import PostHeader from '../components/feeds/PostHeader';
import PostFooter from '../components/feeds/PostFooter';
import PollOptionsView from './PollOptionsView';
import PollResultsView from './PollResultsView';
import { PROFILE } from '../constants/RoutesUrl';
import { toast } from 'react-toastify';
import { GoClock, GoScreenFull } from 'react-icons/go';
import Avatar from "../assets/images/icon/placeholder_Awatar.png";
import { _imagesURL } from '../constants/API';
import { Language } from '../App.js';
import { IoCalendarOutline, IoCloseSharp } from 'react-icons/io5';
import { ReadMore } from '../components/common/ReadMore.jsx';

const PostCard = ({
  value,
  postDropDown,
  setPostDropDown,
  setReportModal,
  setDropDownId,
  dropDownId,
  handleRepost,
  handleSave,
  handleFollow,
  setShowPost,
  editWebinarTab,
  handlePostSelect,
  updatePost,
  deletePost,
  updatePostDetail,
  handleLikeUsers,
  handleLike,
  handleGetComments,
  setPostModal,
  setPostId,
  handleReserveWebinar,
  handlePollAnswer,
  toggleModal,
  removePollAnswer,
  refetch,
  playingVideo,
  handleVideoPlay,
  indicatorStyles
}) => {
    const {lang} = useContext(Language)
    const userData = localStorage.getItem("SignupData");
    const user = userData ? JSON.parse(userData) : null;
    const user_id = user?.data?.id;
    const [modalOpen, setModalOpen] = useState(false);
      const [pdfModalOpen, setPdfModalOpen] = useState(false);
      const [isFullScreen, setIsFullScreen] = useState(false);
      const [fileToView, setFileToView] = useState({ type: null, media: null });

      const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
      };
      const [isChrome, setIsChrome] = useState(false);

      useEffect(() => {
        // Check if the browser is Chrome
        const userAgent = navigator.userAgent.toLowerCase();
        if (
          userAgent.indexOf("chrome") > -1 &&
          userAgent.indexOf("edge") === -1
        ) {
          setIsChrome(true);
        }
      }, []);

    const openModal = (file,type) => {
      setFileToView({...fileToView,type:type,media:file}); // Set the file to be viewed in the modal
      setPdfModalOpen(true); // Open the modal
    };
  
    const closeModal = () => {
      setPdfModalOpen(false); // Close the modal
      setFileToView(null); // Clear the file to view state
    };

  const renderRepostHeader = () => {
    if (!value.repost_user?.name) return null;
    
    return (
      <>
        <Row>
          <Col className="d-flex">
            <div
              className="bg-white rounded-circle align-self-center br-16 ms-3"
              style={{
                width: "50px",
                height: "50px",
                padding: "2px",
                border: "1px solid rgba(29, 193, 221, 1)",
              }}
            >
              <Link to={`${PROFILE}/${value.repost_user.id}`}>
                <ImageComponent
                  src={value.repost_user.image ? _imagesURL + value.repost_user.image : Avatar}
                  img_height="100%"
                  img_width="100%"
                  loader_height="100%"
                  loader_width="100%"
                  compClassName="rounded-circle"
                  roundShimmerSize={45}
                />
              </Link>
            </div>
            <div className="align-self-center">
              <h6 className="mx-3 mb-0">
                <Link className="hover-blue" to={`${PROFILE}/${value.repost_user.id}`}>
                  {value.repost_user.name}{" "}
                </Link>
                {lang?.reposted_this}
              </h6>
            </div>
          </Col>
        </Row>
        <hr style={{ opacity: "0.1px" }} />
      </>
    );
  };

  const renderMedia = () => {
    if (!value.media?.length) return null;

    if (value.type === "video") {
      return value.media.map((media, index) => (
        <VideoWithAspectRatio
          key={index}
          index={index}
          src={_imagesURL + media}
          aspectRatio={555 / value.sizes}
          setPlayingVideo={handleVideoPlay}
          playingVideo={playingVideo}
        />
      ));
    }

    if (value.media.length === 1) {
      return value.media.map((media, index) => (
        <ImageWithAspectRatio
          key={index}
          height={555 / value.sizes}
          src={_imagesURL + media}
          alt="..."
        />
      ));
    }

    return (
      <Carousel
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <IoIosArrowDroprightCircle
              className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto end-0 me-3"
              size={30}
              color="white"
              onClick={(e) => {
                e.stopPropagation();
                onClickHandler();
              }}
            />
          )
        }
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <IoIosArrowDropleftCircle
              className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto ms-3 start-0"
              size={30}
              style={{ zIndex: "1" }}
              color="white"
              onClick={(e) => {
                e.stopPropagation();
                onClickHandler();
              }}
            />
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <li
            style={{
              ...indicatorStyles,
              background: isSelected ? "#00c5de" : "#ffffff",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClickHandler();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              onClickHandler();
            }}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        )}
        autoPlay={false}
        infiniteLoop={false}
        showIndicators={true}
        showThumbs={false}
        showStatus={false}
      >
        {value.media.map((media, index) => (
          <ImageWithAspectRatio
            key={index}
            height={555 / value.sizes}
            src={_imagesURL + media}
            alt="..."
          />
        ))}
      </Carousel>
    );
  };

  const renderWebinarContent = () => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB", {
          weekday: "short",
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
      };
      function handleWebinarClick(value) {
        const userIsHost = value.user.id === user_id;
        const isReserve = value.isReserve;
        const status = value.status;
      
        if (userIsHost) {
          // If the user is the host
          if (status === 0) {
            return null;
          } else if (status === 2) {
            return lang.webinar_ended;
          } else {
            // User is the host and the webinar is active
            window.open(value.meeting_host_url, "_blank");
          }
        } else {
          // If the user is not the host
          if (status === 0) {
            // Status is 0, handle reservation
            if (isReserve) {
              handleReserveWebinar(value.id);
            } else {
              handleReserveWebinar(value.id);
            }
          } else if (status === 1) {
            // Webinar has started
            if (isReserve) {
              if (userIsHost) {
                window.open(value.meeting_host_url, "_blank");
              } else {
                if (value.meeting_url !== "") {
                  window.open(value.meeting_url, "_blank");
                } else {
                  toast.info("Not started yet.");
                }
              }
            } else {
              handleReserveWebinar(value.id);
            }
          } else if (status === 2) {
            // Webinar has ended
            toast.info(lang.webinar_is_ended);
          }
        }
      }
      function getWebinarButtonText(value) {
        const userIsHost = value.user.id === user_id;
        const status = value.status;
        const isReserve = value.isReserve;
      
        if (userIsHost) {
          // User is the host
          if (status === 0) {
            return lang.not_start_yet;
          } else if (status === 2) {
            return lang.webinar_ended;
          } else {
            return lang.start_webinar;
          }
        } else {
          // User is not the host
          if (status === 0) {
            // Webinar not started
            return isReserve ? lang.unreserve : lang.reserve_seat;
          } else if (status === 1) {
            // Webinar has started
            return isReserve ? lang.join_webinar : lang.reserve_seat;
          } else if (status === 2) {
            // Webinar ended
            return lang.webinar_ended;
          }
        }
      }
       
const webinarStatus = getWebinarButtonText(value);
const webinarDate = formatDate(value.schedule_date);
    if (value.type !== "webinar") return null;

    return (
      <Row className="justify-content-between px-3">
        <Col className="align-self-center">
                              <div className="d-flex gap-2 mb-2">
                                <GoClock className="text-warning" />
                                <small className="mb-0 text-warning roboto-medium">
                                  {value.schedule_time}
                                </small>
                              </div>
                              <div className="d-flex gap-2">
                                <IoCalendarOutline className="text-warning" />
                                <small className="mb-0 text-warning roboto-medium">
                                  {webinarDate}
                                </small>
                              </div>
                            </Col>
        <Col className="text-end align-self-center">
        <button
                                className={`px-4 py-2 fw-bold ${webinarStatus === "Unreserve Seat" ? "text-danger border-danger bg-white" : "follow-btn border-0 text-white"} rounded`}
                                onClick={() => handleWebinarClick(value)}
                              >
                               {webinarStatus}
                              </button>
        </Col>
      </Row>
    );
  };

  const renderPollContent = () => {
    if (value.type !== "poll") return null;

    return value.right === 0 && user_id !== value.user.id ? (
      <PollOptionsView
        value={value}
        handlePollAnswer={handlePollAnswer}
        toggleModal={toggleModal}
      />
    ) : (
      <PollResultsView
        value={value}
        toggleModal={toggleModal}
        removePollAnswer={removePollAnswer}
      />
    );
  };

  return (
    <div
      className="bg-white my-3 py-3 rounded"
      style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
    >
      {renderRepostHeader()}
      
      <PostHeader
        refetch={refetch}
        postDetails={value}
        postDropDown={postDropDown}
        setPostDropDown={setPostDropDown}
        setReportModal={setReportModal}
        setDropDownId={setDropDownId}
        dropDownId={dropDownId}
        handleRepost={handleRepost}
        isShare={value.isShare}
        isSave={value.isSave}
        handleSave={handleSave}
        shares_count={value.shares_count}
        type={value.type}
        isFollow={value.user.follow}
        handleFollow={handleFollow}
        setShowPost={setShowPost}
        editWebinarTab={editWebinarTab}
        handlePostSelect={handlePostSelect}
        updatePost={updatePost}
        deletePost={deletePost}
      />

      <Row
        className="pt-3"
        onClick={() => {
          updatePostDetail(value);
        }}
      >
        <Col>{renderMedia()}</Col>
      </Row>

      {(value?.caption || value?.title) && (
        <div className="py-2 px-2" style={{marginLeft: value.type === "clinical-image" ? "2px" : undefined, marginRight: value.type === "clinical-image" ? "2px" : undefined, backgroundColor: value.type === "clinical-image" ? '#EFFBFD' : ""}}>
          <div className='d-flex align-items-center justify-content-between mb-2' >
            {value?.title && <h4 className='mb-0'>{value.title}</h4>}
            {value.type === "clinical-image" && value?.pdf && (
              <button
                className="px-3 py-2 text-black fs-14 roboto-medium border manue-hover rounded"
                style={{whiteSpace: 'nowrap'}}
                // onClick={() => window.open(_imagesURL + value.pdf, "_blank")}
                onClick={() =>
                  openModal(value?.pdf, "pdf")
                }
              >
                {lang?.view_Case_Study}
              </button>
          )}
            </div>  
           { value.type !== "poll" && 
            <ReadMore text={value.caption} />
             }
        </div>
      )}

      {renderWebinarContent()}
      {renderPollContent()}
      {value.type !=="webinar" &&
      <PostFooter
        likes_count={value.likes_count}
        setPostId={setPostId}
        original_id={value.original_id}
        handleLikeUsers={handleLikeUsers}
        like_users_images={value.like_users_images}
        handleLike={handleLike}
        isLike={value.isLike}
        handleGetComments={handleGetComments}
        setPostModal={setPostModal}
        comments_count={value.comments_count}
        handleRepost={handleRepost}
        isShare={value.isShare}
        isSave={value.isSave}
        handleSave={handleSave}
        shares_count={value.shares_count}
        type={value.type}
        value={value}
        refetch={refetch}
        pollData={value}
      />}

{pdfModalOpen && (
      <Modal
        isOpen={pdfModalOpen}
        centered
        toggle={closeModal}
        size={isFullScreen ? "lg" : "lg"}
        className={isFullScreen ? "fullscreen-modal" : ""}
        style={
          isFullScreen
            ? { maxWidth: "100vw", maxHeight: "100vh", margin: 0 }
            : {}
        }
      >
        <ModalBody className="modal-body-view">

          {fileToView && fileToView?.type === "pdf" && (
            <div
              className={`position-relative custom_pdf_viewer ${
                isFullScreen ? "full-screen" : ""
              }`}
              style={{ transition: "all 0.3s ease-in-out" }}
            >
              <div className="position-relative custom_pdf_viewer">
                {/* {/ Overlay to block Firefox toolbar /} */}
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: isFullScreen ? "46px" : "40px", // Toolbar height
                    background: "white",
                    zIndex: 1000,
                    display: "flex", // Use flex to align content
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    padding: "0 10px",
                  }}
                >
                  {/* {/ Heading and Close Button /} */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      style={{
                        margin: 0,
                        padding: 0,
                        //  fontSize: '16px',
                        fontWeight: "bold",
                        color: "#333",
                        flexGrow: 1, // This ensures the heading takes available space and pushes the X button to the right
                        textAlign: "center",
                      }}
                    >
                      Case Study PDF
                    </h4>
                  </div>
                  <hr />
                </div>

                <iframe
                  title="PDF Viewer"
                  src={`${
                    _imagesURL + fileToView?.media
                  }#toolbar=0&navpanes=0&scrollbar=0&view=FitH&messages=0&print=0&download=0`}
                  width="100%"
                  height="500px"
                  className={isChrome ? "mt-5" : ""}
                  //  className={"mt-5"}
                  style={{ border: "none" }}
                />
                <span
                  title={isFullScreen ? "Exit full screen" : "Full Screen"}
                  onClick={() => {
                    toggleFullScreen();
                  }}
                  className="position-absolute cursor text-white px-2 py-1"
                  style={{
                    borderRadius: "5px",
                    top: "0px",
                    right: "0px",
                    zIndex: 99999,
                  }}
                >
                  {isFullScreen ? (
                    <IoCloseSharp className="fs-3 text-black" />
                  ) : (
                    <GoScreenFull className="fs-3 text-black" />
                  )}
                </span>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
      )}

    </div>
  );
};

export default PostCard;