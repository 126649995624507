import React, { useContext, useRef, useState } from 'react';
import { FaFilePdf, FaPlus } from 'react-icons/fa';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { _imagesURL, AddClinicalStudies, AddSurvay } from '../../../constants/API';
import Avatar from "../../../assets/images/icon/placeholder_Awatar.png";
import close from "../../../assets/images/icon/close-icon.svg";
import Cropper from "../../../../src/cropper/components/CroppersWizard.tsx"
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Language } from '../../../App';
import { toast } from 'react-toastify';
import { FEED } from '../../../constants/RoutesUrl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CiCamera } from 'react-icons/ci';
import PostImageModal from './PostImageModal';
import ProgressPopup from './ProgressPopup';
import ImageShowModal from '../../common/ImageShowModal.jsx';

function ClinicalStudyTab({ onHide, setProgress, handleApiCall, refetch }) {
  const { lang } = useContext(Language)
  const navigate = useNavigate();
  const childRef = useRef(null);
  // sessionStorage
  const SplashData = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = SplashData?.user?.id;
  const sub_category = SplashData?.home?.subcategories;
  const { data: userToken } = JSON.parse(localStorage.getItem("SignupData"));
  const [pdf, setPdf] = useState("");
  const [imagesUrl, setImagesUrl] = useState([]);
  const [type, setType] = useState("")
  const [showImageModal, setShowImageModal] = useState(false);
  const [qsModal, setQsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [qs, setQs] = useState([{ question: "", answers: ["", "", ""] }]);
  const [savedQs, setSavedQs] = useState([]);

  // States for Image Preview in Modal
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const toggleImagePreview = () => setIsImagePreviewOpen(!isImagePreviewOpen);
  const handleImageClick = (img) => {
    setPreviewImage(img); // Set the clicked image for preview
    toggleImagePreview();
  };

  const [coverImg, setCoverImg] = useState([]);  // For storing the selected image
  const [imgArr, setImgArr] = useState([]);  // For storing the selected image
  const [cropperOpen, setCropperOpen] = useState(false); // Whether the cropper modal is open
  const maxNumber = 6;
  // Additional cropper-related states (as per your Cropper component's props)
  const [ar, setAR] = useState(1);  // Aspect ratio
  const [maxARatio, setMaxAR] = React.useState(undefined);
  const [minARatio, setMinAR] = React.useState(undefined);
  const [resizable, setResizable] = useState(true); // Whether cropping is resizable
  const [hasSettings, setHasSettings] = useState(true); // Whether cropper settings are enabled
  const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = useState(false); // For coordinate updates
  const [fileUploadRefs, setFileUploadRefs] = React.useState({});
  const [numberOfImages, setNumberOfImages] = React.useState(
    // coverImg.length < maxNumber ? coverImg.length : maxNumber
    maxNumber
  );

  const [height, setHeight] = React.useState(undefined);
  const [width, setWidth] = React.useState(undefined);
  const [top, setTop] = React.useState(undefined);
  const [left, setLeft] = React.useState(undefined);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [formData, setFormData] = useState({
    title: '',
    category: '',
    description: '',
    file: null
  });
  const [isTruncated, setIsTruncated] = useState(true);
  const runChildFunction = (index) => {
    if (childRef.current) {
      childRef.current.onImageRemove(index);
    } else {
      console.error("Child ref is not set.");
    }
  };
  // Function to handle toggling truncation
  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const getImagesUrl = (url) => {

    setImagesUrl(url);
  }
  const removeImages = (index) => {
    const newImages = imagesUrl.filter((_, i) => i !== index);
    runChildFunction(index)
    setImagesUrl(newImages);
  }
  const handleClose = () => {
    setCropperOpen(false); // Close the cropper modal
  };

  // const onImageRemove = (index) => {
  //     setCoverImg((prev) => prev.filter((_, i) => i !== index));
  //     setImgArr((prev) => prev.filter((_, i) => i !== index));
  //     setNumberOfImages((prev) => Math.max(0, prev - 1));
  // };
  const onImageRemove = (index) => {
    // Create copies of existing arrays/objects
    const updatedImages = [...coverImg];
    const updatedFileUploadRefs = { ...fileUploadRefs };

    // Remove the image from arrays
    updatedImages.splice(index, 1);

    // Reset the file input value for the removed index
    if (fileUploadRefs[index + 1] && fileUploadRefs[index + 1].current) {
      fileUploadRefs[index + 1].current.value = ''; // Clear the file input
    }

    // Update all states related to images
    setCoverImg(updatedImages);
    setImgArr(updatedImages);

    // Reorganize the fileUploadRefs
    const newFileUploadRefs = {};
    Object.keys(updatedFileUploadRefs)
      .filter(key => key !== index.toString())
      .forEach((key, newIndex) => {
        newFileUploadRefs[newIndex] = updatedFileUploadRefs[key];
      });

    setFileUploadRefs(newFileUploadRefs);

    // Update the number of images
    // setNumberOfImages(updatedImages.length + 1);

    // Reset settings if no images left
    if (updatedImages.length === 0) {
      setHasSettings(true);
      setAR(undefined);
      setMinAR(undefined);
      setMaxAR(undefined);
      setWidth(500);
      setHeight(500);
      setTop(0);
      setLeft(0);

      // Reset the cropper state
      setCropperOpen(false);
    }
  };
  const handleImageUploadClick = () => {
    const currentIndex = numberOfImages - 1;
    if (fileUploadRefs[currentIndex] && fileUploadRefs[currentIndex].current) {
      // Reset the file input value before triggering the click
      fileUploadRefs[currentIndex].current.value = '';
      fileUploadRefs[currentIndex].current.click();
    }
  };
  const handleSubmit = async () => {
    // e.preventDefault();
    setIsLoading(true);
    setShowProgress(true);
    onHide(); // Close the popup
    const data = {
      title: formData.title,
      type: "clinical-image",
      category: "3",
      subcategory: formData.category,
      caption: formData.description,
      mediaBase64: imgArr,
    };
    try {
      const res = await axios.post(
        AddClinicalStudies,
        data,
        {
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken?.token}`,
          },
        }
      );

      // Handle the response if status is success
      if (res.data.status) {
        setShowProgress(false);


        handleSurvay(res.data.post.id, qs);
        onHide()
        return res
      } else {
        setShowProgress(false);
        toast.error(res.data.action);
      }
    } catch (error) {
      // Handle any errors that occur during the axios request
      setShowProgress(false);
      console.error(error);
      toast.error("Something went wrong. Please try again.");
    }


  };

  const handleSurvay = (post_id, questionsData) => {
    if (!questionsData || questionsData.length === 0) {

      return; // Return early to prevent the API call
    }
    const questions = [];
    const option1 = [];
    const option2 = [];
    const option3 = [];
    questionsData.forEach((item) => {
      questions.push(item.question);
      option1.push(item.answers[0]);
      option2.push(item.answers[1]);
      option3.push(item.answers[2]);
    });
    // savedQs.forEach((item) => {
    //   questions.push(item.question);
    //   option1.push(item.answers[0]);
    //   option2.push(item.answers[1]);
    //   option3.push(item.answers[2]);
    // });

    const data = { pdf, questions, option1, option2, option3, post_id };

    axios
      .post(AddSurvay, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken?.token}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          refetch()
          // navigate(FEED);
        } else {
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const _handleSubmit = async (e) => {
    e.preventDefault()

    await handleApiCall(() => handleSubmit(), { type: "clinical-study" })
  }
  const truncatedName = pdf?.name?.length > 50 ? `${pdf.name.slice(0, 50)}...` : pdf?.name;
  return (
    <>
      <div className="container">
        <h4 className="mb-4 text-muted">{lang.create_clinical_study}</h4>
        <form onSubmit={_handleSubmit}>
          <div className="mb-3">
            <label className="form-label">{lang.title}</label>
            <input
              type="text"
              className="form-control bg-light"
              placeholder={lang.clinical_study_title}
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">{lang.category}</label>
            <select
              className="form-select bg-light"
              value={formData.category}
              onChange={(e) => setFormData({ ...formData, category: e.target.value })}
            >
              <option value="">{lang?.case_study_category}</option>
              {sub_category.map((value) => (
                <option value={value.id} key={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3">
            <label className="form-label">{lang.description}</label>
            <textarea
              className="form-control bg-light"
              rows="4"
              placeholder={lang.clinical_study_description}
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            ></textarea>
          </div>

          <div className="mb-3">
            <label className="form-label">{lang.attach_complete_clinical_study_pdf}</label>
            <div className="d-flex gap-2">

              <button
                type="button"
                className="add-option-btn"
                onClick={() => document.getElementById('pdfinput').click()}
                style={{ border: "1px solid #88dfed", backgroundColor: "#f3fcfd", padding: "5px 15px", borderRadius: "20px" }}
              >
                <span><FaPlus /></span>{" "}{lang.upload_pdf}

              </button>
              <input
                type="file"
                id="pdfinput"
                className="d-none"
                accept=".pdf"
                onChange={(e) => {
                  const pdfFile = e.target.files[0];
                  setPdf(pdfFile);
                }}
              />
              {pdf && (
                <Col md={1} className="align-self-center">
                  <button
                    type="button"
                    className="add-option-btn"

                    style={{
                      border: "1px solid #ed9288",
                      backgroundColor: "#fdf3f3",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      display: 'flex', // Aligns items horizontally
                      alignItems: 'center' // Vertically aligns the items in the center
                    }}
                  >
                    <FaFilePdf
                      size={20}
                      color="black"
                      className="cursor"

                    />
                    <span style={{
                      marginLeft: '8px',
                      marginRight: "6px",
                      whiteSpace: 'nowrap', // Ensures the name doesn't break into a new line
                      overflow: 'hidden', // Hides overflowed content
                      textOverflow: 'ellipsis', // Adds "..." at the end when text overflows
                      display: 'inline-block', // Keeps the name within a block-level container
                      maxWidth: 'calc(100% - 30px)' // Keeps the name within the remaining space after the icon

                    }}
                      onClick={() => {
                        const fileURL = URL.createObjectURL(pdf);
                        window.open(fileURL, "_blank");
                        URL.revokeObjectURL(fileURL);
                      }}
                    >

                      {truncatedName}
                      {pdf?.name?.length > 50 && (
                        <span
                          onClick={toggleTruncate}
                          style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
                        >
                          {isTruncated ? '...show more' : 'show less'}
                        </span>
                      )}
                    </span>
                    <span>
                      <img
                        style={{ marginLeft: 0 }}
                        className="close c-pointer"
                        onClick={() => setPdf("")}
                        src={close}
                        alt="Remove"
                      />
                    </span>
                  </button>
                </Col>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">{lang.add_survey} <span className="text-muted">{lang.opti}</span></label>
            <div className="gap-2">
              <button
                type="button"
                className="add-option-btn"
                onClick={() => setQsModal(true)}
                style={{ border: "1px solid #88dfed", backgroundColor: "#f3fcfd", padding: "5px 15px", borderRadius: "20px" }}
              >
                <span><FaPlus /></span>{" "}{lang.add_question}

              </button>
            </div>
          </div>
          <hr className="text-muted" style={{ marginLeft: "-37px", marginRight: "-37px" }} />
          {/* Horizontal scrollable container */}
          <div
            className="d-flex gap-3 scroll-hide"
            style={{

              overflowX: "auto",  // Enable horizontal scroll
              overflowY: "hidden", // Hide vertical overflow
              flexWrap: "nowrap",   // Prevent wrapping of items
              maxWidth: "100%",     // Ensure it doesn't stretch beyond the parent
              whiteSpace: "nowrap", // Prevent line breaks between items
            }}
          >
            {/* Display Images */}
            {imgArr.length > 0 && (
              <div className="d-flex gap-1">
                {imgArr.map((img, index) => (
                  <div
                    key={index}
                    className="upload-poll-image-container"
                    style={{ flexShrink: 0, display: 'inline-block', width: 'auto', position: 'relative' }}
                  >
                    <PhotoProvider
                      speed={() => 800}
                      easing={(type) =>
                        type === 2
                          ? 'cubic-bezier(0.36, 0, 0.66, -0.56)'
                          : 'cubic-bezier(0.34, 1.56, 0.64, 1)'
                      }
                    >
                      <PhotoView src={img}>
                        <img className="custom-image cursor" src={img} onClick={() => handleImageClick(img)} alt="Uploaded" style={{
                          width: '110px',
                          height: "110px",
                          objectFit: "cover",
                          border: "1px solid #a5a0a0",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          borderRadius: "8px",
                        }} />
                      </PhotoView>
                    </PhotoProvider>
                    <img
                      className="c-pointer"
                      aria-label="Close"
                      src={close}
                      style={{
                        position: "absolute",
                        top: "-1px",
                        right: "-3px",
                        width: "26px",
                        height: "26px",
                      }}
                      alt="Remove"
                      onClick={() => onImageRemove(index)} // Remove specific image on click
                    />
                  </div>
                ))}
              </div>
            )}

          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <button type='button' onClick={() => {
              // fileUploadRefs[numberOfImages - 1].current.click();
              handleImageUploadClick()
              setType("image")
            }} className="btn btn-light rounded-pill text-decoration-none text-secondary
                              "
              disabled={coverImg.length === 5}
            >
              <CiCamera size={20} className="me-2" />
              {lang.images} {imgArr.length > 0 && (
                <span
                  className="px-2"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "17px",
                    border: "2px solid #fff",
                    marginLeft: "5px", // Add spacing between the word 'Images' and the badge
                  }}
                >
                  {imgArr.length}
                </span>
              )}
            </button>
            <div>
              <button type="button" style={{ color: '#848798', border: "1px solid #848798" }} className="btn px-3 me-2" onClick={onHide}>{lang.cancel}</button>
              <button type="submit" disabled={!formData.title || !formData.category || !formData.description || !imgArr.length || !pdf} className="btn px-4 btn-info text-white">{lang.publish}</button>
            </div>
          </div>
        </form>
        <>

          <Cropper
            images={coverImg}
            setImages={setCoverImg}
            setImagesArr={setImgArr}
            imagesArr={imgArr}
            value={coverImg}
            max={maxNumber}
            allowCrop={true}
            ar={ar}
            setAR={setAR}
            minARatio={minARatio}
            setMaxAR={setMaxAR}
            maxARatio={maxARatio}
            setMinAR={setMinAR}
            resizable={resizable}
            setResizable={setResizable}
            handleClose={handleClose}
            fileUploadRefs={fileUploadRefs}
            setFileUploadRefs={setFileUploadRefs}
            numberOfImages={numberOfImages}
            setNumberOfImages={setNumberOfImages}
            hasSettings={hasSettings}
            setHasSettings={setHasSettings}
            updateCoordinatesCheck={updateCoordinatesCheck}
            setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
            height={height}
            setHeight={setHeight}
            width={width}
            setWidth={setWidth}
            top={top}
            setTop={setTop}
            left={left}
            setLeft={setLeft}
            cropperOpen={cropperOpen}
            setCropperOpen={setCropperOpen}
          />


        </>
      </div>
      <PostImageModal ref={childRef} type={type} getVideoSource={null} getImagesUrl={getImagesUrl} showModal={showImageModal} setShowModal={setShowImageModal} />

      <Modal isOpen={qsModal} centered scrollable>
        <ModalHeader toggle={() => setQsModal(false)}>
          {lang?.add_question}
        </ModalHeader>
        <ModalBody>
          {qs.map((value, index) => (
            <div>
              <div className="d-flex gap-2">
                <h6 className="align-self-end">{index + 1}.</h6>{" "}
                <input
                  type="text"
                  className="form-control"
                  placeholder={lang?.write_question_here}
                  value={value.question}
                  onChange={(e) => {
                    const updatedQuestionsData = [...qs];
                    updatedQuestionsData[index] = {
                      question: e.target.value,
                      answers: qs[index].answers,
                    };
                    setQs(updatedQuestionsData);
                  }}
                />
              </div>
              <div className="my-2">
                <ol type="i">
                  {value.answers.map((data, id) => (
                    <li>
                      <input
                        type="text"
                        className="form-control my-2"
                        placeholder={lang?.write_option_here}
                        value={data}
                        onChange={(e) => {
                          const updatedQuestionsData = [...qs];
                          updatedQuestionsData[index].answers[id] =
                            e.target.value;
                          setQs(updatedQuestionsData);
                        }}
                      />
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          ))}

          <div className="text-end">
            <button
              className="bg-transparent text-blue border-0"
              onClick={() => {
                var disable = false;
                qs.map((value) => {
                  if (value.question === "") {
                    disable = true;
                    toast.error(lang?.enter_question_please);
                  }
                  value.answers.map((data) => {
                    if (data === "") {
                      disable = true;
                      toast.error(lang?.enter_option_please);
                    }
                  });
                });
                if (!disable) {
                  setSavedQs([...qs]);
                  const newQuestion = { question: "", answers: ["", "", ""] };
                  const updatedQuestionsData = [...qs];
                  updatedQuestionsData.push(newQuestion);
                  setQs(updatedQuestionsData);
                }
              }}
            >
              <FaPlus /> {lang?.add_question}
            </button>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex w-100 gap-3">
            <button
              className="btn btn-secondary py-2 w-100 rounded"
              onClick={() => setQsModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-blue rounded py-2 w-100 border-0"
              onClick={() => {
                setQsModal(false);
              }}
            >
              {lang?.save}
            </button>
          </div>
        </ModalFooter>
      </Modal>

      <ImageShowModal
        isOpen={isImagePreviewOpen}
        toggle={toggleImagePreview}
        imageSrc={previewImage}
      />
    </>
  );
}

export default ClinicalStudyTab;