import React, { useContext, useState } from 'react';
import { Language } from '../../../App';
import { GoClock } from "react-icons/go";
import { GoCalendar } from "react-icons/go";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileTimePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { TextField, InputAdornment } from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import { _imagesURL, adminBaseURL } from '../../../constants/API';
import axios from "axios";


const ClinicalSessionTab = ({ onHide, setProgress, handleApiCall }) => {
    const { lang } = useContext(Language);
    const SplashData = JSON.parse(localStorage.getItem("SplashData"));
    const subcategories = SplashData?.home?.subcategories;
    const { data: userToken } = JSON.parse(localStorage.getItem("SignupData"));
    const [startDate, setStartDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [formData, setFormData] = useState({
        caption: null,
        startDate: "",
        startTime: '',
        category: null,
        title: null,
        visibility: null,
        timeLimit: null,
        type: "clinical-session",
    });

    const handleDateChange = (newDate) => {
        //   setTempDate(newDate);
        setStartDate(newDate)
        setFormData({ ...formData, startDate: moment(newDate).format("YYYY-MM-DD") });
        //   setScheduleDate(moment(newDate).format("YYYY-MM-DD"))
    };
    const handleTimeChange = (newTime) => {
        setStartTime(newTime)
        setFormData({ ...formData, startTime: moment(newTime).format("hh:mm A") });

    };
    const handleTimePickerOpen = () => {
        setStartTime(moment().add(5, 'minutes'))
        setFormData({ ...formData, startTime: moment().add(5, 'minutes') });
    };
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    function isValidFutureDate(inputDate) {
        // Parse the input date
        const userDate = new Date(inputDate);

        // Check if the parsed date is a valid date
        if (isNaN(userDate.getTime())) {
            toast.error(lang?.error_note_number);
            return false;
        }

        // Get today's date
        const today = new Date();

        // Set the time to midnight for both dates to compare only the dates
        userDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        // Compare the dates
        if (userDate < today) {
            toast.error(lang?.date_should_greater_todays_date);
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        // Converting to unixTimeStamp
        const combinedDateTimeString = `${formData.startDate} ${moment(formData.startTime).format("hh:mm A")}`;  // Remove 'T' between date and time

        // Parse the combined string using moment
        const combinedDateTime = moment(combinedDateTimeString, "YYYY-MM-DD hh:mm A");

        // Get the timestamp in milliseconds
        const start_timestamp = combinedDateTime.valueOf();  // milliseconds
        const _start_timestamp = Math.floor(start_timestamp / 1000);
        if (formData.timeLimit > 120) {
            toast.error(lang?.clinical_session_duration_must_not_greater_min);
            return 0;
        }

        if (formData.timeLimit < 0) {
            toast.error(lang?.enter_correct_time_limit);
            return 0;
        }

        if (combinedDateTime < new Date()) {
            toast.error(lang?.start_time_less_current_time);
            return 0;
        }

        isValidFutureDate(formData.startDate);
        if(formData.timeLimit<15){
            toast.error(lang?.clinical_session_duration_must_greater_min||"Clinical session minimum 15 minutes");
            return 0;
        }
        const data = {
            start_timestamp: _start_timestamp,
            type: formData.type,
            caption: formData.caption,
            schedule_date: formData.startDate,
            schedule_time: moment(formData.startTime).format("hh:mm A"),
            category_id: "3",
            subcategory_id: formData.category,
            title: formData.title,
            visibility: formData.visibility,
            duration: formData.timeLimit,
        };
        console.log("data",data)
        onHide(); // Close the popup
        try {
            // Make the POST request to the API
            const res = await axios.post(adminBaseURL + "user/post/create", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${userToken?.token}`,
                },
                onUploadProgress: (data) => {
                    setProgress(Math.round((100 * data.loaded) / data.total)); // Track upload progress
                },
            });

            // Check if the response status is successful
            if (res.data.status) {
                handleCancel(); // Cancel the current process (close form or reset fields)
                return res
            } else {
                toast.error(res.data.error); // Show error toast
            }

        } catch (err) {
            toast.error("An error occurred while uploading. Please try again."); // Show error toast
            console.error(err); // Log the error for debugging
        }

    };
    const _handleSubmit = async (e) => {
        e.preventDefault();


        await handleApiCall(() => handleSubmit(), { type: "clinical-session" }); // Call API and show progress popup
    };
    const handleCancel = () => {
        setFormData({
            title: '',
            category: '',
            startDate: '',
            startTime: '',
            timeLimit: '',
            commitment: '',
            caption: ''
        });
        onHide()
    };
    const handleTimeOpen = (type) => {

        if (type === "start_time") {
            toast.error('Please select  start date first.');
            return false; // Prevents picker from opening
        } else if (type === "end_date") {
            toast.error("Select Start Date First")
        } else if (type === "end_time") {
            toast.error("Select End Date First")
        }
        return true; // Allows picker to open
    };
    const futureTime = startDate
        ? moment(startDate).isSame(moment(), 'day')  // If startDate is today
            ? moment().add(5, 'minutes')  // For today, add 5 minutes to the current time
            : moment(startDate).startOf('day').add(5, 'minutes')  // For tomorrow, add 5 minutes to midnight
        : moment().add(5, 'minutes');  // Default if startDate is null

    return (
        <>
            <div className="container">
                <div className="">
                    <div className="card-body">

                        <h4 className="card-title text-muted mb-4">{lang.create_clinical_session}</h4>
                        <form onSubmit={_handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">{lang.title}</label>
                                <input
                                    type="text"
                                    className="form-control bg-light"
                                    id="title"
                                    placeholder={lang.clinical_session_title}
                                    value={formData.title}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="category" className="form-label">{lang.category}</label>
                                <select
                                    className="form-select bg-light"
                                    id="category"
                                    value={formData.category}
                                    onChange={handleChange}
                                >
                                    <option value="">{lang.select_category}</option>
                                    {subcategories.map((value) => (
                                        <option key={value.id} value={value.id}>
                                            {value.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="row mb-3">
                                <div className="col">
                                    <label htmlFor="startDate" className="form-label">{lang.start_date}</label>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <MobileDatePicker
                                            label={startDate ? "" : "MM/DD/YYYY"}
                                            value={startDate}
                                            minDate={moment().startOf('day')}
                                            onChange={handleDateChange}
                                            slotProps={{
                                                textField: {
                                                    className: "custom-input bg-light",
                                                    InputLabelProps: { shrink: false },
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <GoCalendar style={{ cursor: 'pointer' }} />
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth style={{ width: '100%', height: '40px' }} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col">
                                    <label htmlFor="startTime" className="form-label">{lang.start_time}</label>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        {startDate ? (
                                            <MobileTimePicker
                                                value={startTime}
                                                label={startTime ? "" : "hh:mm:aa"}
                                                minTime={futureTime}
                                                onChange={handleTimeChange}
                                                onOpen={handleTimePickerOpen}
                                                slotProps={{
                                                    textField: {
                                                        className: "custom-input bg-light",
                                                        InputLabelProps: { shrink: false },
                                                        InputProps: {
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <GoClock style={{ cursor: 'pointer' }} />
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                    },
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />) : (
                                            <TextField
                                                label={startTime ? "" : "hh:mm:aa"}
                                                className="custom-input bg-light"
                                                InputLabelProps={{ shrink: false }}
                                                onClick={() => handleTimeOpen("start_time")} // Trigger toast when clicked

                                                InputProps={{
                                                    readOnly: true, // Prevent manual typing
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <GoClock
                                                                style={{ cursor: 'pointer' }}
                                                            // onClick={handleTimeOpen} // Trigger toast when clicked
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col">
                                    <label htmlFor="timeLimit" className="form-label">{lang.time_limit}</label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control bg-light"
                                            id="timeLimit"
                                            value={formData.timeLimit}
                                            onChange={handleChange}
                                        />
                                        <span className="input-group-text">{lang.min}</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <label htmlFor="commitment" className="form-label">{lang.clinical_session_commitment}</label>
                                    <select
                                        className="form-select bg-light"
                                        id="visibility"
                                        value={formData.visibility}
                                        onChange={handleChange}
                                    >
                                        <option value="">{lang?.choose_session_commitment}</option>
                                        <option value="One Time">{lang?.one_time}</option>
                                        <option value="Daily">{lang?.daily}</option>
                                        <option value="Weekly">{lang?.weekly}</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">{lang.description}</label>
                                <textarea
                                    className="form-control bg-light"
                                    id="caption"
                                    rows="4"
                                    placeholder={lang.description}
                                    value={formData.caption}
                                    onChange={handleChange}
                                />
                            </div>
                            <hr className="text-muted" style={{ marginLeft: "-37px", marginRight: "-37px" }} />
                            <div className="text-end mt-4">
                                <button
                                    type="button"
                                    style={{ color: '#848798', border: "1px solid #848798" }}
                                    className="btn px-3 me-2"
                                    onClick={handleCancel}
                                >
                                    {lang.cancel}
                                </button>
                                <button disabled={
                                    !formData.caption ||
                                    !formData.startDate ||
                                    !formData.startTime ||
                                    !formData.category ||
                                    !formData.title ||
                                    !formData.visibility ||
                                    !formData.timeLimit
                                } type="submit" className="btn px-4 btn-info text-white">
                                    {lang.publish}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClinicalSessionTab;