import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
} from "react";
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axios from "axios";
import NavBar from "../components/NavBar";
import ProfileInfoCard from "../components/feeds/ProfileInfoCard";
import SuggestedProfiles from "../components/feeds/SuggestedProfiles";
import PostForm from "../components/feeds/PostForm";
import {
  _imagesURL,
  FeedPostApi,
  GetCommentsApi,
  RepostApi,
  ReserveApi,
  adminBaseURL,
  FollowApi,
} from "../constants/API";
import InfiniteScroll from "react-infinite-scroller";
import { COURSES, EVENTS, LOGIN } from "../constants/RoutesUrl";
import {  useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FeedType,
  Language,
  PostDetailContext,
} from "../App.js";
import { ShimmerSocialPost } from "react-shimmer-effects";
import LiveFeeds from "../components/feeds/LiveFeeds.jsx";
import FeedAdvertisement from "../components/feeds/FeedAdvertisement.jsx";
import SuggestedCoursesEvents from "../components/feeds/SuggestedCoursesEvents.jsx";
import FeedSponsorPost from "../components/feeds/FeedSponsorPost.jsx";
import FeedPostFilter from "../components/feeds/FeedPostFilter.jsx";
import ProfileSuggestionSlider from "../components/feeds/ProfileSuggestionSlider.jsx";
import {
  fetchFeedData,
  likePostApi,
  savePostApi,
  usersLikedPostApi,
} from "../services/feedApis.js";
import { useQuery, useQueryClient } from "react-query";
import { getToken } from "../shared/token.js";
import CommentCanvas from "./post-details/comment-details/CommentCanvas.jsx";
import PollLikesModal from "../components/modals/PollLikesModal.jsx";
import LikeUsersModal from "./LikeUsersModal.jsx";
import PostCard from "./PostCard.jsx";
import ReportModal from "./ReportModal.jsx";
// import sendEmail from "../utils/emailService.js";
// import sendErrorEmail from "../utils/emailService.js";


const Feed = memo(() => {
  const { feedType } = useContext(FeedType);
//  const queryClient = useQueryClient();
  const { updatePostDetail } = useContext(PostDetailContext);
  const [playingVideo, setPlayingVideo] = useState(-1);
  sessionStorage.setItem("feed_url", window.location.href);
  const navigate = useNavigate();
  const location = useLocation();
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  // New Code
  const [leftSideAds, setLeftSideAds] = useState([]);
  const [popularCourses, setPopularCourses] = useState([]);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [rightSideAds, setRightSideAds] = useState([]);
  const [profileInfo, setProfileInfo] = useState({});
  const [suggestedEvents, setSuggestedEvents] = useState([]);
  const [joinedEvents, setJoinedEvents] = useState([]);
  const [contest_obj, setContestObj] = useState([]);
  const [leftSideFeeds, setLeftSideFeeds] = useState({});
  const [page, setPage] = useState(1);
  const [data, setData] = useState({});
  const [reportModal, setReportModal] = useState(false);
  const [postData, setPostData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [postComments, setPostComments] = useState({});
  const [postId, setPostId] = useState("");
  const [postDropDown, setPostDropDown] = useState(false);
  const [dropDownId, setDropDownId] = useState(null);
  const [likeUsersModal, setLikeUsersModal] = useState(false);
  const [likeUsers, setLikeUsers] = useState({});
  const [likeSpinner, setLikeSpinner] = useState(false);
  const [likePage, setLikePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedCourseType, setSelectedCourseType] = useState(null);
  const [suggestionsData, setSuggestionsData] = useState([]);
  const [showPost, setShowPost] = useState(false);
  const [editWebinar, setEditWebinar] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [pollId, setPollId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { lang } = useContext(Language);
  const authorize = {
    headers: {
      Authorization: `Bearer ${getToken()}`, // Set the Authorization header
    },
  };
  const {
    // data,
    isLoading: isLoadingFeeds,
    isRefetching,
    refetch: feedRefetch,
    isError,
    error,
  } = useQuery({
    queryKey: ["feedData"],
    queryFn: ()=>fetchFeedData({feedType}),
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (data) => {
      if (data?.status) {
        setLeftSideAds(data?.ads?.home_left);
        setPopularCourses(data.left.popular);
        setPurchasedCourses(data.left.purchased);
        setRightSideAds(data?.ads?.home_right);
        setProfileInfo(data.right.profile);
        setSuggestedEvents(data.right.suggested);
        setJoinedEvents(data.right.joined);
        setContestObj(data?.ads?.contest);
        setLeftSideFeeds(data?.left);
        setPostData(data?.home?.posts.data);
        setData(data?.home?.posts);
        setSuggestionsData(data?.right?.suggested_users)
        localStorage.setItem("contest_obj", JSON.stringify(data?.ads?.contest || []));

      } else {
        toast.error(data.error);
        sessionStorage.clear();
        localStorage.clear();
        navigate(LOGIN);
      }
    },
    onSettled: () => {
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    onError: (err) => {
      // navigate(LOGIN, { replace: true })
    },
  });
  const handleNewPageData = useCallback(async () => {

    if (isLoading) return;

    setIsLoading(true);
    const { data: localData } = JSON.parse(localStorage.getItem("SignupData")) || {};

    try {
      if (localData?.token) {
        const response = await axios.get(`${FeedPostApi + feedType}?page=${page + 1}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localData.token}`,
          },
        });

        const newPosts = response.data.data.data;
        setPostData((prev) => [...prev, ...newPosts]);

        // Store combined data in sessionStorage
        const updatedPosts = [...postData, ...newPosts];
        const uniquePosts = [...new Set(updatedPosts)];
        sessionStorage.setItem("prevFeedData", JSON.stringify(uniquePosts));
        sessionStorage.setItem("prevFeedDataDetails", JSON.stringify(response.data.data));
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error loading more data", error);
    } finally {
      setIsLoading(false);
    }
  }, [page, isLoading, postData, location.pathname]);
  const handleLikeUsers = useCallback(
    async (otherId) => {
      setLikeSpinner(true);
      setLikeUsersModal(true);
      try {
        const response = await
          axios.get(
            `${adminBaseURL}user/post/likes/${otherId}`, authorize
          );
        if (response.data.status) {
          setLikeUsers(response.data.data);
        } else {
          toast.error(response.data.error);
          setLikeUsersModal(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLikeSpinner(false);
      }
    },
    [user_id]
  );

  const handleNewLikeUsers = useCallback(async () => {
    try {
      const response = await usersLikedPostApi({ post_id: postId, page })
      if (response.data.status) {
        const update = likeUsers.data.concat(response.data.data.data);
        setLikeUsers((prevUsers) => ({ ...prevUsers, data: update }));
        setLikePage((prevPage) => prevPage + 1);
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error(error);
    }
  }, [likePage, likeUsers, postId, user_id]);

  const indicatorStyles = useMemo(
    () => ({
      background: "#fff",
      width: 20,
      height: 4,
      display: "inline-block",
      margin: "0 4px",
      borderRadius: "1px",
    }),
    []
  );

  const handleReserveWebinar = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map((value) =>
          value.id === post_id
            ? { ...value, isReserve: !value.isReserve }
            : value
        );
        setPostData(updatedData);
        await axios.get(`${ReserveApi}${post_id}}`, authorize);
      } catch (error) {
        console.error(error);
      }
    },
    [postData, user_id]
  );

  const handlePollAnswer = useCallback(
    async (post_id, answer) => {
      try {
        // Make the API request
        const response = await axios.post(
          `${adminBaseURL}user/post/poll/answer/submit`,
          {
            post_id,
            answer: answer + 1,
          },
          authorize
        );

        setPostData((prevData) =>
          prevData.map((post) =>
            post.id === post_id
              ? {
                ...post,
                right: answer + 1, 
                percs: response.data.data, 
                votes_count: post.votes_count + 1
               
              }
              : post
          )
        );

        // Check the response status
        if (!response.data.status) {
          toast.error(response.data.action);
        }

      } catch (error) {
        // Handle any errors
        toast.error(error.message);
      }
    },
    [postData, user_id] 
  );

  const handleLike = useCallback(
    async (post_id) => {
      try {
        setPostData((prevData) =>
          prevData.map((post) =>
            post.original_id === post_id
              ? {
                  ...post,
                  isLike: !post.isLike, 
                  likes_count: post.isLike
                    ? post.likes_count - 1 
                    : post.likes_count + 1,
                  like_users_images: !post.isLike
                    ? [...post.like_users_images,   user?.data?.image] 
                    : post.like_users_images.filter(
                        (image) => image !== user?.data?.image 
                      ),
                }
              : post
          )
        );
        

        await likePostApi({ post_id });
      } catch (error) {
        setPostData((prevData) =>
          prevData.map((post) =>
            post.id === post_id
              ? {
                ...post,
                isLike: !post.isLike, // Revert `isLike`
                likes_count: post.isLike
                  ? post.likes_count + 1 // Revert like count
                  : post.likes_count - 1,
              }
              : post
          )
        );
        toast.error(error.message);
      }
    },
    [postData, user_id]
  );

  const handleSave = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map((value) =>
          value.original_id === post_id ? { ...value, isSave: !value.isSave } : value
        );
        setPostData(updatedData);
        const response = await savePostApi({ post_id });
        if (response.status) {
          // toast.success(response.action);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [postData, user_id]
  );

  const handleRepost = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map((value) =>
          value.id === post_id ? { ...value, isShare: !value.isShare } : value
        );
        setPostData(updatedData);
        const response = await axios.get(`${RepostApi}${post_id}`, authorize);
        if (response.data.status) {
          feedRefetch()
          // toast.success(response.data.action);
              
        } else {
          toast.error(response.data.action);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [postData, user_id]
  );
  const handleFollow = useCallback(async (id) => {
    const updatedData = postData.map((value) =>
      value.user.id === id
        ? {
          ...value,
          user: {
            ...value.user,  // Spread the current user object
            follow: !value.user.follow  // Toggle follow
          }
        }
        : value
    );

    setPostData(updatedData);  // Update the state with the new data
    try {

      const res = await axios.get(`${FollowApi}${id}`, authorize);

      if (res.data.status) {

      } else {
        console.log(res.data.error);
      }
    } catch (error) {
      console.error('Error following user:', error);
    } finally {
      setIsLoading(false);
    }
  }, [postData, user_id]);
  const handleGetComments = useCallback(
    async (post_id) => {
      setIsLoading(true);
      const id = post_id || postId;
      try {
        const response = await axios.get(
          `${GetCommentsApi}${id}?page=1`
          , authorize);
        if (response.data.status) {
          setPostComments(response?.data?.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [postId, user_id]
  );

  const handlePostReport = useCallback(async (reportInput) => {
    if(!reportInput){
      toast.error("Please enter a report");
      return;
    }
    setIsLoading(true);
    try {
      const reportData = {
        feature_id: dropDownId,
        user_id,
        report: reportInput,
        type: "post",
      };
      const response = await axios.post(`${adminBaseURL}app/report`, reportData,authorize);
      if (response.data.status) {
        toast.success("Reported Successfully!");
        setReportModal(false);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [dropDownId, user_id]);

  const _fetchFeedData = async () => {
    try {
      setLoading(true);
      const requestData = {
        type: feedType || "all",
      };

      const response = await axios.get(
        `${FeedPostApi + feedType}?page=1`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
          },
        }
      );

      if (response.data.status) {
        setData(response.data.data);
        setPostData(response.data.data.data);
      } else {
        toast.error(response.data.error);
      }

      setLoading(false);
      // }
    } catch (error) {
      toast.error("Error fetching feed data:", error);
    }
  };
  useEffect(() => {
    setPage(1);
    _fetchFeedData();
  }, [feedType]);

  useEffect(() => {
    const clearSessionStorageOnReload = () => {
      ["prevFeedData", "prevFeedDataDetails"].forEach((key) =>
        sessionStorage.removeItem(key)
      );
    };
    window.addEventListener("beforeunload", clearSessionStorageOnReload);
    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnReload);
    };

  }, []);


  const toggleModal = useCallback((id) => {
    setPollId(id);
    setModalOpen((prev) => !prev);
  }, []);

  const isFeedsEmpty = useMemo(() =>
    leftSideFeeds.live?.length === 0 && leftSideFeeds.upcoming?.length === 0,
    [leftSideFeeds]
  );
  const viewAllPurchased = (course_type, pageType, type) => {

    if (pageType === location.pathname) {
      // Determine navigation based on type
      if (type === "courses") {
        navigate(COURSES + "/1", { state: { selectedCourseType: course_type } });
      } else if (type === "events") {
        navigate(EVENTS + "/3",);
      }
      setSelectedCourseType(course_type); // Set the selected course or event type
    }

    window.scrollTo(0, 0);
  };

  const handleVideoPlay = (index) => {
    setPlayingVideo(index); // Set the current video as playing
  };
  const removePollAnswer = async (id) => {
    try {
      // Sending the DELETE request to the specified endpoint
      const response = await axios.get(
        `${adminBaseURL}user/post/poll/answer/remove/${id}`,
        authorize // Assuming authorize is the authorization header or config
      );
      // Handle the response
      if (response.data.status) {
        setPostData((prevData) =>
          prevData.map((post) =>
            post.id === id
              ? {
                ...post,
                right: 0, // Update the 'right' property with the response
                votes_count: post.votes_count - 1,
              }
              : post
          )
        );
        return response.data;
      } else {
        console.error('Error removing poll answer:', response.data.action);
        return null; // or return error message depending on your use case
      }
    } catch (error) {
      // Handle any error that occurs during the API request
      console.error('Error while removing poll answer:', error.message);
      return null;
    }
  }
  const editWebinarTab = (edit) => {
    setEditWebinar(edit)
  }
  const handlePostSelect = (object) => {
    setSelectedPost(object);
  };
  const updatePost = (postId, newCaption) => {
    setPostData((prevData) =>
      prevData.map((post) =>
        post.id === postId ? { ...post, caption: newCaption } : post
      )
    );
  };
  const deletePost = (postId) => {
    setPostData((prevData) => prevData.filter((post) => post.original_id !== postId));
  };

  // Function to handle page reload
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Set a flag in localStorage to indicate a reload
      localStorage.setItem('isReload', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Check if the page was reloaded
    const isReload = localStorage.getItem('isReload');
    if (isReload === 'true') {
      setPostData([]); // Reset state to empty
      // localStorage.removeItem('isReload'); // Clear the flag
    }

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  const updateCommentsCount = (postId, change) => {
    setPostData((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? { ...post, comments_count: post.comments_count + change }
          : post
      )
    );
  }
  return (
    <>
      <Container fluid>
        <Row>
          <NavBar showPost={showPost} setShowPost={setShowPost} />
        </Row>
        <Row
          className="d-flex justify-content-center"
          style={{ paddingTop: "4rem" }}
        >
          <div className="col-md-3 d-flex flex-column flex-wrap align-content-end">
            {/* <div style={{ marginTop:isFeedsEmpty?"15px":"0px" }}> */}
            <LiveFeeds leftSideFeeds={leftSideFeeds} isLoading={isLoadingFeeds} isRefetching={isRefetching} />
            {!isFeedsEmpty && <div style={{ marginTop: "15px" }}></div>}
            <FeedAdvertisement ads={leftSideAds} new_contest_obj={contest_obj} />
            <SuggestedCoursesEvents
              type="courses"
              popularCourses={popularCourses}
              purchasedCourses={purchasedCourses}
              setSelectedCourseType={setSelectedCourseType}
              selectedCourseType={selectedCourseType}
              viewAllPurchased={() => viewAllPurchased(1, "/feed", "courses")}
            />
          </div>
          <div
            className="find_scroll pt-3 col-md-6"
            style={{
              maxWidth: "595px",
              paddingBottom: "5rem",
            }}
          >
            <PostForm selectedPost={selectedPost} editWebinar={editWebinar} setEditWebinar={setEditWebinar} showPost={showPost} setShowPost={setShowPost} refetch={_fetchFeedData} />
            <FeedSponsorPost
              loading={!contest_obj}
              new_contest_obj={contest_obj}
            />
            <FeedPostFilter />
            <InfiniteScroll
              pageStart={0}
              loadMore={handleNewPageData}
              hasMore={page <= data?.last_page}
              loader={<ShimmerSocialPost type="image" />}
              useWindow={true}
            >
              <>

                {!isLoading && loading || isLoadingFeeds ? (
                  <div className="mt-3">
                    <ShimmerSocialPost type="image" /></div>
                ) : (
                  postData &&
                  postData.map((value, index) =>
                    index === 3 ? (
                      <ProfileSuggestionSlider suggestions={suggestionsData} />
                    ) :
                    (
                      <PostCard
                        key={value.id}
                        value={value}
                        postDropDown={postDropDown}
                        setPostDropDown={setPostDropDown}
                        setReportModal={setReportModal}
                        setDropDownId={setDropDownId}
                        dropDownId={dropDownId}
                        handleRepost={handleRepost}
                        handleSave={handleSave}
                        handleFollow={handleFollow}
                        setShowPost={setShowPost}
                        editWebinarTab={editWebinarTab}
                        handlePostSelect={handlePostSelect}
                        updatePost={updatePost}
                        deletePost={deletePost}
                        updatePostDetail={updatePostDetail}
                        handleLikeUsers={handleLikeUsers}
                        handleLike={handleLike}
                        handleGetComments={handleGetComments}
                        setPostModal={setPostModal}
                        setPostId={setPostId}
                        handleReserveWebinar={handleReserveWebinar}
                        handlePollAnswer={handlePollAnswer}
                        toggleModal={toggleModal}
                        removePollAnswer={removePollAnswer}
                        refetch={_fetchFeedData}
                        playingVideo={playingVideo}
                        handleVideoPlay={handleVideoPlay}
                        indicatorStyles={indicatorStyles}
                      />
                    )
                  
                  )
                )}
              </>
            </InfiniteScroll>
          </div>
          <div className="col-md-3">
            <SuggestedProfiles suggestions={suggestionsData} />
            <ProfileInfoCard profileInfo={profileInfo} />
            <FeedAdvertisement ads={rightSideAds} new_contest_obj={contest_obj} />
            <SuggestedCoursesEvents
              type="events"
              suggestedEvents={suggestedEvents}
              joinedEvents={joinedEvents}
              viewAllPurchased={() => viewAllPurchased("joined-events", "/feed", "events")}
            />
          </div>
        </Row>
      </Container>
      {/* Post Modal */}
      {postModal && 
      <CommentCanvas
        postModal={postModal}        // Controls the visibility of the canvas
        setPostModal={setPostModal}   // Function to toggle the modal’s visibility
        postId={postId}
        onAddComment={updateCommentsCount} // Increase count
        onRemoveComment={updateCommentsCount} // Decrease count
        onReplyComment={updateCommentsCount}
      />
      }
     
      <LikeUsersModal
        isOpen={likeUsersModal}
        toggleLikesModal={() => setLikeUsersModal(!likeUsersModal)}
        likeSpinner={likeSpinner}
        likeUsers={likeUsers}
        handleNewLikeUsers={handleNewLikeUsers}

      />
     {reportModal && <ReportModal
        isOpen={reportModal}
        toggle={setReportModal}
        handleReport={handlePostReport}
        isLoading={isLoading}
        heading={lang.report_post} 
      />}
      {modalOpen && <PollLikesModal modal={modalOpen} toggleModal={toggleModal} pollId={pollId} />}
    </>
  );
});

export default Feed;

