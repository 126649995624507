import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import ImageComponent from "../common/ImageComponent.jsx";
import { PROFILE } from "../../constants/RoutesUrl.js";
import { _imagesURL, DeletePostApi, EditPostApi } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import Dots from "../../assets/images/icon/post-dropdown-dots.png";
import { Link } from "react-router-dom";
import { UnixToRelative } from "../notification/Notification.jsx";
import { Collapse, Modal, ModalBody, Spinner, ModalHeader, Row, Col, Card } from "reactstrap";
import { FeedDataRefetch, Language } from "../../App.js";
import { toast } from "react-toastify";
import axios from "axios";
import { authorize } from "../../shared/authorize.js";
import { BiEditAlt, BiRepost, BiUserVoice } from "react-icons/bi";
import Save from "../../assets/images/icons/save.svg";
import Saved from "../../assets/images/icons/saved.svg";
import { MdOutlineReport } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import RepostUserModal from "./RepostUserModal.jsx";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import InviteWebinarPopup from "./postTabs/InviteWebinarPopup.jsx";
import { TbMailPlus } from "react-icons/tb";
import ShareModal from "./ShareModal.jsx";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { RepostCard } from "./PostFooter.jsx";
import PostHeaderDropdown from "./PostHeaderDropdown.jsx";
const PostHeader = ({
  postDetails,
  postDropDown,
  setPostDropDown,
  setReportModal,
  setDropDownId,
  dropDownId,
  isSave,
  isShare,
  shares_count,
  handleSave,
  handleRepost,
  type,
  isFollow,
  handleFollow,
  setShowPost,
  editWebinarTab,
  handlePostSelect,
  profilePage,
  updatePost,
  deletePost
}) => {
  const { lang } = useContext(Language);
  const { updateRefetch } = useContext(FeedDataRefetch);
  const collapseRef = useRef(null);
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  const [inviteWebinar, setInviteWebinar] = useState(false)
  const [webinarType, setWebinarType] = useState(null)
  const [editPostInput, setEditPostInput] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = useCallback((id) => {
    // setPostId(id);
    setModalOpen((prev) => !prev);
  }, []);
  const handleDeletePost = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${DeletePostApi}${postDetails.original_id}`, authorize());
      if (response.data.status) {
        // toast.success("Post Deleted!");
        setDeleteModal(false);
        if (!profilePage) {
          // refetch()
          deletePost(postDetails.original_id)
        } else {
          window.location.reload();
        }
        // updateRefetch();
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPost = async () => {
    setIsLoading(true);
    try {
      const editData = { caption: editPostInput, post_id: dropDownId };
      const response = await axios.post(EditPostApi, editData, authorize());
      if (response.data.status) {
        // toast.success("Edited Successfully!");
        setEditModal(false);
        updateRefetch();
        updatePost(dropDownId, editPostInput);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const [ignoreNextOutsideClick, setIgnoreNextOutsideClick] = useState(false);
  const RepostCollapse = useRef(null)
  const RepostbuttonRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // Skip if click is on the button itself
      if (RepostbuttonRef.current?.contains(event.target)) {
        return;
      }
      
      // Only close if click is outside collapse area
      if (RepostCollapse.current && !RepostCollapse.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If we should ignore this click, reset the flag and return
      if (ignoreNextOutsideClick) {
        setIgnoreNextOutsideClick(false);
        return;
      }

      // Check if click is inside the collapse or the dots button
      const isClickInsideCollapse = collapseRef.current?.contains(event.target);
      const dotsButton = document.querySelector('.dots-button');
      const isClickInsideDotsButton = dotsButton?.contains(event.target);

      // Only close if click is completely outside
      if (!isClickInsideCollapse && !isClickInsideDotsButton) {
        setPostDropDown(false);
        setIsOpen(false);
      }
    };

    if (postDropDown) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [postDropDown, ignoreNextOutsideClick]);

  const handleRepostClick = () => {

    if (type !== "poll") {
      // When type is not "poll"
      if (!isOwner) {

        handleRepost(postDetails?.original_id);
      } else {
        setModalOpen(true);
      }
    } else {
      // When type is "poll"
      if (isOwner) {
        setModalOpen(true); // or any other logic you'd like to add for this case
      } else {
        if (!isShare) {
          setIsOpen(!isOpen); // Toggle collapse when isShare is false
        } else {
          handleRepost(postDetails?.original_id); // Repost when isShare is true
        }
      }
    }
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    if (postDropDown) {
      setPostDropDown(false);
      setIsOpen(false);
    } else {
      setPostDropDown(true);
      setDropDownId(postDetails?.original_id);
    }
  };
  const handleMenuItemClick = (callback) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIgnoreNextOutsideClick(true);

    if (callback) {
      callback();
    }
  };
  const isOwner = postDetails?.user?.id === user_id
  const repostTitle = isOwner
    ? `${shares_count} ${lang?.repost}`
    : `${isShare ? lang?.reposted : lang?.repost}`;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative px-3">
        {profilePage ? <Col md={10} className="d-flex gap-3">
          <div
            className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
            style={{
              maxHeight: "4rem",
              maxWidth: "4rem",
            }}
          >
            <img
              src={
                postDetails?.user?.image
                  ? _imagesURL +
                  postDetails?.user?.image
                  : Avatar
              }
              alt="picture"
              className="br-16 img-fluid"
            />
          </div>
          <div className="align-self-end">
            <h1 className="fs-16 roboto-medium">
              {postDetails?.user?.name}
            </h1>
            <span className="fs-12 roboto-regular">
              <UnixToRelative
                unixTimestamp={
                  postDetails?.time
                }
              />
            </span>
          </div>
        </Col> :
          <div className="d-flex gap-2">
            <div
              className="bg-white rounded-circle align-self-center"
              style={{
                padding: "2px",
                width: "50px",
                height: "50px",
                border: "1px solid rgba(29, 193, 221, 1)",
              }}
            >
              <Link to={PROFILE + "/" + postDetails?.user?.id}>
                <ImageComponent
                  src={
                    postDetails?.user?.image
                      ? _imagesURL + postDetails?.user?.image
                      : Avatar
                  }
                  img_height="100%"
                  img_width="100%"
                  loader_height="100%"
                  loader_width="100%"
                  compClassName="rounded-circle"
                  roundShimmerSize={45}
                />
              </Link>
            </div>
            <div className="align-self-center">
              <div>
                <Link to={PROFILE + "/" + postDetails?.user?.id}>
                  <h1 className="fs-16 roboto-bold hover-blue mb-0 text-ellips-162px">
                    {postDetails?.user?.name}
                  </h1>
                </Link>
                <p
                  className="mb-0 fs-12 roboto-regular"
                  style={{
                    color: "#434343",
                    opacity: "80%",
                  }}
                >
                  {lang.posted} <UnixToRelative unixTimestamp={postDetails?.time} />
                </p>
              </div>
            </div>
          </div>}
        <div
          className="manue-hover cursor d-flex justify-content-center align-items-center p-2 rounded dots-button"
          onClick={
            handleDotsClick
          }
        >
          <img width={20} src={Dots} alt="..." />
        </div>
        {/* Post DropDown */}
        <PostHeaderDropdown
          postDetails={postDetails}
          postDropDown={postDropDown}
          dropDownId={dropDownId}
          type={type}
          isSave={isSave}
          isShare={isShare}
          shares_count={shares_count}
          isFollow={isFollow}
          isOwner={isOwner}
          user_id={user_id}
          lang={lang}
          handleSave={handleSave}
          handleRepost={handleRepost}
          handleFollow={handleFollow}
          setReportModal={setReportModal}
          setEditModal={setEditModal}
          setDeleteModal={setDeleteModal}
          setShowPost={setShowPost}
          editWebinarTab={editWebinarTab}
          handlePostSelect={handlePostSelect}
          setInviteWebinar={setInviteWebinar}
          setWebinarType={setWebinarType}
          setShareModal={setShareModal}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          toggleRepostModal={toggleModal}
          setEditPostInput={setEditPostInput}
        />
        {/* End Post DropDown */}
      </div>

      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(false)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_post}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_deleting_post_see_post}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeletePost}
            >
              {isLoading ? <Spinner size={"sm"} /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* End Delete Modal */}

      {/* Edit Modal */}
      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(false)}
        centered
        zIndex={9}
      >
        <ModalHeader>{lang?.edit_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.what_your_mind}</h6>
          <textarea
            rows="7"
            className="form-control my-3"
            value={editPostInput}
            onChange={(e) => {
              setEditPostInput(e.target.value);
            }}
          ></textarea>
          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setEditModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              disabled={!editPostInput}
              onClick={handleEditPost}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.update}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* End Edit Modal */}
      {inviteWebinar && <InviteWebinarPopup
        isOpen={inviteWebinar}
        webinarId={postDetails?.original_id}
        toggleModal={() => {
          setInviteWebinar(!inviteWebinar);
          setWebinarType(null)
        }}
        type={webinarType}
      />}
      {shareModal &&
        <ShareModal
          isOpen={shareModal}
          toggle={() => setShareModal(!shareModal)}
          type={type}
          postId={postDetails.original_id}
        />
      }

      {modalOpen && <RepostUserModal toggleModal={toggleModal} modal={modalOpen} postId={postDetails?.original_id} />}


    </>
  );
};

export default PostHeader;
