import React, { useContext, useEffect, useState } from "react";
import { authorize } from "../../shared/authorize";
import axios from "axios";
import { toast } from "react-toastify";
import { _imagesURL, adminBaseURL } from "../../constants/API";
import ImageComponent from "../common/ImageComponent";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import Noanswer from "../../assets/images/no_answer.svg";
import { Language } from "../../App";
import RadioOption from "../common/RadioOption";

const ClinicalPoll = ({ clinicalId, onClose, customHeight }) => {
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [spin, setSpin] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [checkUserId, setCheckUserId] = useState("");
  const [submittedList, setSubittedList] = useState("");
  const { lang } = useContext(Language);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState("");

  const handleOpenModal = (attendee) => {
    setSelectedAttendee(attendee.post_survey_answers);
    setModalOpen(true);
  };

  const handleAnswerChange = (questionId, optionIndex) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: optionIndex,
    }));
  };
  const handleGetOptions = () => {
    setLoader(true);
    axios
      .get(`${adminBaseURL}user/post/clinical-study/${clinicalId}`, authorize())
      .then((res) => {
        if (res.status) {
          const fetchedOptions = res.data.data.surveys;
          setOptions(fetchedOptions);
          setCheckUserId(res.data.data.user.id);
          const isFormSubmitted = res.data.data.submitted;
          setIsSubmitted(isFormSubmitted);
          setSubittedList(res.data.data.survey_users);

          // Initialize answers based on submission status
          let initialAnswers = {};
          if (isFormSubmitted) {
            // Use submitted answers and adjust indexes (-1 to match stored indexes)
            initialAnswers = res.data.data.survey_answers.reduce(
              (acc, item) => {
                acc[item.question_id] = item.answer ? item.answer - 1 : "";
                return acc;
              },
              {}
            );
          } else {
            initialAnswers = fetchedOptions.reduce((acc, question) => {
              acc[question.id] = "";
              return acc;
            }, {});
          }

          setAnswers(initialAnswers);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    if (clinicalId) {
      handleGetOptions();
    }
  }, [clinicalId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitted) return;
    setSpin(true);

    const questionIds = Object.keys(answers).join(",");

    // Add +1 to each selected index before sending
    const answerValues = Object.values(answers)
      .map((index) => index + 1) // Add +1 to each index
      .join(",");

    const params = {
      post_id: clinicalId,
      questions: questionIds,
      answers: answerValues,
    };

    try {
      const response = await axios.post(
        `${adminBaseURL}user/post/clinical-study/question/answers`,
        params,
        authorize()
      );
      if (response.data.status) {
        toast.success(
          response.data.action || "Answers submitted successfully."
        );
        setIsSubmitted(true);
        // onClose();
        handleGetOptions();
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error(
        error.message || "Failed to submit answers. Please try again."
      );
    } finally {
      setSpin(false);
    }
  };
  const signupData = JSON.parse(localStorage.getItem("SignupData"));
  const userId = signupData?.data?.id;

  const [formHeight, setFormHeight] = useState(getDynamicHeight());

  function getDynamicHeight() {
    const screenHeight = window.innerHeight;
    
    // if (screenHeight < 500) return "calc(40vh - 100px)";
    // if (screenHeight < 768) return "calc(50vh - 120px)";
    if (screenHeight < 1517) return "calc(60vh - 180px)";
    if (screenHeight < 1820) return "calc(60vh - 140px)";
    if (screenHeight > 2000) return "calc(60vh - 110px)";
    
    return "calc(60vh - 154px)";
  }

  useEffect(() => {
    const handleResize = () => {
      setFormHeight(getDynamicHeight());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {checkUserId === userId ? (
        <div
          className="d-flex flex-column p-2 scroll-hide"
          style={{
             maxHeight: customHeight ? "calc(60vh - 130px)" : "calc(60vh - 83px)",
            overflowY: "auto",
          }}
        >
          {submittedList && submittedList.length > 0 ? (
            submittedList.map((attendee) => (
              <div
                key={attendee.id}
                className="list-group-item d-flex align-items-center justify-content-between w-100 py-3 manue-hover rounded px-2 cursor"
                onClick={() => handleOpenModal(attendee)}
              >
                <div className="d-flex align-items-center gap-3">
                  <div
                    className="bg-white rounded-circle align-self-center"
                    style={{
                      padding: "2px",
                      width: "48px",
                      height: "48px",
                      border: "1px solid rgba(29, 193, 221, 1)",
                    }}
                  >
                    <ImageComponent
                      src={
                        attendee?.image
                          ? _imagesURL + attendee?.image
                          : Avatar
                      }
                      compClassName="rounded-circle border"
                      img_height="100%"
                      img_width="100%"
                      roundShimmerSize={38}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <h6 className="mb-0">
                      {attendee.name && attendee.name.length > 15
                        ? attendee.name.substring(0, 15) + "..."
                        : attendee.name}
                    </h6>
                    <small className="text-secondary">
                      {attendee.subcategory && attendee.subcategory.length > 25
                        ? attendee.subcategory.substring(0, 25) + "..."
                        : attendee.subcategory}
                    </small>
                  </div>
                </div>

                <button
                  className="btn btn-sm roboto-medium fs-12"
                  style={{
                    backgroundColor: "#F3FCFD",
                    border: "1px solid #D1D1D1",
                  }}
                >
                  See Answer
                </button>
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <p className="text-muted">{lang?.people_that_answered}</p>
            </div>
          )}
        </div>
      ) : loader ? (
        <div className="d-flex justify-content-center p-5">
          <span className="spinner-border text-primary"></span>
        </div>
      ) : options.length > 0 ? (
        <div className="pt-3 pb-0 scroll-hide">
            <div className="d-flex gap-2 align-items-center mx-2">
              <span
                className="mb-0 text-white bg-black rounded-circle fs-12 d-flex align-items-center justify-content-center"
                style={{ width: "20px", height: "20px" }}
              >
                {options.length}
              </span>
              <p className="mb-0 text-secondary roboto-medium fs-14">
                Multiple Choice Question
              </p>
            </div>
          <form
            onSubmit={handleSubmit}
            className="scroll-hide"
            style={{  maxHeight: customHeight ? "calc(60vh - 200px)" : "calc(60vh - 154px)", overflowY: "auto" }}
            // style={{maxHeight: formHeight, overflowY: "auto"}}
          >
            {options.map((question, index) => {
              const questionOptions = [
                question.answer1,
                question.answer2,
                question.answer3,
              ].filter(Boolean);

              return (
                <div key={question.id} className="p-3 bg-white">
                  <p className="mb-3 fw-bold">{question.question}</p>

                  {questionOptions.map((option, optionIndex) => {
                    const isSelected = answers[question.id] === optionIndex;

                    return (
                      <div
                        key={optionIndex}
                        className={`form-check d-flex align-items-center mb-2 rounded cursor ${
                          isSelected ? "selected-option" : ""
                        }`}
                        style={{
                          border: isSelected
                            ? isSubmitted
                              ? "1px solid rgb(151, 149, 149)"
                              : "1px solid #1DC1DD"
                            : "1px solid #E8E8E8",
                          padding: "10px",
                          color: isSelected
                            ? isSubmitted
                              ? "rgb(151, 149, 149)"
                              : "#1DC1DD"
                            : "inherit",
                          cursor: isSubmitted ? "not-allowed" : "pointer",
                          opacity: isSubmitted ? 0.6 : 1,
                        }}
                        onClick={() =>
                          !isSubmitted &&
                          handleAnswerChange(question.id, optionIndex)
                        }
                      >
                        <input
                          type="radio"
                          id={`${question.id}-${optionIndex}`}
                          name={question.id}
                          className="me-2 custom_radioCheck"
                          value={optionIndex}
                          disabled={isSubmitted}
                          checked={isSelected}
                          onChange={() => {}}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`${question.id}-${optionIndex}`}
                        >
                          {option}
                        </label>
                      </div>
                    );
                  })}
                  <hr className="m-0" />
                </div>
              );
            })}
          </form>
          <div className="px-3  position-absolute w-100" style={{bottom: '50px'}}>
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn-blue mt-2 py-1 border-0 rounded px-3 w-100 roboto-medium"
              disabled={
                isSubmitted ||
                !Object.values(answers).every((answer) => answer !== "")
              }
            >
              {spin ? (
                <span className="spinner-border spinner-border-sm ms-2"></span>
              ) : isSubmitted ? (
                "Already Submitted"
              ) : (
                "Submit Answers"
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center scroll-hide"
        // style={{  maxHeight: customHeight ? "calc(60vh - 200px)" : "calc(60vh - 154px)", overflowY: 'auto'}} 
        >
          <img src={Noanswer} alt="No Answer" />
          <small className="text-muted">No Questions Uploaded</small>
        </div>
      )}

      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        centered
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpen(false)}>
          Submitted Answers Detail
        </ModalHeader>
        <ModalBody>
        {selectedAttendee.length > 0 ? (
  selectedAttendee.map((attendee, attendeeIndex) => (
    <div key={attendee.id} className="d-flex flex-column mt-3 gap-3">
      <p className="fs-20 fw-bold text-muted">
        {attendee.question.question}
      </p>

      <div className="d-flex flex-column gap-2">
        {[
          attendee.question.answer1,
          attendee.question.answer2,
          attendee.question.answer3,
        ]
          .filter(Boolean)
          .map((option, index) => (
            <RadioOption
              key={`${attendeeIndex}-${index}`}
              option={option}
              isSelected={attendee.answer === index + 1}
              disabled={true}
              border={"1px solid black"}
            />
          ))}
      </div>
    </div>
  ))
) : (
  <p>Loading...</p>
)}

        </ModalBody>
      </Modal>
    </>
  );
};

export default ClinicalPoll;
