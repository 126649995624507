import React, { useEffect, useRef } from "react";

interface VideoWithAspectRatioProps {
  aspectRatio: number;
  src: string;
  index: number;
  setPlayingVideo: (index: number | null) => void;
  playingVideo: number | null;
}

const VideoWithAspectRatio: React.FC<VideoWithAspectRatioProps> = ({
  aspectRatio,
  src,
  index,
  setPlayingVideo,
  playingVideo,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  // const height = 555 / aspectRatio;

  useEffect(() => {
    // Cleanup existing observer
    if (observerRef.current && videoRef.current) {
      observerRef.current.unobserve(videoRef.current);
    }

    // Create a new observer to monitor visibility
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.75) {
            setPlayingVideo(index); // Notify parent that this video is in view
          }
        });
      },
      {
        threshold: [0.75], // Play only when 75% of the video is visible
        rootMargin: "0px",
      }
    );

    if (videoRef.current) {
      observerRef.current.observe(videoRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [index, setPlayingVideo]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    if (playingVideo === index) {
      // Play the current video
      videoElement.play().catch((err) => {
        console.error("Error playing video:", err);
      });
    } else {
      // Pause other videos
      videoElement.pause();
      videoElement.currentTime = 0; // Reset to the beginning
    }
  }, [playingVideo, index]);

  return (
    <>
    <div
      className="video-container"
      style={{
        // height: aspectRatio > 630 ? "630px" : aspectRatio + "px",
        backgroundColor: "#f5f5f5",
        position: "relative",
      }}
    >
      <video
        ref={videoRef}
        src={src}
        controls
        muted
        autoPlay
        preload="auto" // Preloads the video automatically
        playsInline
        style={{
          width: "100%",
          height: aspectRatio > 630 ? "630px" : aspectRatio + "px",
          objectFit: aspectRatio > 630 ? "contain" : "cover",
        }}
      />
    </div>
    </>
  );
};

export default VideoWithAspectRatio;
