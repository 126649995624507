import React, { useContext } from 'react';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { GoDotFill } from 'react-icons/go';
import { Language } from '../App';
import usePollRemainingTime from '../hooks/usePollRemainingTime';

const PollResultsView = ({ value, toggleModal, removePollAnswer }) => {
    const {lang} = useContext(Language)
    const userData = localStorage.getItem("SignupData");
    const user = userData ? JSON.parse(userData) : null;
    const user_id = user?.data?.id;
    const showVoteModal = (id)=>{
      if(user_id===value.user.id){
       
        toggleModal(id)
      }
      
    }
    const remainingTime = usePollRemainingTime(value.time, value.duration);
  return (
    <>
      <div className="container my-2">
        {[value?.option_1, value?.option_2, value?.option_3, value?.option_4]
          .filter(data => data !== "" && data !== null)
          .map((data, index) => (
            <div className="list-item-poll mb-2 cursor" key={index}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <span className="me-2">{index + 1}.</span>
                  <span>{data}</span>
                  {value?.right === index + 1 && (
                    <span className="verified-icon">
                      <RiCheckboxCircleFill />
                    </span>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <div className="avatar-wrapper d-flex">
                    {/* You can add user avatars here */}
                  </div>
                  <span className="ms-2">{value?.percs[index]}%</span>
                </div>
              </div>
              {/* Progress Bar */}
              <div className="progress-poll mt-2">
                <div
                  className="progress-bar-poll"
                  role="progressbar"
                  style={{ width: `${value?.percs[index]}%` }} // Dynamically set progress bar width
                  aria-valuenow={value?.percs[index]}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          ))}
      </div>

      <div className="d-flex align-items-center p-2">
        <p
          className="mb-0 cursor"
          onClick={() => showVoteModal(value.id)}
          style={{ marginRight: '5px', color: '#007aff' }}
        >
          {value.votes_count} {value.votes_count > 1 ? lang.votes : lang.vote}
        </p>
        {value.duration && <GoDotFill size={10} style={{ color: '#848798' }} />}
        {value.duration && (
          <p className="mb-0 text-muted mx-2">
            {remainingTime}
          </p>
        )}
        {value.user.id !== user_id && (<>
        <GoDotFill size={10} style={{ color: '#848798' }} />
        
          <p
            className="mb-0 cursor"
            style={{ marginLeft: '5px', color: '#007aff' }}
            onClick={() => removePollAnswer(value.original_id)}
          >
            {lang.undo}
          </p>
          </>
        )}
      </div>
    </>
  );
};

export default PollResultsView;
