

import React from 'react';
import './commentsection.css';

const CommentShimmer = () => {
  return (
    <div className="container mt-2">
      <div className="row">
        {/* Profile Section */}
        <div className="col-md-12 d-flex align-items-center mb-4">
          <div className="shimmer-circle me-3"></div>
          <div className="flex-grow-1">
            <div className="shimmer-line mb-2" style={{ width: '60%' }}></div>
            <div className="shimmer-line" style={{ width: '60%' }}></div>
          </div>
          <div className="shimmer-button ms-auto"></div>
        </div>

        {/* Content Section */}
        <div className="col-md-12 mb-4">
          <div className="shimmer-line mb-2" style={{ width: '100%' }}></div>
          <div className="shimmer-line" style={{ width: '100%' }}></div>
        </div>

        {/* Footer Section */}
        <div className="col-md-12 d-flex  justify-content-between">
            <div className="d-flex align-items-center justify-content-center gap-2">

          <div className="shimmer-circle-small mb-0"></div>
          <div className="shimmer-button mb-0"></div>
          <div className="shimmer-circle-small mb-0"></div>
          <div className="shimmer-button mb-0"></div>
            </div>
          <div className="shimmer-button mb-0"></div>
        </div>
      </div>
      <hr style={{color: '#e8e9ec', opacity: '1'}} />
    </div>
  );
};

export default CommentShimmer;
