function TimestampConverter({ timestamp }) {
  const dt = new Date(timestamp * 1000); // Convert to milliseconds

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // timeZone: "UTC",
  };

  const formattedDate = dt.toLocaleString("en-US", options);

  return <div>{formattedDate}</div>;
}
export { TimestampConverter };