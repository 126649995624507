

import { useContext, useEffect, useMemo, useState } from "react";
import { Language, Sponsorpost } from "../../App";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle, IoIosArrowForward } from "react-icons/io";
import { Row } from "reactstrap";
import ImageComponent from "../common/ImageComponent";
import { CONTEST_DETAILS, LOGIN, NEW_CONTEST } from "../../constants/RoutesUrl";
import { ShimmerBadge, ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { MedicalQuizContest } from "../sponsor-post/MedicalQuizContest";

const _imagesURL = "https://djoejj0gd5srt.cloudfront.net"; // Ensure there are no spaces

export default function FeedSponsorPost({ new_contest_obj=[], loading }) {
  const { lang } = useContext(Language);
  const { updateContext } = useContext(Sponsorpost);
  const [showMedicalQuiz, setShowMedicalQuiz] = useState(false);
  const [medicalMedia, setMedicalMedia] = useState(null);
  const { data } = JSON.parse(localStorage.getItem("SignupData"));
 
  const Redirect = useNavigate();
  const indicatorStyles = useMemo(
    () => ({
      background: "#fff",
      width: 20,
      height: 4,
      display: "inline-block",
      margin: "0 4px",
      borderRadius: "1px",
    }),
    []
  );
  useEffect(()=>{
    const contestData = new_contest_obj.find(contest => contest.name === "contest_5");
    setMedicalMedia(contestData);
  },[new_contest_obj])
  const handleAdClick = async (ad) => {
    // Redirect(NEW_CONTEST);
    updateContext(ad);
    // Redirect(NEW_CONTEST);
    if (data?.token) {
      const apiEndpoint = `https://admin.medicalradar.es/endpoint/user/web/ads/click/contest_ads/${ad.id}`;

      try {
        await axios.post(apiEndpoint, { device: "Web" }, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });

      } catch (error) {
        console.error("Error in API call:", error);
      }
    } else {
      Redirect(LOGIN);
    }
  };

  return (
    <>
      {loading ? (
        <div className="my-3">
          <div
            className="container d-flex flex-column justify-content-between"
            style={{
              backgroundColor: 'white',
              height: '330px',
              width: '567px',
              borderRadius: '5px',
              padding: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            {/* Upper section with text and Join Now button */}
            <div className="d-flex justify-content-between align-items-center">
              <ShimmerBadge width={200} />
              <ShimmerButton size="sm" />
            </div>

            {/* Image section */}
            <ShimmerThumbnail height={250} width={400} className="" rounded />

            {/* Empty section for spacing (to structure similar to provided layout) */}
          </div>
        </div>
      ) : (
        <>
          {new_contest_obj && new_contest_obj.length > 0 ? (
            <div
              className="bg-white my-3 overflow-hidden br-6"
              style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
            >
              <Row>
                {/* Single Carousel containing multiple slides */}
                <Carousel
                  autoPlay={new_contest_obj.length>1}
                  infiniteLoop={new_contest_obj.length>1}
                  showIndicators={new_contest_obj.length>1}
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && new_contest_obj.length>1&& (
                      <IoIosArrowDroprightCircle
                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto end-0 me-3"
                        size={30}
                        color="white"
                        onClick={onClickHandler}
                      />
                    )
                  }
                  renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && new_contest_obj.length>1&& (
                      <IoIosArrowDropleftCircle
                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto ms-3 start-0"
                        size={30}
                        style={{ zIndex: "1" }}
                        color="white"
                        onClick={onClickHandler}
                      />
                    )
                  }
                  renderIndicator={(
                    onClickHandler,
                    isSelected,
                    index,
                    label
                  ) => {
                    if(new_contest_obj.length===1)return null;
                    return (
                      <>
                        {isSelected ? (
                          <li
                            style={{
                              ...indicatorStyles,
                              background: "#00c5de",
                            }}
                            aria-label={`Selected: ${label} ${index + 1
                              }`}
                            title={`Selected: ${label} ${index + 1
                              }`}
                          />
                        ) : (
                          <li
                            style={indicatorStyles}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            title={`${label} ${index + 1}`}
                            aria-label={`${label} ${index + 1}`}
                          />
                        )}
                      </>
                    );
                  }}

                // infiniteLoop={false}
                // showIndicators={true}
                // showThumbs={false}
                // showStatus={false}
                >
                  {new_contest_obj.map((contest, index) => (
                    <div key={index}>
                      <div
                        className="d-flex justify-content-between py-2 px-3 cursor"

                      >
                        <div className="align-self-end">
                          <h6 className="mb-0 pb-0">
                            {lang?.haz_click_para_participar}
                          </h6>
                        </div>
                        <div className="align-self-end d-flex align-items-center">
                          <p
                            className="mb-0 roboto-bold fs-14"
                            style={{ color: `#${contest?.color_code}` }} // Dynamic color code
                          >
                            {lang.join_now}
                          </p>{" "}
                          <IoIosArrowForward
                            color={`#${contest?.color_code}`}
                            size={25}
                          />
                        </div>
                      </div>
                      {/* Image from new media endpoint */}
                      <div onClick={() => {
                        handleAdClick(contest)
                        // updateContext(contest);
                        if(contest.name==="contest_1"){
                        Redirect(NEW_CONTEST);
                        }else if(contest.name==="contest_2"){
                          Redirect(CONTEST_DETAILS + "OCULOPLASTIA")
                        }else if(contest.name==="contest_3"){
                          Redirect(CONTEST_DETAILS +"RETINA-Y-UVEÍTIS")
                        }else if(contest.name==="contest_4"){
                          Redirect(NEW_CONTEST);
                        }else if(contest.name==="contest_5"){
                          setShowMedicalQuiz(true)
                        }
                      }}>
                        <ImageComponent
                          src={`${_imagesURL}${contest?.media}`} // Properly concatenate
                          img_height={"auto"}
                          img_width="100%"
                          loader_height={"300px"}
                          loader_width="100%"
                          compClassName="object-cover"
                        />
                      </div>
                    </div>
                  ))}
                </Carousel>
              </Row>
            </div>
          ) : (
            <div className="my-3">
              <div
                className="container d-flex flex-column justify-content-between"
                style={{
                  backgroundColor: 'white',
                  height: '370px',
                  width: '567px',
                  borderRadius: '5px',
                  padding: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                {/* Upper section with text and Join Now button */}
                <div className="d-flex justify-content-between align-items-center">
                  <ShimmerBadge width={200} />
                  <ShimmerButton size="sm" className="" />
                </div>

                {/* Image section */}
                <ShimmerThumbnail height={300} width={400} className="" rounded />

                {/* Empty section for spacing (to structure similar to provided layout) */}
              </div>
            </div>
          )}
        </>
      )}
     {showMedicalQuiz && <MedicalQuizContest contestMedia={medicalMedia.media} showMedicalQuiz={showMedicalQuiz} toggle={()=>setShowMedicalQuiz(!showMedicalQuiz)}/>}
    </>
  );
}

