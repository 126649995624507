import {
  CHANGE_PASSWORD,
  CHAT,
  COURSES,
  COURSE_DETAILS,
  COURSE_CONTENT,
  EVENTS,
  FEED,
  FORGOT_PASSWORD,
  JOBS,
  LOGIN,
  OTP_VERIFICATION,
  PROFILE,
  SIGNUP,
  SPECIALIZATION,
  ADDPOST,
  SETTINGMODULE,
  WEBINAR,
  EDIT_PROFILE,
  NOTIFICATIONS,
  SEARCH,
  EVENT_DETAILS,
  JOBDETAIL,
  MyReports,
  ADD_CLINICAL,
  PROFILEJOB,
  PROFILEEVENTS,
  PROFILECOURSES,
  LIVE_WEBINARS,
  PROFILEPOSTS,
  GROUPSETTING,
  CONTESTS,
  CONTEST_DETAILS,
  CLINICAL_SESSIONS,
  CLINICAL_SESSIONS_DETAILS,
  POST_DETAILS,
  POLL_DETAILS,
  WEBINAR_DETAILS,
  CLINICAL_DETAILS,
  CLINICAL_SESSION_DETAILS,
  SEE_ALL_COURSES,
  CONTEST_LIST,
  NEW_CONTEST,
  COURSE_SHARED_POST,
  EVENT_SHARED_POST,
  SEE_ALL_TRENDING_PAGE,
  SEE_ALL_FOLLOWING_DOCTOR_PAGE,
  SEE_ALL_POPULAR_COURSES_PAGE,
  SEE_ALL_PURCHASED_COURSES_PAGE,
  NEW_COURSE_DETAILS,
  NEW_MANAGE_CONTENT,
  SEE_ALL_SUGGESTED_EVENTS,
  WALLET,
  payPalCallback,
  PAYPAL_PAYMENT,
  NEW_COURSE_SECTION,
  NEW_COURSE_MEDIA,
  NEW_COURSE_EDIT,
  COURSE_CHAPTER_DETAILS,
  Chapter_Quiz,
} from "./RoutesUrl";
import LoggedInRoute from "./LoggedInRoute";
import ProtectedRoute from "./ProtectedRoute";
import { createBrowserRouter } from "react-router-dom";
import React, { lazy } from "react";
import MedicalRadarLoader from "../components/common/MedicalRadarLoader";
import Loadable from "../components/common/Loadable";
const Feed = Loadable(lazy(() => import("../pages/Feed")));
const Specialization = Loadable(lazy(() => import("../pages/auth/Specialization")));
const Courses = Loadable(lazy(() => import("../pages/course/Courses")));
const Events = Loadable(lazy(() => import("../pages/Events")));
const Jobs = Loadable(lazy(() => import("../pages/Jobs")));
const Profile = Loadable(lazy(() => import("../pages/Profile")));
const ChatModule = Loadable(lazy(() => import("../pages/chat/ChatModule")));
const SettingModule = Loadable(lazy(() => import("../pages/setting/SettingModule")));
const WebinarVideoCall = Loadable(lazy(() => import("../components/webinar/WebinarVideoCall")));
const EditProfileInfo = Loadable(lazy(() => import("../pages/setting/EditProfileInfo")));
const Index = Loadable(lazy(() => import("../pages/notifications/Index")));
const Search = Loadable(lazy(() => import("../pages/search/Search")));
const EventsDetails = Loadable(lazy(() => import("../pages/events/EventsDetails")));
const JobDetails = Loadable(lazy(() => import("../components/jobs/JobDetails")));
const Create_Complaint = Loadable(lazy(() => import("../components/manage_account/Create_Complaint")));
const ProfileJob = Loadable(lazy(() => import("../components/jobs/ProfileJob")));
const ProfileEvents = Loadable(lazy(() => import("../components/events/ProfileEvents")));
const ProfileCourses = Loadable(lazy(() => import("../components/courses/ProfileCourses")));
const LiveWebinars = Loadable(lazy(() => import("../components/live-webinars/LiveWebinars")));
const ProfilePost = Loadable(lazy(() => import("../components/feeds/ProfilePost")));
const GroupSettings = Loadable(lazy(() => import("../pages/GroupSetting/GroupSettings")));
const Contest = Loadable(lazy(() => import("../pages/sponsor-post/Contest")));
const ContestDetail = Loadable(lazy(() => import("../components/sponsor-post/ContestDetail")));
const ClinicalSessions = Loadable(lazy(() => import("../pages/clinical-sessions/ClinicalSessions")));
const ClinicalSessionsDetails = Loadable(lazy(() => import("../pages/clinical-sessions/ClinicalSessionsDetails")));
const PostsDetails = Loadable(lazy(() => import("../pages/post-details/PostsDetails")));
const SeeAllCourses = Loadable(lazy(() => import("../components/courses/SeeAllCourses")));
const ContestList = Loadable(lazy(() => import("../components/sponsor-post/ContestList")));
const NewContest = Loadable(lazy(() => import("../components/sponsor-post/NewContest")));
const CourseSharedPost = Loadable(lazy(() => import("../pages/post-details/CourseSharedPost")));
const EventSharedPost = Loadable(lazy(() => import("../pages/post-details/EventSharedPost")));
const DoctorsSearch = Loadable(lazy(() => import("../pages/search/DoctorsSearch")));
const PostsSearch = Loadable(lazy(() => import("../pages/search/PostsSearch")));
const WebinarsSearch = Loadable(lazy(() => import("../pages/search/WebinarsSearch")));
const ClinicalStudiesSearch = Loadable(lazy(() => import("../pages/search/ClinicalStudiesSearch")));
const JobsSearch = Loadable(lazy(() => import("../pages/search/JobsSearch")));
const CoursesSearch = Loadable(lazy(() => import("../pages/search/CoursesSearch")));
const EventsSearch = Loadable(lazy(() => import("../pages/search/EventsSearch")));
const SeeAllTrendingPage = Loadable(lazy(() => import("../components/courses/See-All-Components/SeeAllTrendingPage")));
const SeeAllFollowingDoctorPage = Loadable(lazy(() => import("../components/courses/See-All-Components/SeeAllFollowingDoctorPage")));
const SeeAllPopularCoursesPage = Loadable(lazy(() => import("../components/courses/See-All-Components/SeeAllPopularCoursesPage")));
const SeeAllPurchasedCourses = Loadable(lazy(() => import("../components/courses/See-All-Components/SeeAllPurchasedCourses")));
const NewCourseDetail = Loadable(lazy(() => import("../components/course-details/NewCourseDetail")));
const SeeAllSuggestedEvents = Loadable(lazy(() => import("../pages/events/see-all-events/SeeAllSuggestedEvents")));
const Wallet = Loadable(lazy(() => import("../pages/setting/wallet/Wallet")));
const PayPalCallback = Loadable(lazy(() => import("../pages/setting/wallet/PayPalCallback")));
const NewLogin = Loadable(lazy(() => import("../pages/auth/newauth/NewLogin")));
const NewForgotPassword = Loadable(lazy(() => import("../pages/auth/newauth/NewForgotPassword")));
const NewResetPassword = Loadable(lazy(() => import("../pages/auth/newauth/NewResetPassword")));
const NewSignup = Loadable(lazy(() => import("../pages/auth/newauth/NewSignup")));
const PaypalPaymentPage = Loadable(lazy(() => import("../components/course-details/PaypalPaymentPage")));
const NewManageContentPage = Loadable(lazy(() => import("../components/course-details/NewManageContentPage")));
const NewCourseSection = Loadable(lazy(() => import("../components/course-details/NewCourseSection")));
const NewCourseMedia = Loadable(lazy(() => import("../components/course-details/NewCourseMedia")));
const NewEditCourse = Loadable(lazy(() => import("../components/course-details/NewEditCourse")));
const CourseChapterDetails = Loadable(lazy(() => import("../components/course-details/course-content-details/CourseChapterDetails")));
const ChapterQuiz = Loadable(lazy(() => import("../pages/ChapterQuiz")));

const Router = createBrowserRouter([
  {
    path: LOGIN,
    element: (
      <LoggedInRoute>
        <NewLogin />  
      </LoggedInRoute>
    ),
  },
  {
    path: SIGNUP,
    element: (
      <LoggedInRoute>
        <NewSignup />
      </LoggedInRoute>
    ),
  },
  {
    path: JOBDETAIL + "/:id",
    element: (
      <ProtectedRoute>
        <JobDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/search/doctors",
    element: (
      <ProtectedRoute>
        <DoctorsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: "/search/posts",
    element: (
      <ProtectedRoute>
        <PostsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: "/search/webinars",
    element: (
      <ProtectedRoute>
        <WebinarsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: "/search/clinicals",
    element: (
      <ProtectedRoute>
        <ClinicalStudiesSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: "/search/jobs",
    element: (
      <ProtectedRoute>
        <JobsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: "/search/courses",
    element: (
      <ProtectedRoute>
        <CoursesSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: "/search/events",
    element: (
      <ProtectedRoute>
        <EventsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: MyReports,
    element: (
      <ProtectedRoute>
        <Create_Complaint />
      </ProtectedRoute>
    ),
  },
  {
    path: SPECIALIZATION,
    element: (
      <ProtectedRoute>
        <Specialization />
      </ProtectedRoute>
    ),
  },
  {
    path: PROFILE + "/:id",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: FORGOT_PASSWORD,
    element: (
      <LoggedInRoute>
        <NewForgotPassword />
      </LoggedInRoute>
    ),
  },
  {
    path: CHANGE_PASSWORD,
    element: (
      <LoggedInRoute>
        <NewResetPassword />
      </LoggedInRoute>
    ),
  },
  {
    path: FEED,
    element: (
      <ProtectedRoute>
        <Feed />
      </ProtectedRoute>
    ),
  },
  {
    path: EVENTS + "/:id",
    element: (
      <ProtectedRoute>
        <Events />
      </ProtectedRoute>
    ),
  },
  {
    path: JOBS + "/:id",
    element: (
      <ProtectedRoute>
        <Jobs />
      </ProtectedRoute>
    ),
  },
  {
    path: PROFILEJOB + "/:id",
    element: (
      <ProtectedRoute>
        <ProfileJob />
      </ProtectedRoute>
    ),
  },
  {
    path: PROFILEEVENTS + "/:id",
    element: (
      <ProtectedRoute>
        <ProfileEvents />
      </ProtectedRoute>
    ),
  },
  {
    path: PROFILECOURSES + "/:id",
    element: (
      <ProtectedRoute>
        <ProfileCourses />
      </ProtectedRoute>
    ),
  },
  {
    path: PROFILEPOSTS + "/:id",
    element: (
      <ProtectedRoute>
        <ProfilePost />
      </ProtectedRoute>
    ),
  },
  {
    path: COURSES + "/:id",
    element: (
      <ProtectedRoute>
        <Courses />
      </ProtectedRoute>
    ),
  },
  {
    path: CHAT,
    element: (
      <ProtectedRoute>
        <ChatModule />
      </ProtectedRoute>
    ),
  },
  {
    path: EDIT_PROFILE,
    element: (
      <ProtectedRoute>
        <EditProfileInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: SETTINGMODULE + "/:id",
    element: (
      <ProtectedRoute>
        <SettingModule />
      </ProtectedRoute>
    ),
  },
  {
    path: WALLET,
    element: (
      <ProtectedRoute>
        <Wallet />
      </ProtectedRoute>
    ),
  },
  {
    path: payPalCallback,
    element: (
      <ProtectedRoute>
        <PayPalCallback />
      </ProtectedRoute>
    ),
  },
  {
    path: NOTIFICATIONS,
    element: (
      <ProtectedRoute>
        <Index />
      </ProtectedRoute>
    ),
  },
  {
    path: WEBINAR,
    element: (
      <ProtectedRoute>
        <WebinarVideoCall />
      </ProtectedRoute>
    ),
  },
  {
    path: SEARCH,
    element: (
      <ProtectedRoute>
        <Search />
      </ProtectedRoute>
    ),
  },
  {
    path: EVENT_DETAILS + ":id",
    element: (
      <ProtectedRoute>
        <EventsDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: LIVE_WEBINARS,
    element: (
      <ProtectedRoute>
        <LiveWebinars />
      </ProtectedRoute>
    ),
  },
  {
    path: GROUPSETTING,
    element: (
      <ProtectedRoute>
        <GroupSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: CONTESTS,
    element: (
      <ProtectedRoute>
        <Contest />
      </ProtectedRoute>
    ),
  },
  {
    path: CONTEST_LIST,
    element: (
      <ProtectedRoute>
        <ContestList />
      </ProtectedRoute>
    ),
  },
  {
    path: CONTEST_DETAILS + ":type",
    element: (
      <ProtectedRoute>
        <ContestDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: CLINICAL_SESSIONS,
    element: (
      <ProtectedRoute>
        <ClinicalSessions />
      </ProtectedRoute>
    ),
  },
  {
    path: CLINICAL_SESSIONS_DETAILS + ":id",
    element: (
      <ProtectedRoute>
        <ClinicalSessionsDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: POST_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: WEBINAR_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: CLINICAL_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: CLINICAL_SESSION_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: POLL_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: COURSE_SHARED_POST + ":course_id",
    element: <CourseSharedPost />,
  },
  {
    path: EVENT_SHARED_POST + ":event_id",
    element: <EventSharedPost />,
  },
  {
    path: SEE_ALL_COURSES,
    element: (
      <ProtectedRoute>
        <SeeAllCourses />
      </ProtectedRoute>
    ),
  },
  {
    path: NEW_CONTEST,
    element: (
      <ProtectedRoute>
        <NewContest />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_TRENDING_PAGE,
    element: (
      <ProtectedRoute>
        <SeeAllTrendingPage />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_FOLLOWING_DOCTOR_PAGE,
    element: (
      <ProtectedRoute>
        <SeeAllFollowingDoctorPage />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_POPULAR_COURSES_PAGE,
    element: (
      <ProtectedRoute>
        <SeeAllPopularCoursesPage />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_PURCHASED_COURSES_PAGE,
    element: (
      <ProtectedRoute>
        <SeeAllPurchasedCourses />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_SUGGESTED_EVENTS,
    element: (
      <ProtectedRoute>
        <SeeAllSuggestedEvents />
      </ProtectedRoute>
    ),
  },
  {
    path: NEW_COURSE_DETAILS + ":course_id",
    element: (
      <ProtectedRoute>
        <NewCourseDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: PAYPAL_PAYMENT,
    element: (
      <ProtectedRoute>
        <PaypalPaymentPage />
      </ProtectedRoute>
    ),
  },
  {
    path: NEW_MANAGE_CONTENT + ":course_id",
    element: (
      <ProtectedRoute>
        <NewManageContentPage />
      </ProtectedRoute>
    ),
  },
  {
    path: NEW_COURSE_SECTION + ":chapter_id",
    element: (
      <ProtectedRoute>
        <NewCourseSection />
      </ProtectedRoute>
    ),
  },
  {
    path: NEW_COURSE_MEDIA + ":section_id",
    element: (
      <ProtectedRoute>
        <NewCourseMedia />
      </ProtectedRoute>
    ),
  },
  {
    path: NEW_COURSE_EDIT,
    element: (
      <ProtectedRoute>
        <NewEditCourse />
      </ProtectedRoute>
    ),
  },
  {
    path: COURSE_CHAPTER_DETAILS+ ":course_id/:chapter_id?",
    element: (
      <ProtectedRoute>
        <CourseChapterDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: Chapter_Quiz + ":quiz_id",
    element: (
      <ProtectedRoute>
        <ChapterQuiz />
      </ProtectedRoute>
    ),
  },
]);

export default Router;