import React, { useContext, useState } from "react";
import { Col, Row } from "reactstrap";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import PollIcon from "../../assets/images/icons/poll-icon.svg";
import WebinarIcon from "../../assets/images/icons/webinar-icon.svg";
import ClinicalStudyIcon from "../../assets/images/icons/clinical-study-icon.svg";
import ClincalSessionIcon from "../../assets/images/icons/ic_clinical_sessions.svg";
import { _imagesURL } from "../../constants/API";
import { useNavigate } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import { Language, LoginUserData } from "../../App";
import { RiEditLine } from "react-icons/ri";
import { CiCamera } from "react-icons/ci";
import { GoDeviceCameraVideo } from "react-icons/go";
import CreatePostPopup from "./CreatePostPopup";
import ProgressPopup from "./postTabs/ProgressPopup";

import InviteWebinarPopup from "./postTabs/InviteWebinarPopup";
import ClinicalSessionInvitePopup from "./postTabs/ClinicalSessionInvitePopup";
import { useEffect } from "react";
import { MedicalQuizContest } from "../sponsor-post/MedicalQuizContest";


const PostForm = ({selectedPost,editWebinar,setEditWebinar,showPost,setShowPost,refetch}) => {
  const { lang } = useContext(Language);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [titleMessage, setTitleMessage] = useState("");
  const [inviteWebinar,setInviteWebinar] = useState(false)
  const [inviteClinical,setInviteClincal] = useState(false)
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [webinarId, setWebinarId] = useState("");
  const [clinicalId, setClinicalId] = useState("");
  const handleApiCall = async (apiFunction, { type }) => {
    if (type === "poll") {
      setTitleMessage(lang.uploading_your_poll);
    } else if (type === "webinar") {
      setTitleMessage(lang.uploading_your_webinar);
    }else if (type==="clinical-session"){
      setTitleMessage(lang.uploading_your_clinical_session);
    }else if(type==="post"){
      setTitleMessage(lang.uploading_your_post)
    }else if(type==="clinical-study"){
      setTitleMessage(lang.uploading_your_clinical_study)
    }
    
    setShowProgress(true); // Show progress popup
    console.info("Type", type)
  
    try {
      // Call the API function and wait for it to complete
      const response = await apiFunction();
      // Handle success
      if (response) {
        // Hide progress indicator
        setShowProgress(false);
  
        // Display success toast based on the type
        if (type === "poll") {
          // toast.success(lang.poll_added_successfully);
        } else if (type === "webinar") {
          // toast.success("Webinar added successfully");
          if(response.data.post.id){
            setWebinarId(response.data.post.id)
            setInviteWebinar(true)
          }
        }else if(type==="clinical-session"){
          // toast.success("Clinical session added successfully");
          if(response.data.post.id){
            setClinicalId(response.data.post.id)
            setInviteClincal(true)
          }
        }
  
        // Refetch any data after the API call is successful
        if (type !== "clinical-study") {
          refetch();
        }
        setProgress(0)
      } else {
        // Handle unexpected response status
        setShowProgress(false);
        // toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      setShowProgress(false);
      // toast.error("Something went wrong. Please try again.");
      console.error(error);
    } finally {
      // Hide the progress indicator in case of success or error
      setShowProgress(false);
    }
  };

  const pages = [
    { src: PollIcon, label: lang.poll, type: 2 },
    { src: WebinarIcon, label: lang.webinar, type: 3 },
    { src: ClinicalStudyIcon, label: lang.clinical_study, type: 4 },
    { src: ClincalSessionIcon, label: lang.clinical_session, type: 5 },
    // { src: GoLiveIcon, label: lang.go_live, type: 5 },
  ];
  const { splashData } = useContext(LoginUserData);
  const Navigate = useNavigate();

  const handleLabelClick = (label) => {
    setSelectedLabel(label);
  };
useEffect(()=>{
  if(navigator.language.startsWith("en")){
    setSelectedLabel("Publish")
  }else{
    setSelectedLabel("Publicar")
  }
},[])
  return (
    <>
    <div
      className="bg-white feed-form-shadow rounded scroll-hide cursor"
      onClick={() => {
        // handleNavigate(1);
        setShowPost(true);
      }}
      style={{
        border: "0.5px solid rgba(0,0,0,0.25)",
        // height: "173px",
        overflowX:"auto",
        maxWidth: "570px",
        whiteSpace:"nowrap"
      }}
    >
      <div className="d-flex">
        <div className="d-flex align-items-center gap-2 mx-3">
          <RiEditLine size={16} />{" "}
          <p className="roboto-medium fs-14 mb-0">{lang.publish}</p>
        </div>
        <div
          className="d-flex"
          style={{
            borderBottom: "1px solid rgba(29, 193, 221, 0.2)",
            borderLeft: "1px solid rgba(29, 193, 221, 0.2)",
            width: '590px'
          }}
        >
          {pages.map((val, i) => (
            <div className="d-flex align-items-center gap-2 p-3" key={i} onClick={()=>handleLabelClick(val.label)}>
              <img src={val.src} width={16} height={16} alt="icon" />
              <p
                className="roboto-regular fs-14 mb-0"
                style={{ color: "#848798" }}
                
              >
                {val.label}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Row className="py-2 px-3">
        <Col className="d-flex">
          <div>
            <div
              className="rounded-circle"
              style={{
                border: "1px solid #1DC1DD",
                maxWidth: "48px",
                maxHeight: "48px",
                padding: "1px",
              }}
            >
              <ImageComponent
                src={
                  splashData && splashData.user && splashData.user.image !== ""
                    ? _imagesURL + splashData.user.image
                    : Avatar
                }
                compClassName="rounded-circle"
                loader_height="44px"
                loader_width="44px"
                img_height="44px"
                img_width="44px"
                roundShimmerSize={44}
              />
            </div>
          </div>
          <div className="w-100 align-self-center">
            <textarea
            onClick={()=>setSelectedLabel(lang.publish)}
            
              className="form-control border-0 cursor fs-md-14"
              placeholder={lang?.post_form_placeholder}
              rows="1"
               style={{
    pointerEvents: 'none',
    userSelect: 'none',
  }}
            ></textarea>
          </div>
        </Col>
      </Row>
      <div
        className="d-flex align-items-center"
        style={{ borderTop: "1px solid rgba(29, 193, 221, 0.2)",  width: '590px' }}
      >
        <button
          className="d-flex align-items-center gap-2 rounded-pill border-0 px-3 py-1 m-2"
          style={{ backgroundColor: "rgba(247, 247, 247, 1)" }}
          onClick={()=>setSelectedLabel(lang.publish)}
        >
          <CiCamera size={16} color="rgba(132, 135, 152, 1)" />{" "}
          <p
            className="roboto-regular fs-14 mb-0"
            style={{ color: "rgba(132, 135, 152, 1)" }}
          >
            {lang.images}
          </p>
        </button>
        <button
          className="d-flex align-items-center gap-2 rounded-pill border-0 px-3 py-1 mx-2"
          style={{ backgroundColor: "rgba(247, 247, 247, 1)" }}
          onClick={()=>setSelectedLabel(lang.publish)}
        >
          <GoDeviceCameraVideo size={16} color="rgba(132, 135, 152, 1)" />{" "}
          <p
            className="roboto-regular fs-14 mb-0"
            style={{ color: "rgba(132, 135, 152, 1)" }}
          >
            {lang.videos}
          </p>
        </button>
      </div>
    </div>
  {showPost&&<CreatePostPopup selectedLabel={selectedLabel} refetch={refetch} selectedPost={selectedPost} editWebinar={editWebinar} setEditWebinar={setEditWebinar} show={showPost} onHide={() => setShowPost(false)} setProgress={setProgress} handleApiCall={handleApiCall}/>}
  {showProgress&&<ProgressPopup
        title={titleMessage}
        message={lang.uploading_in_progress}
        progress={progress}
        isVisible={showProgress}
      />}
      {inviteWebinar&&<InviteWebinarPopup
      isOpen={inviteWebinar}
      webinarId={webinarId}
      toggleModal={()=>setInviteWebinar(!inviteWebinar)}
      />}
     {inviteClinical&& <ClinicalSessionInvitePopup
      isOpen={inviteClinical}
      toggleModal={()=>setInviteClincal(!inviteClinical)}
      clinicalId={clinicalId}
      />}
      {/* <MedicalQuizContest/> */}
    </>
  );
};

export default PostForm;


