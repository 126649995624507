import React, { useRef, useEffect } from "react";
import { Collapse, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl.js";
import { BiEditAlt, BiRepost, BiUserVoice } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import { MdOutlineReport } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { TbMailPlus } from "react-icons/tb";
import { FaRegShareFromSquare } from "react-icons/fa6";
import Save from "../../assets/images/icons/save.svg";
import Saved from "../../assets/images/icons/saved.svg";
import { RepostCard } from "./PostFooter.jsx";

const PostHeaderDropdown = ({
  postDetails,
  postDropDown,
  dropDownId,
  type,
  isSave,
  isShare,
  shares_count,
  isFollow,
  isOwner,
  user_id,
  lang,
  handleSave,
  handleRepost,
  handleFollow,
  setReportModal,
  setEditModal,
  setDeleteModal,
  setShowPost,
  editWebinarTab,
  handlePostSelect,
  setInviteWebinar,
  setWebinarType,
  setShareModal,
  setIsOpen,
  isOpen,
  toggleRepostModal,
  setEditPostInput,
  from,
  closeDetailModal
}) => {
  const collapseRef = useRef(null);
  const RepostCollapse = useRef(null);
  const RepostbuttonRef = useRef(null);
  const [ignoreNextOutsideClick, setIgnoreNextOutsideClick] = React.useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ignoreNextOutsideClick) {
        setIgnoreNextOutsideClick(false);
        return;
      }

      const isClickInsideCollapse = collapseRef.current?.contains(event.target);
      const isClickInsideRepostCollapse = RepostCollapse.current?.contains(event.target);
      const dotsButton = document.querySelector(".dots-button");
      const isClickInsideDotsButton = dotsButton?.contains(event.target);
      const isClickInsidePopup = document.querySelector(".modal.d-block")?.contains(event.target);

      // Only close if the click is outside all relevant elements
      if (
        !isClickInsideCollapse &&
        !isClickInsideDotsButton &&
        !isClickInsideRepostCollapse &&
        !isClickInsidePopup
      ) {
        setIsOpen(false);
      }
    };

    if (postDropDown || isOpen) {
      document.addEventListener("mousedown", handleClickOutside); // Use mousedown for better reliability
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [postDropDown, isOpen, ignoreNextOutsideClick, setIsOpen]);

  const handleMenuItemClick = (callback) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIgnoreNextOutsideClick(true);
    if (callback) callback();
  };

  const repostTitle = isOwner
    ? `${shares_count} ${lang?.repost}`
    : `${isShare ? lang?.reposted : lang?.repost}`;

  const handleRepostClick = (e) => {
    e.stopPropagation();
    if (type !== "poll") {
      if (!isOwner) {
        handleRepost(postDetails?.original_id);
      } else {
        toggleRepostModal(postDetails?.original_id);
      }
    } else {
      if (isOwner) {
        toggleRepostModal(postDetails?.original_id);
      } else {
        if (!isShare) {
          setIsOpen(!isOpen);
        } else {
          handleRepost(postDetails?.original_id);
        }
      }
    }
  };

  return (
    <div
      className="position-absolute end-0"
      style={{
        zIndex: "2",
        // marginTop: from? type === "webinar" ? (isOwner ? "16rem" : "10rem") : "16rem",
        // width: from?"214px":"39%",
        marginTop: from ? "0" : (type === "webinar" ? (isOwner ? "16rem" : "10rem") : "16rem"),
width: from ? "214px" : "39%",

      }}
    >
      <Collapse
        innerRef={collapseRef}
        onClick={(e) => {
          e.stopPropagation();
          setIgnoreNextOutsideClick(true);
        }}
        isOpen={postDropDown && postDetails?.original_id === dropDownId}
        className="bg-white rounded p-1 text-center"
        style={{ boxShadow: "5px 5px 6px 6px rgba(0, 0, 0, 0.16)" }}
      >
        {type === "webinar" && !isOwner && (
          <div
            className="d-flex manue-hover cursor justify-content-start align-items-center"
            onClick={() => handleFollow(postDetails?.user?.id)}
          >
            <span className="mx-2">
              {isFollow ? (
                <AiOutlineUserDelete color="#212529" size={20} />
              ) : (
                <AiOutlineUserAdd color="#212529" size={20} />
              )}
            </span>
            <h6 className="mb-0 mx-1 p-2 rounded cursor fs-md-12 mt-1">
              {isFollow ? lang?.unfollow : lang?.follow}
            </h6>
          </div>
        )}
        {type === "webinar" && isOwner && (
          <>
            <div
              className="d-flex manue-hover cursor justify-content-start align-items-center"
              onClick={() => setInviteWebinar(true)}
            >
              <span className="mx-2">
                <TbMailPlus size={20} />
              </span>
              <h6 className="mb-0 p-2 rounded cursor fs-md-12 mt-1">
                {lang?.send_invites}
              </h6>
            </div>
            <div
              className="d-flex manue-hover cursor justify-content-start align-items-center"
              onClick={() => {
                setInviteWebinar(true);
                setWebinarType("host");
              }}
            >
              <span className="mx-2">
                <BiUserVoice size={20} />
              </span>
              <h6 className="mb-0 p-2 rounded cursor fs-md-12 mt-1">
                {lang?.set_host}
              </h6>
            </div>
          </>
        )}
        {type !== "webinar" && (
          <>
            <div
              className="d-flex manue-hover cursor justify-content-start align-items-center"
              onClick={handleMenuItemClick(() => handleSave(postDetails?.original_id))}
            >
              <span className="mx-2">
                <img width={14} src={isSave ? Saved : Save} alt="..." />
              </span>
              <h6 className="mb-0 mx-1 p-2 rounded cursor fs-md-12 mt-1">
                {isSave ? lang?.saved : lang?.save}
              </h6>
            </div>
            <div
              className="d-flex manue-hover cursor justify-content-start align-items-center"
              ref={RepostbuttonRef}
              onClick={handleRepostClick}
            >
              <span className="mx-2">
                <BiRepost size={20} />
              </span>
              <h6 className="mb-0 p-2 rounded cursor fs-md-12 mt-1">
                {repostTitle}
              </h6>
            </div>
          </>
        )}
        {postDetails?.user?.id === user_id && type !== "poll" && type !== "clinical-image" && postDetails.status !== 1 && (
          <div
            className="d-flex manue-hover cursor justify-content-start align-items-center"
            onClick={() => {
                setEditPostInput(postDetails?.caption);
              
              if (type === "webinar") {
                setShowPost(true);
                editWebinarTab(true);
                handlePostSelect(postDetails);
              }else{
                setEditModal(true);
              }
            }}
          >
            <span className="mx-2">
              <BiEditAlt size={20} />
            </span>
            <h6 className="mb-0 p-2 rounded cursor fs-md-12 mt-1">
              {lang?.edit}
            </h6>
          </div>
        )}
        <Link onClick={()=>closeDetailModal(true)} style={{ color: "#212529" }} to={PROFILE + "/" + postDetails?.user?.id}>
          <div className="d-flex manue-hover cursor justify-content-start align-items-center">
            <span className="mx-2">
              <IoMdInformationCircleOutline color="#212529" size={20} />
            </span>
            <h6 className="mb-0 p-2 rounded cursor fs-md-12 mt-1"  style={{whiteSpace: 'nowrap'}}>
              {lang.about_this_account}
            </h6>
          </div>
        </Link>
        <div
          onClick={handleMenuItemClick(() => setShareModal(true))}
          className="d-flex manue-hover cursor justify-content-start align-items-center"
        >
          <span className="mx-2">
            <FaRegShareFromSquare size={20} />
          </span>
          <h6 className="mb-0 p-2 rounded cursor fs-md-12">
            {lang?.share || "Share"}
          </h6>
        </div>
        {postDetails?.user?.id === user_id && (
          <div
            onClick={handleMenuItemClick(() => setDeleteModal(true))}
            className="d-flex manue-hover cursor justify-content-start align-items-center"
          >
            <span className="mx-2">
              <RiDeleteBinLine color="#ff0000" size={20} />
            </span>
            <h6 style={{ color: "#ff0000" }} className="mb-0 p-2 rounded cursor fs-md-12">
              {lang?.delete}
            </h6>
          </div>
        )}
        {postDetails?.user?.id !== user_id && (
          <div
            onClick={() => setReportModal(true)}
            className="d-flex manue-hover cursor justify-content-start align-items-center"
          >
            <span className="mx-2">
              <MdOutlineReport color="#ff0000" size={20} />
            </span>
            <h6 style={{ color: "#ff0000" }} className="mb-0 p-2 rounded cursor fs-md-12">
              {lang?.report}
            </h6>
          </div>
        )}
      </Collapse>
      <div ref={RepostCollapse}>
        {isOpen && (
          <Card
            style={{
              position: "absolute",
              top: "96px",
              left: "-15px",
              zIndex: 1000,
              width: "308px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <RepostCard
              repost={handleRepost}
              original_id={postDetails?.original_id}
              toggleCollapse={() => setIsOpen(false)}
              pollData={postDetails}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

export default PostHeaderDropdown;