import { RouterProvider } from "react-router-dom";
import Router from "./constants/Routing";
import { createContext, Suspense, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { analytics, logEvent } from './constants/firebase-config';
import English from "./lang/en.json";
import Spanish from "./lang/sp.json";

import FontFaceObserver from "fontfaceobserver";
import { QueryClient, QueryClientProvider } from "react-query";
import MedicalRadarLoader from "./components/common/MedicalRadarLoader";

const queryClient = new QueryClient();

export const Sponsorpost = createContext();
export const Language = createContext();
export const ScrollCordinates = createContext();
export const LoginUserData = createContext();
export const FeedType = createContext();
export const FeedDataRefetch = createContext();
export const PostDetailContext = createContext();

function App() {
  const id = JSON.parse(localStorage.getItem("SignupData"))?.data?.id || null;

  const [postImage, setPostImage] = useState("");
  const [postDetail, setPostDetail] = useState({});
  const [isOpen, setIsOpen] = useState(!postDetail.original_id);
  const [lang, setLang] = useState(() => {
    const savedLang = localStorage.getItem("selectedLanguage");
    return savedLang === "Spanish" ? Spanish : English; // Default to English
  });

  const updateLang = (newLanguage) => {
    if (newLanguage === "English") {
      setLang(English);
      localStorage.setItem("selectedLanguage", "English");
    } else if (newLanguage === "Spanish") {
      setLang(Spanish);
      localStorage.setItem("selectedLanguage", "Spanish");
    }
  };

  const [refetch, setRefetch] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [splashData, setSplashData] = useState({
    user: {
      id: id,
    },
  });
  const [feedType, setFeedType] = useState(
    () => sessionStorage.getItem("FeedType") || "all"
  );

  const updateScrollPosition = (newValue) => setScrollPosition(newValue);
  const updateContext = (newValue) => setPostImage(newValue);
  const updateSplash = (newValue) => setSplashData(newValue);
  // const updateLang = (newValue) => setLang(newValue);
  const updateFeedType = (newValue) => {
    setFeedType(newValue);
    sessionStorage.setItem("FeedType", newValue);
  };
  const updateRefetch = (newValue) => setRefetch(newValue);

  const updatePostDetail = (newValue) => {
    setPostDetail(newValue);
    setIsOpen(true);
  };

  const postModalClose = () => {
    setPostDetail({});
    setIsOpen(false);
  };

  useEffect(() => {
    document.body.classList.add("scroll-hide");
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    sessionStorage.setItem("user_timezone", userTimezone);

    if (navigator.language.startsWith("en")) {
      setLang(English);
    } else {
      setLang(Spanish);
    }
  }, []);
  useEffect(() => {
    // Use logEvent instead of analytics.logEvent
    const startTime = performance.now();

    // Page load finished, log the event
    window.addEventListener('load', () => {
      const endTime = performance.now();
      const pageLoadTime = endTime - startTime;

      logEvent(analytics, 'page_load_time', {
        load_time: pageLoadTime,
      });
    });
    logEvent(analytics, 'app_loaded', { page_path: window.location.pathname });
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const loadFonts = async () => {
      const fontRegular = new FontFaceObserver("Noto-regular");
      const fontMedium = new FontFaceObserver("Noto-medium");
      const fontBold = new FontFaceObserver("Noto-bold");
      const robotoRegular = new FontFaceObserver("realRoboto-regular");
      const robotoMedium = new FontFaceObserver("realRoboto-medium");
      const robotoBold = new FontFaceObserver("realRoboto-bold");
      const outfitRegular = new FontFaceObserver("Outfit-regular");
      const outfitMedium = new FontFaceObserver("Outfit-medium");
      const outfitSemiBold = new FontFaceObserver("Outfit-semibold");
      const outfitBold = new FontFaceObserver("Outfit-bold");

      try {
        await Promise.all([
          fontRegular.load(),
          fontMedium.load(),
          fontBold.load(),
          robotoRegular.load(),
          robotoMedium.load(),
          robotoBold.load(),
          outfitRegular.load(),
          outfitMedium.load(),
          outfitSemiBold.load(),
          outfitBold.load(),
        ]);

        setFontsLoaded(true);
      } catch (error) {
        console.error("Fonts failed to load:", error);
        setFontsLoaded(true); // Proceed even if fonts fail to load
      }
    };

    loadFonts();
  }, []);

  if (!fontsLoaded) {
    return ; // Show a loader while fonts load
  }

  return (
    <QueryClientProvider client={queryClient}>
      <LoginUserData.Provider value={{ splashData, updateSplash, lang, updateLang }}>
        <Language.Provider value={{ lang, updateLang }}>
          <ScrollCordinates.Provider
            value={{ scrollPosition, updateScrollPosition }}
          >
            <Sponsorpost.Provider value={{ postImage, updateContext }}>
              <FeedType.Provider value={{ feedType, updateFeedType }}>
                <FeedDataRefetch.Provider value={{ refetch, updateRefetch }}>
                  <PostDetailContext.Provider
                    value={{
                      postDetail,
                      isOpen,
                      postModalClose,
                      updatePostDetail,
                    }}
                  >
                    <ToastContainer
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="dark"
                    />
                      <RouterProvider router={Router} />
                  </PostDetailContext.Provider>
                </FeedDataRefetch.Provider>
              </FeedType.Provider>
            </Sponsorpost.Provider>
          </ScrollCordinates.Provider>
        </Language.Provider>
      </LoginUserData.Provider>
    </QueryClientProvider>
  );
}

export default App;
