import React, { useContext } from 'react';
import { GoDotFill } from 'react-icons/go';
import { Language } from '../App';
import usePollRemainingTime from '../hooks/usePollRemainingTime';

const PollOptionsView = ({ value, handlePollAnswer, toggleModal }) => {
  const {lang}= useContext(Language)
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;

  function getPollStatus(timestamp, duration) {
    // Convert timestamp to number and get the current time in seconds
    const pollStartTime = parseInt(timestamp, 10);
    if (isNaN(pollStartTime)) {
        return 'Invalid timestamp';
    }

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

    // Convert the duration to seconds
    const durationInSeconds = convertDurationToSeconds(duration);
    if (durationInSeconds === 0) {
        return duration;
    }

    // Calculate the end time of the poll
    const pollEndTime = pollStartTime + durationInSeconds;

    if (currentTime < pollEndTime) {
        const remainingTimeInSeconds = pollEndTime - currentTime;
        return formatRemainingTime(remainingTimeInSeconds);
    } else {
        return 'Poll closed';
    }
}

function convertDurationToSeconds(duration) {
    switch (duration) {
        case "1 Day":
            return 1 * 24 * 60 * 60;
        case "3 Days":
            return 3 * 24 * 60 * 60;
        case "7 Days":
            return 7 * 24 * 60 * 60;
        case "2 Weeks":
            return 14 * 24 * 60 * 60;
        default:
            return 0;
    }
}

function formatRemainingTime(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    if (days > 0) {
        return days === 1 ? `${days} day left` : `${days} days left`;
    }

    if (hours > 0) {
        return hours === 1 ? `${hours} hour left` : `${hours} hours left`;
    }

    if (minutes > 0) {
        return minutes === 1 ? `${minutes} minute left` : `${minutes} minutes left`;
    }

    return 'Poll closed';
}
const showVoteModal = (id)=>{
  if(user_id===value.user.id){
   
    toggleModal(id)
  }
  
}
const remainingTime = usePollRemainingTime(value.time, value.duration);
const pollStatus = getPollStatus(value.time, value.duration);
  return (
    <>
      {[value?.option_1, value?.option_2, value?.option_3, value?.option_4]
        .filter(data => data !== "" && data !== null) 
        .map((data, index) => (
          <div className="mb-2" key={index}>
            <div className="container mt-3">
              <div
                className="custom-box cursor"
                onClick={() => handlePollAnswer(value?.original_id, index)}
                style={{
                  pointerEvents: pollStatus==="Poll closed" ? 'none' : 'auto',  
                  opacity:  pollStatus==="Poll closed" ? 0.5 : 1,                 
                  cursor: pollStatus==="Poll closed" ? 'not-allowed' : 'pointer' 
                }}
                aria-disabled={ pollStatus==="Poll closed" ? 'true' : 'false'} 
              >
                {data}
              </div>
            </div>
          </div>
        ))}

      {/* Render vote count and remaining time */}
      <div className="d-flex align-items-center p-2">
        <p
          className="mb-0 cursor"
          onClick={() => showVoteModal(value.id)}
          style={{ marginRight: '5px', color: '#007aff' }}
        >
          {value.votes_count} {lang.votes}
        </p>
        {value.duration && <GoDotFill size={10} style={{ color: '#848798' }} />}
        <p className="mb-0 text-muted" style={{ marginLeft: '5px' }}>
          {remainingTime}
        </p>
      </div>
    </>
  );
};

export default PollOptionsView;
