import React, { useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { Language } from '../App';

const ReportModal = ({ isOpen, toggle, handleReport, isLoading,heading }) => {
    const {lang} = useContext(Language)
  const [reportInput, setReportInput] = useState('');

  return (
    <Modal isOpen={isOpen} centered zIndex={9999} toggle={toggle} backdrop="static">
      <ModalHeader>{heading}</ModalHeader>
      <ModalBody>
        <h6 className="fs-16">{lang?.modal_enter_report_para}</h6>

        <textarea
          rows="7"
          className="form-control"
          placeholder={lang.enter_your_report}
          onChange={(e) => setReportInput(e.target.value)}
        ></textarea>

        <div className="justify-content-end gap-3 d-flex mt-3">
          <button
            className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
            onClick={()=>toggle(false)} // Toggle modal on cancel
          >
            {lang?.cancel}
          </button>
          <button
            className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
            onClick={() => handleReport(reportInput)} // Pass the report input to handlePostReport
          >
            {isLoading ? <Spinner size="sm" /> : lang?.submit_report}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReportModal;
