import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GoDotFill } from "react-icons/go";
import { BsThreeDots } from "react-icons/bs";
import { LiaReplySolid } from "react-icons/lia";
import { IoChevronDownSharp } from "react-icons/io5";
import likeComment from "../../assets/images/icons/LikeComment.svg";
import FilledlikeComment from "../../assets/images/icons/LikeCommentFilled.svg";
import reportInfo from '../../assets/images/icons/reportInfo.svg';
import {
  Button,
  Card,
  CardBody,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  _imagesURL,
  adminBaseURL,
  DeleteCommentApi,
  GetRepliesApi,
} from "../../constants/API";
import { authorize } from "../../shared/authorize";
import CommentShimmer from "../feeds/comments/CommentShimmer";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import axios from "axios";
import { Language } from "../../App";
import RepostUserModal from "../feeds/RepostUserModal";
import ReportModal from "../../pages/ReportModal";
import { MdOutlineReport } from "react-icons/md";
import { toast } from "react-toastify";
import ImageComponent from "../common/ImageComponent";
import ImageShowModal from "../common/ImageShowModal";
import { Link, useNavigate } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";

const CommentList = ({
  postComments,
  setPostComments,
  setReplyingTo,
  setParentId,
  parentId,
  setReplyId,
  replyId,
  captions,
  loader,
  handleGetComments,
  commentLoading,
  handleGetReplies,
  commentReplies,
  setCommentReplies,
  setShowReplies,
  showReplies,
  type,
  editWebinarTab,
  setSelectedPost,
  onClose,
  selectedImage,
  setSelectedImage,
}) => {
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  const [isImage, setIsImage] = useState(false);
  const [isOpen, setIsOpen] = useState({});
  const [openReplies, setOpenReplies] = useState({});
  const [openComments, setOpenComments] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loadingId, setLoadingId] = useState(null);
  const [delLoader, setDelLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [likedComments, setLikedComments] = useState({});
  const [reportModal, setReportModal] = useState(false);
  const [reportId, setReportId] = useState("")
  const { lang } = useContext(Language);
    const navigate = useNavigate();

    // States for Image Preview in Modal
            const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
            const [previewImage, setPreviewImage] = useState("");
            const toggleImagePreview = () =>
              setIsImagePreviewOpen(!isImagePreviewOpen);
            const handleImageClick = (img) => {
              setPreviewImage(img); // Set the clicked image for preview
              toggleImagePreview();
            };

  const dropdownCommentRef = useRef(null);
  const replyRefs = useRef({});

  const toggleComment = (commentId) => {
    setOpenComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };
  
  const toggleReply = (replyId) => {
    setOpenReplies((prev) => ({
      ...prev,
      [replyId]: !prev[replyId],
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownCommentRef.current &&
        !dropdownCommentRef.current.contains(event.target)
      ) {
        setOpenComments((prev) => {
          const newState = { ...prev };
          Object.keys(newState).forEach((key) => {
            newState[key] = false;
          });
          return newState;
        });
      }
    };
  
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check all reply refs
      const newOpenReplies = { ...openReplies };
      let shouldUpdate = false;

      Object.keys(replyRefs.current).forEach((replyId) => {
        if (
          replyRefs.current[replyId] &&
          !replyRefs.current[replyId].contains(event.target) &&
          openReplies[replyId]
        ) {
          newOpenReplies[replyId] = false;
          shouldUpdate = true;
        }
      });

      if (shouldUpdate) {
        setOpenReplies(newOpenReplies);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openReplies]);

  // Format timestamp to relative time
  const formatRelativeTime = (timestamp) => {
    const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
    const now = Date.now() / 1000;
    const secondsAgo = Math.floor(now - timestamp);
    
    const intervals = [
      { label: lang?.second, value: 1, unit: 'second' },
      { label: lang?.minute, value: 60, unit: 'minute' },
      { label: lang?.hour, value: 3600, unit: 'hour' },
      { label: lang?.day, value: 86400, unit: 'day' },
      { label: lang?.month, value: 2592000, unit: 'month' },
      { label: lang?.year, value: 31536000, unit: 'year' },
    ];
    
    for (let i = intervals.length - 1; i >= 0; i--) {
      const interval = intervals[i];
      const diff = Math.floor(secondsAgo / interval.value);
      if (diff >= 1) {
        // Use the correct unit ('minute', 'second', etc.) for the Intl.RelativeTimeFormat
        return rtf.format(-diff, interval.unit);
      }
    }
    
    return rtf.format(-1, 'second');
  };
  

  const handleReplyClick = (name, commentId, id) => {
    setReplyingTo(name);
    setParentId(commentId);
    setReplyId(id);
  };

  const toggleDeleteModal = (id, commentId) => {
    setDeleteModal(!deleteModal);
    setLoadingId(id);
    setParentId(commentId)
  };

  const handleDeleteComment = useCallback(async () => {
    setDelLoader(true);

    try {
      const response = await axios.get(
        `${DeleteCommentApi}${loadingId}`,
        authorize()
      );
      if (response.data.status) {
        setLoadingId("");
        setDeleteModal(false);
        handleGetComments();
        handleGetReplies(parentId);
        setDelLoader(false);
      }
    } catch (error) {
      console.error(error);
      setDelLoader(false);
    }
  }, [loadingId, handleGetComments, parentId]);

  const handleCommentLike = async (commentId, currentLikes, isReply = false, parentCommentId = null) => {
    const previousLikedState = likedComments[commentId] || false;
    const isLiking = !previousLikedState;
    
    // Helper to update states (either optimistically or for reverting)
    const updateStates = (newLikedState) => {
      // Update like state
      setLikedComments(prev => ({...prev, [commentId]: newLikedState}));
      
      // Update count in the appropriate state
      if (isReply && parentCommentId) {
        setCommentReplies(prev => ({
          ...prev,
          [parentCommentId]: prev[parentCommentId].map(reply => 
            reply.id === commentId 
              ? {...reply, likes_count: newLikedState ? reply.likes_count + 1 : Math.max(0, reply.likes_count - 1)} 
              : reply
          )
        }));
      } else {
        setPostComments(prev => ({
          ...prev,
          data: prev.data.map(comment => 
            comment.id === commentId 
              ? {...comment, likes_count: newLikedState ? comment.likes_count + 1 : Math.max(0, comment.likes_count - 1)} 
              : comment
          )
        }));
      }
    };
    
    try {
      // Optimistically update UI
      updateStates(isLiking);
      
      // Make API call
      const url = `${adminBaseURL}user/post/comment/like/${commentId}`;
      const response = await axios.get(url, authorize());
      
      // Revert if needed
      const apiSucceeded = response.data.status && 
                          ((isLiking && response.data.action === "Comment liked") || 
                           (!isLiking && response.data.action === "Comment unliked"));
                           
      if (!apiSucceeded) {
        console.error("Failed to like comment/reply", response.data.message);
        updateStates(previousLikedState); // Revert to previous state
      }
    } catch (error) {
      console.error("Error liking comment/reply:", error);
      updateStates(previousLikedState); // Revert to previous state
      return null;
    }
  };

    const handlePostReport = useCallback(async (reportInput) => {
      if(!reportInput){
        toast.error("Please enter a report");
        return;
      }
      setIsLoading(true);
      try {
        const reportData = {
          feature_id: reportId,
          report: reportInput,
          type: "comment",
        };
        const response = await axios.post(`${adminBaseURL}app/report`, reportData, authorize());
        if (response.data.status) {
          toast.success("Reported Successfully!");
          setReportModal(false);
        } else {
          toast.error(response.data.action);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }, [ reportId, user_id]);

  return (
    <>
      <div
        className="my-2 scroll-hide overflowY-auto"
        style={{
          height: type === "poll"
            ? "calc(62vh - 210px)"
            : captions && captions.length < 50
            ? "calc(62vh + 28px)"
            : "calc(62vh - 35px)",
        }}
      >
        {commentLoading ? (
          [...Array(3)].map((_, index) => <CommentShimmer key={index} />)
        ) : postComments && postComments.data.length > 0 ? (
          postComments.data.map((comment) => (
            <div key={comment.id}>
              <div className="d-flex align-items-center p-2">
                <div
                  className="bg-white rounded-circle me-2 align-self-center cursor"
                  style={{
                    padding: "2px",
                    width: "46px",
                    height: "46px",
                    border: "1px solid rgba(29, 193, 221, 1)",
                  }}
                  onClick={() => {setSelectedPost(null); onClose(); editWebinarTab(false); navigate(PROFILE + "/" + comment?.user?.id);}}
                >
                  <ImageComponent
                    compClassName="rounded-circle"
                    img_width={40}
                    img_height={40}
                    loader_width={40}
                    loader_height={40}
                    src={
                      comment?.user?.image
                        ? _imagesURL + comment.user.image
                        : Avatar
                    }
                    roundShimmerSize={40}
                  />
                </div>
                <div className="flex-grow-1 position-relative">
                  <div className="d-flex justify-content-between mt-1 align-items-center">
                    <div className="d-flex justify-content-center align-items-center gap-2">
                        <span className="outfit-semibold hover-blue cursor" onClick={() => {setSelectedPost(null); onClose(); editWebinarTab(false); navigate(PROFILE + "/" + comment?.user?.id);}}>
                          {comment.user.name}
                        </span>
                      <span className="text-muted small">
                        <GoDotFill />{" "}
                        {formatRelativeTime(
                          Math.floor(
                            new Date(comment.created_at).getTime() / 1000
                          )
                        )}
                      </span>
                    </div>
                    <div
                      ref={dropdownCommentRef}
                      className="position-absolute"
                      style={{ top: "3px", right: "10px" }}
                    >
                      <div
                        className="text-muted cursor"
                        onClick={() => toggleComment(comment.id)}
                      >
                        <BsThreeDots />
                      </div>
                      <Collapse
                        isOpen={!!openComments[comment.id]}
                        className="position-absolute"
                        style={{ right: "0", top: "30px" }}
                      >
                        <Card
                          className="p-2 shadow-sm"
                          style={{ borderRadius: "18px" }}
                        >
                          <CardBody className="p-0">
                            {comment?.user?.id !== user_id ? (
                              <div
                                onClick={() => {
                                  setReportId(comment.id);
                                  setReportModal(true);
                                }}
                                className="d-flex manue-hover cursor justify-content-start align-items-center"
                              >
                                <span className="mx-2">
                                  <img src={reportInfo} alt="..." />
                                </span>
                                <h6
                                  className="mb-0 ps-0 p-2 rounded cursor fs-md-12"
                                  style={{ color: "#ff0000" }}
                                >
                                  {lang?.report}
                                </h6>
                              </div>
                            ) : (
                              <button
                                className="dropdown-item text-danger d-flex align-items-center gap-2 roboto-medium p-1"
                                onClick={() => toggleDeleteModal(comment.id)}
                              >
                                <RiDeleteBinLine className="text-danger" />
                                {lang?.delete_comment}
                              </button>
                            )}
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="px-2 pb-2"
                style={{ borderBottom: "0.5px solid #e8e9ec" }}
              >
                {comment.image && (
                  <ImageComponent
                    compClassName="rounded object-fit-cover cursor"
                    img_width={100}
                    img_height={100}
                    loader_width={100}
                    loader_height={100}
                    src={_imagesURL + comment.image}
                    onClick={() => handleImageClick(_imagesURL + comment.image)}
                    border="1px solid #e5e7e7"
                  />
                )}
               <p className="text-dark outfit-regular" style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>
                  {comment.comment?.split('\n').map((line, index) => (
                    <span key={index}>{line}<br /></span>
                  ))}
                </p>
                <div className="d-flex align-items-center gap-2 mt-3 text-muted">
                  <div
                    className="d-flex align-items-center cursor"
                    onClick={() =>
                      handleCommentLike(comment.id, comment.likes_count)
                    }
                  >
                    {likedComments[comment.id] ? (
                      <img src={FilledlikeComment} alt="" />
                    ) : (
                      <img src={likeComment} alt="" />
                    )}
                    <span className="ms-1 text-black outfit-regular">
                      {comment.likes_count} {lang?.likes}
                    </span>
                  </div>
                  <span className="text-muted small">
                    <GoDotFill />
                  </span>
                  <div className="d-flex align-items-center me-4 cursor" onClick={() => { handleReplyClick(comment.user.name, comment.id, comment.user.id); setSelectedImage(null); }}>
                    <LiaReplySolid size={20} />
                    <span
                      className="ms-1 outfit-regular"
                    >
                      {lang?.reply}
                    </span>
                  </div>
                  {comment.replies > 0 && (
                    <div
                      className="ms-auto d-flex align-items-center outfit-regular cursor"
                      onClick={() => handleGetReplies(comment.id)}
                    >
                      {showReplies[comment.id] ? lang?.hide : lang?.view}{" "}
                      <span className="mx-1">{comment.replies} {lang?.replies}</span>
                      <div
                        style={{
                          transform: showReplies[comment.id] ? "rotate(180deg)" : "rotate(0deg)",
                          transition: "transform 0.3s ease"
                        }}
                      >
                        <IoChevronDownSharp />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Render replies */}
              {loader[comment.id]
                ? [...Array(2)].map((_, index) => (
                    <CommentShimmer key={index} />
                  ))
                : showReplies[comment.id] &&
                  (commentReplies[comment.id]?.length > 0 &&
                    commentReplies[comment.id].map((reply, index) => (
                      <>
                      
                      <div
                        key={reply.id}
                        className="ps-5 mt-3 position-relative"
                      >
                        <div
                          className="position-absolute"
                          style={{
                            left: "20px",
                            top: "0",
                            bottom: "20px",
                            width: "1px",
                            height: "68px",
                            backgroundColor: "#e8e9ec",
                          }}
                        ></div>
                        <div
                          className="position-absolute"
                          style={{
                            left: "20px",
                            top: "67px",
                            width: "28px",
                            height: "1px",
                            backgroundColor: "#e8e9ec",
                          }}
                        ></div>
                        <div>
                          <div className="d-flex align-items-center cursor">
                            <div
                              className="bg-white rounded-circle me-2 align-self-center"
                              style={{
                                padding: "2px",
                                width: "46px",
                                height: "46px",
                                border: "1px solid rgba(29, 193, 221, 1)",
                              }}
                              onClick={() => {setSelectedPost(null); onClose(); editWebinarTab(false); navigate(PROFILE + "/" + reply?.user?.id);}}
                            >
                              <ImageComponent
                                compClassName="rounded-circle"
                                img_width={40}
                                img_height={40}
                                loader_width={40}
                                loader_height={40}
                                src={
                                  reply?.user?.image
                                    ? _imagesURL + reply.user.image
                                    : Avatar
                                }
                                roundShimmerSize={40}
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex justify-content-center align-items-center gap-2">
                                  <span className="outfit-semibold hover-blue cursor" onClick={() => {setSelectedPost(null); onClose(); editWebinarTab(false); navigate(PROFILE + "/" + reply?.user?.id);}}>
                                    {reply.user.name}
                                  </span>
                                <span className="text-muted small">
                                  <GoDotFill />{" "}
                                  {formatRelativeTime(
                                    Math.floor(
                                      new Date(reply.created_at).getTime() /
                                        1000
                                    )
                                  )}
                                </span>
                              </div>
                              <div
                                ref={(el) => (replyRefs.current[reply.id] = el)}
                                className="position-absolute"
                                style={{ top: "3px", right: "10px" }}
                              >
                                <div
                                  className="text-muted cursor"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent click from bubbling up
                                    toggleReply(reply.id);
                                  }}
                                >
                                  <BsThreeDots />
                                </div>
                                <Collapse
                                  isOpen={!!openReplies[reply.id]}
                                  className="position-absolute"
                                  style={{ right: "0", top: "30px" }}
                                >
                                  <Card
                                    className="p-2 shadow-sm"
                                    style={{ borderRadius: "18px" }}
                                  >
                                    <CardBody className="p-0">
                                      {reply?.user?.id !== user_id ? (
                                        <div
                                          onClick={() => {
                                            setReportId(reply.id);
                                            setReportModal(true);
                                          }}
                                          className="d-flex manue-hover cursor justify-content-start align-items-center"
                                        >
                                          <span className="mx-2">
                                            <img src={reportInfo} alt="..." />
                                          </span>
                                          <h6
                                            className="mb-0 p-1 p-2 rounded cursor fs-md-12"
                                            style={{ color: "#ff0000" }}
                                          >
                                            {lang?.report}
                                          </h6>
                                        </div>
                                      ) : (
                                        <button
                                          className="dropdown-item text-danger d-flex align-items-center gap-2 roboto-medium p-1"
                                          onClick={() =>
                                            toggleDeleteModal(reply.id, comment.id)
                                          }
                                        >
                                          <RiDeleteBinLine className="text-danger" />
                                          {lang?.delete_reply}
                                        </button>
                                      )}
                                    </CardBody>
                                  </Card>
                                </Collapse>
                              </div>
                            </div>
                          </div>

                          {/* <div className="flex-grow-1"> */}
                            <div className="mt-1">
                            <p className="text-dark mt-1 px-2 outfit-regular" style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>
                              <span 
                                className="text-info me-2 cursor" 
                                style={{whiteSpace: 'pre'}}  
                                onClick={() => { setSelectedPost(null); onClose(); editWebinarTab(false); navigate(PROFILE + "/" + reply.mentions[0].id);
                                }}
                              >
                                {lang?.reply_to} {reply?.mentions[0]?.name}
                              </span>
                              {reply.comment && (reply.comment.includes("\r\n") || reply.comment.includes("\n")) ? (
                                reply.comment.split(/(?:\r\n|\r|\n)/g).map((line, index) => (
                                  <span key={index}>
                                    {line}
                                    <br />
                                  </span>
                                ))
                              ) : (
                                reply.comment
                              )}
                            </p>
                            </div>
                            <div className="d-flex align-items-center gap-2 mt-2 mb-3 text-muted">
                              <div className="d-flex align-items-center cursor" onClick={() => handleCommentLike(reply.id, reply.likes_count, true, comment.id)}>
                                {/* <img src={likeComment} alt="" /> */}
                                {likedComments[reply.id] ? (
                                  <img src={FilledlikeComment} alt="" />
                                ) : (
                                  <img src={likeComment} alt="" />
                                )}
                                <span className="ms-1">
                                  {reply.likes_count || 0} {lang?.likes}
                                </span>
                              </div>
                              <span className="text-muted small">
                                <GoDotFill />
                              </span>
                              <div className="d-flex align-items-center cursor" onClick={() => { handleReplyClick(reply.user.name, comment.id, reply.user.id); setSelectedImage(null); }}>
                                <LiaReplySolid size={20} />
                                <span className="ms-1" >
                                  {lang?.reply}
                                </span>
                              </div>
                            </div>
                          {/* </div> */}
                        </div>
                      </div>
                      {index === commentReplies[comment.id].length - 1 && <hr style={{color: '#e8e9ec', opacity: '1'}} />}
                      </>
                    ))
                  )}
            </div>
          ))
        ) : (
          <div
            className="text-muted m-0 p-0 d-flex flex-column align-items-center justify-content-center"
            style={{ height: type === "poll" ? "100%" : "59vh" }}
          >
            <p className="m-0">{lang?.no_comments_yet}</p>
            <p className="m-0">{lang?.no_comments_yet_para}</p>
          </div>
        )}

        {deleteModal && (
          <Modal
            isOpen={deleteModal}
            toggle={toggleDeleteModal}
            centered
            size="md"
            backdrop="static"
          >
            <ModalBody className="px-3 pt-3">
              <h5 style={{ fontFamily: "NotoSans-bold" }}>
                {lang?.do_want_delete_comment}
              </h5>
              <p style={{ fontFamily: "NotoSans-medium" }}>
                {lang?.once_you_delete_comment_cannot_recover}
              </p>
            </ModalBody>
            <ModalFooter className="w-100 p-3">
              <Button
                className="cancel_btn rounded"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                {lang?.cancel}
              </Button>
              <Button
                className="delete_btn rounded"
                onClick={handleDeleteComment}
              >
                {delLoader ? <Spinner size="sm" /> : lang?.yes_delete}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>

      {reportModal && (
        <ReportModal
          isOpen={reportModal}
          toggle={setReportModal}
          handleReport={handlePostReport}
          isLoading={isLoading}
          heading={lang.report_post}
        />
      )}
      {/* Image preview Modal */}
      {isImagePreviewOpen &&
        <ImageShowModal
          isOpen={isImagePreviewOpen}
          toggle={toggleImagePreview}
          imageSrc={previewImage}
        />
      }
    </>
  );

};

export default CommentList;
