import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ImageRestriction, CropperRef } from "react-advanced-cropper";
import Modal from "./Modal.tsx";
import { Button } from "./Button.tsx";
import { useHashState } from "../service/useHashState.ts";
import { DefaultCropper } from "./croppers/DefaultCropper/DefaultCropper.tsx";
import { DefaultCropperIcon } from "./icons/DefaultCropperIcon.tsx";
import { SettingsIcon } from "./icons/SettingsIcon.tsx";
import { CroppersWizardSettings } from "./CroppersWizardSettings.tsx";
import "./CroppersWizard.scss";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";

export interface CropperSettings {
  aspectRatio?: number;
  minAspectRatio?: number;
  maxAspectRatio?: number;
  imageRestriction?: ImageRestriction;
  stencilType?: "rectangle" | "circle";
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  scaleImage?: boolean;
  grid?: boolean;
}

export interface CropperDescription {
  key: string;
  info: { name: string; description: string };
  link?: string;
  features: string[];
  settings: string[];
}

export default function CroppersWizard({
  images: files,
  setImages: setFiles,
  imagesArr: imagesArray,
  setImagesArr: setImagesArray,
  fileUploadRefs: uploadRefs,
  setFileUploadRefs: setUploadRefs,
  numberOfImages: totalImages,
  setNumberOfImages: setTotalImages,
  removeSource: setVideo,
  cropperOpen,
  setCropperOpen,
  max,
  allowCrop,
  minARatio,
  setMinAR,
  maxARatio,
  setMaxAR,
  ar,
  setAR,
  resizable,
  setResizable,
  height,
  setHeight,
  width,
  setWidth,
  top,
  setTop,
  left,
  setLeft,
  hasSettings,
  setHasSettings,
  updateCoordinatesCheck,
  setUpdateCoordinatesCheck,
  handleClose,
  ...props
}) {
  const location = useLocation();
  const defaultFreeSettings: CropperSettings = {
    aspectRatio: undefined,
    minAspectRatio: undefined,
    maxAspectRatio: undefined,
    grid: true,
    imageRestriction: ImageRestriction.fitArea,
  };

  const [isFirstImage, setIsFirstImage] = useState(true);
  const [settings, setSettings] = useState<CropperSettings>({
    aspectRatio: ar,
    minAspectRatio: minARatio,
    maxAspectRatio: maxARatio,
    grid: true,
    imageRestriction: ImageRestriction.fitArea,
  });

  const [settingsChanged, setSettingsChanged] = useState(false);

  const croppers = [
    {
      key: "default-cropper",
      info: { name: "Default Cropper", description: "The styled default cropped with custom navigation" },
      features: ["Custom Navigation", "Styling"],
      icon: <DefaultCropperIcon />,
      settings: ["aspectRatio", "imageRestriction", "stencil", "size", "scaleImage", "grid"],
    },
  ];

  const cropperRef = useRef<CropperRef>(null);
  const [cropper, setCropper] = useHashState(croppers[0].key, croppers.map((el) => el.key));
  const [src, setSrc] = useState();
  const [customImage, setCustomImage] = useState(false);

  const loadImage = (event) => {
    const { files } = event.target;
    if (files && files[0]) {
      if (src && customImage) {
        URL.revokeObjectURL(src);
      }
      const blob = URL.createObjectURL(files[0]);
      setSrc(blob);
      setCustomImage(true);
      setCropperOpen(true);
      setSettingsChanged(false);
    }
  };

  const [showSettings, setShowSettings] = useState(false);

  const onOpenSettings = () => {
    setShowSettings(true);
  };

  const onCloseSettings = (values) => {
    setSettings(values);
    setMinAR(values.minAspectRatio);
    setMaxAR(values.maxAspectRatio);
    setAR(values.aspectRatio);
    setSettingsChanged(true);

    if (cropperRef.current) {
      cropperRef.current.refresh();
      setTimeout(() => {
        if (cropperRef.current) {
          const coords = cropperRef.current.getCoordinates();
          if (coords) {
            updateCoordinates(coords);
            if (typeof crop === 'function') {
              crop(cropperRef.current);
            }
          }
        }
      }, 50);
    }
    setShowSettings(false);
  };

  const updateCoordinates = (values) => {
    if (values) {
      setHeight(values.height);
      setWidth(values.width);
      setTop(values.top);
      setLeft(values.left);
    }
  };

  const data = croppers.find((el) => el.key === cropper);

  const { minHeight, minWidth, maxWidth, maxHeight, aspectRatio, maxAspectRatio, minAspectRatio, grid } = settings;

  const stencilProps = {
    aspectRatio: isFirstImage 
      ? aspectRatio 
      : (ar || (width && height ? width / height : undefined) || aspectRatio),
    maxAspectRatio: isFirstImage ? maxAspectRatio : undefined,
    minAspectRatio: isFirstImage ? minAspectRatio : undefined,
    resizable: true,
    movable: true,
    grid,
  };

  const defaultSize = ({ imageSize, visibleArea }) => {
    const currentAspectRatio = isFirstImage
      ? (aspectRatio || imageSize.width / imageSize.height)
      : (ar || (width && height ? width / height : imageSize.width / imageSize.height));

    let newWidth = imageSize.width;
    let newHeight = imageSize.height;

    if (currentAspectRatio) {
      if (newWidth / newHeight > currentAspectRatio) {
        newWidth = newHeight * currentAspectRatio;
      } else {
        newHeight = newWidth / currentAspectRatio;
      }
    }

    return {
      width: newWidth,
      height: newHeight,
    };
  };

  const crop = useDebouncedCallback((cropper: CropperRef) => {
    if (!cropper) return;
    try {
      const imageUrisObject = {};
      const canvas = cropper.getCanvas({
        width: width,
        height: height,
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: '#fff',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      });

      if (canvas && canvas.toDataURL) {
        const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
        if (dataUrl && dataUrl !== 'data:,') {
          imageUrisObject[currentFileInputIndex.current] = dataUrl;
          setFiles((prevFiles) => ({ ...prevFiles, ...imageUrisObject }));
        }
      }
    } catch (error) {
      console.error("Error in crop function:", error);
    }
  }, 300);

  const exitCrop = (e) => {
    if (e) e.preventDefault();
    if (cropperRef.current) {
      const finalCanvas = cropperRef.current.getCanvas({
        width: width,
        height: height,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      });
      if (finalCanvas && finalCanvas.toDataURL) {
        const imageUrisObject = {};
        imageUrisObject[currentFileInputIndex.current] = finalCanvas.toDataURL('image/jpeg', 1.0);
        setFiles({ ...files, ...imageUrisObject });
      }
    }

    const currentAspectRatio = settingsChanged ? aspectRatio : (width / height);
    if (isFirstImage || settingsChanged) {
      setAR(currentAspectRatio);
      let newValues = {
        aspectRatio: currentAspectRatio,
        minAspectRatio: currentAspectRatio,
        maxAspectRatio: currentAspectRatio,
        grid: true,
        imageRestriction: ImageRestriction.fitArea,
      };
      setSettings(newValues);
      if (isFirstImage) {
        setIsFirstImage(false);
      }
    }

    setCurrentImage(null);
    currentFile.current = null;
    currentFileInputIndex.current = null;
    setHasSettings(false);

    let arr = Object.values(files);
    setImagesArray(arr);
    setFiles(arr);
    setUpdateCoordinatesCheck(false);
    setSettingsChanged(false);
    handleClose();
  };

  const [originalFiles, setOriginalFiles] = useState(files);
  const [currentImage, setCurrentImage] = useState(null);
  const currentFile = useRef(null);
  const currentFileInputIndex = useRef(null);

  const handleFileChange = async (e, index) => {
    try {
      e.preventDefault();
      const maxAllowedImages = max - Object.keys(files).length;
      const _selectedFiles = e.target.files;
      if (!_selectedFiles || _selectedFiles.length === 0) return;
      if (e.target.files.length > maxAllowedImages) {
        if (props.handleError) {
          props.handleError(`You cannot upload more than ${max} ${max > 1 ? "images" : "image"}`);
        } else {
          toast.error(`You cannot upload more than ${max} ${max > 1 ? "images" : "image"}`);
        }
        return;
      }
      const selectedFiles = Array.from(e.target.files);

      const imageURIs = await Promise.all(
        selectedFiles.map((f) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const image = document.createElement("img");
              image.onload = () => resolve(reader.result);
              image.src = reader.result;
            };
            reader.onerror = (e) => reject(e);
            reader.readAsDataURL(f);
          });
        })
      );

      const imageUrisObject = {};
      for (let i = index; i < index + imageURIs.length; i++) {
        imageUrisObject[i] = imageURIs[i - index];
        currentFileInputIndex.current = i;
      }
      if (allowCrop) {
        setCurrentImage(imageUrisObject[index + imageURIs.length - 1]);
        setOriginalFiles({ ...originalFiles, ...imageUrisObject });
        loadImage(e);
      }
      let arr = Object.values(files);
      arr.push(imageURIs[0]);
      setFiles(arr);
      setImagesArray(arr);
    } catch (e) {
      if (props.handleError) props.handleError(e);
      else toast.error(e);
    }
  };

  useEffect(() => {
    const fileUploadRefsCopy = {};
    Array(totalImages)
      .fill()
      .forEach((_, index) => {
        fileUploadRefsCopy[index] = React.createRef();
      });
    setUploadRefs(fileUploadRefsCopy);
  }, [totalImages]);

  useEffect(() => {
    let imageCount = Object.keys(files).length;
    if (imageCount < max) {
      setTotalImages(imageCount + 1);
    } else {
      setTotalImages(imageCount);
    }
    if (imageCount === 0) {
      setIsFirstImage(true);
      setSettings(defaultFreeSettings);
      setHasSettings(true);
    }
  }, [files, max]);

  useEffect(() => {
    if (cropperOpen && cropperRef.current) {
      setTimeout(() => {
        if (cropperRef.current) {
          cropperRef.current.refresh();
        }
      }, 100);
    }
  }, [cropperOpen]);

  return (
    <>
      <input
        type="file"
        onChange={(e) => handleFileChange(e, totalImages - 1)}
        ref={uploadRefs[totalImages - 1]}
        style={{ display: "none" }}
        accept="image/*"
      />
      {allowCrop && currentImage ? (
        <Modal isOpen={true}>
          <Modal.Header>Crop Image</Modal.Header>
          <Modal.Body>
            <div className={"croppers-wizard"}>
              <div className="croppers-wizard__body">
                <DefaultCropper
                  key={"default-cropper"}
                  wrapperClassName={"croppers-wizard__cropper"}
                  src={src}
                  onReady={(cropper) => {
                    if (cropper) {
                      const coords = cropper.getCoordinates();
                      if (coords) updateCoordinates(coords);
                      crop(cropper);
                    }
                  }}
                  onInteractionEnd={crop}
                  stencilProps={stencilProps}
                  defaultSize={defaultSize}
                  updateCoordinates={updateCoordinates}
                  updateCoordinatesCheck={true}
                  ref={cropperRef}
                />
                {hasSettings && (
                  <button className="croppers-wizard__settings-button" onClick={onOpenSettings}>
                    <SettingsIcon />
                  </button>
                )}
                {data && (
                  <CroppersWizardSettings
                    open={showSettings && hasSettings}
                    settings={settings}
                    onClose={onCloseSettings}
                    properties={data.settings}
                    className={"croppers-wizard__settings"}
                    visibleClassName={"croppers-wizard__settings--visible"}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={exitCrop} size="normal">
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}