import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GoClock } from "react-icons/go";
import { GoCalendar } from "react-icons/go";
import Search from "../../../assets/images/icons/search.svg";
import close from "../../../assets/images/icon/close-icon.svg";
import Cropper from "../../../../src/cropper/components/CroppersWizard.tsx"
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileTimePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { TextField, InputAdornment } from '@mui/material';
import { CiCamera } from 'react-icons/ci';
import { Language } from '../../../App';
import InfiniteScroll from "react-infinite-scroller";
import Avatar from "../../../assets/images/icon/placeholder_Awatar.png"
import PollImageModal from './PollImageModal';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import Feed from '../../../pages/Feed';
import { PROFILE } from '../../../constants/RoutesUrl';
import { _imagesURL, adminBaseURL } from '../../../constants/API';
import { authorize } from '../../../shared/authorize';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import ProgressPopup from './ProgressPopup';
import { debounce } from 'lodash';
import { getToken } from '../../../shared/token.js';
import ImageShowModal from '../../common/ImageShowModal.jsx';

const WebinarTab = ({ onHide, setProgress, handleApiCall, editWebinar, selectedPost, refetch, setEditWebinar }) => {
  const { lang } = useContext(Language);
  const SplashData = JSON.parse(localStorage.getItem("SplashData"));
  const subcategories = SplashData?.home?.subcategories;
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

   // States for Image Preview in Modal
        const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
        const [previewImage, setPreviewImage] = useState("");
        const toggleImagePreview = () => setIsImagePreviewOpen(!isImagePreviewOpen);
        const handleImageClick = (img) => {
          setPreviewImage(img); // Set the clicked image for preview
          toggleImagePreview();
        };

  // Form field state values
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [scheduleTime, setScheduleTime] = useState("")
  const [timeLimit, setTimeLimit] = useState('');
  const [visibility, setVisibility] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [invitePage, setInvitePage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [inviteSpin, setInviteSpin] = useState(false);
  const [webinarId, setWebinarId] = useState("");
  const [scheduleDate, setScheduleDate] = useState("")
  const [keyword, setKeyword] = useState("")
  const [inviteData, setInviteData] = useState({});
  // const [progress, setProgress] = useState(0);
  // const [showProgress, setShowProgress] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [searchData, setSearchData] = useState({})
  const [searchList, setSearchList] = useState([])
  // Temporary states to hold values while picker is open
  const [tempDate, setTempDate] = useState(null);
  const [tempTime, setTempTime] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [coverImg, setCoverImg] = useState([]);  // For storing the selected image
  const [cropperOpen, setCropperOpen] = useState(false); // Whether the cropper modal is open
  const maxNumber = 2;
  // Additional cropper-related states (as per your Cropper component's props)
  const [ar, setAR] = useState(1);  // Aspect ratio
  const [maxARatio, setMaxAR] = React.useState(undefined);
  const [minARatio, setMinAR] = React.useState(undefined);
  const [resizable, setResizable] = useState(true); // Whether cropping is resizable
  const [hasSettings, setHasSettings] = useState(false); // Whether cropper settings are enabled
  const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = useState(false); // For coordinate updates
  const [fileUploadRefs, setFileUploadRefs] = React.useState({});
  const [numberOfImages, setNumberOfImages] = React.useState(
    coverImg.length < maxNumber ? coverImg.length : maxNumber
  );

  const [height, setHeight] = React.useState(undefined);
  const [width, setWidth] = React.useState(undefined);
  const [top, setTop] = React.useState(undefined);
  const [left, setLeft] = React.useState(undefined);

  useEffect(() => {
    if (editWebinar && selectedPost) {
      setTitle(selectedPost.title || '');
      setCategory(selectedPost.subcategory_id?.toString() || '');
      setDescription(selectedPost.caption || '');
      setTimeLimit(selectedPost.duration || '');
      setVisibility(selectedPost.visibility || 'Public');

      // Handle date and time
      if (selectedPost.start_timestamp) {
        const date = new Date(selectedPost.start_timestamp * 1000);
        setStartDate(moment(date)); // Set startDate as a Date object
        setScheduleDate(moment(date).format("YYYY-MM-DD"))
        // setStartTime(moment(date.toTimeString().slice(0, 5)));
        const momentDate = moment(date);

        // Set startTime to a Moment object with just the time from the timestamp
        setStartTime(momentDate.clone().set({ hour: date.getHours(), minute: date.getMinutes(), second: 0 }));
        setScheduleTime(selectedPost.schedule_time)

      }
      // Handle images
      if (selectedPost.media && selectedPost.media.length > 0) {
        setCoverImg(selectedPost.media);
      }
    }
  }, [editWebinar, selectedPost]);


  const handleClose = () => {
    setCropperOpen(false); // Close the cropper modal
  };
  function isValidFutureDate(inputDate) {
    // Parse the input date
    const userDate = new Date(inputDate);

    // Check if the parsed date is a valid date
    if (isNaN(userDate.getTime())) {
      toast.error(lang?.error_note_number);
      return false;
    }

    // Get today's date
    const today = new Date();

    // Set the time to midnight for both dates to compare only the dates
    userDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // Compare the dates
    if (userDate < today) {
      toast.error(lang?.date_should_greater_todays_date);
      return false;
    }
    return true;
  }
  const getImageUrl = (url) => {
    setSelectedImage(url);
  };
  // Set current date and time + 5 minutes when picker is opened
  const handleDatePickerOpen = () => {
    // Set current date as initial value when picker opens
    setTempDate(moment());
  };

  const handleDatePickerClose = (isAccepted) => {
    if (!isAccepted) {
      // If dismissed without accepting, reset to original value
      setStartDate(null);
    }
  };

  const handleDateChange = (newDate) => {
    setTempDate(newDate);
    setStartDate(newDate)
    setScheduleDate(moment(newDate).format("YYYY-MM-DD"))
  };

  const handleDateAccept = () => {
    // If date was changed, update the main state
    if (tempDate && !tempDate.isSame(moment(), 'day')) {
      setStartDate(tempDate);
    } else {
      // If no change was made but OK was pressed, use current date
      setStartDate(moment());
    }
  };

  // Time Picker Handlers
  const handleTimePickerOpen = () => {
    // Set current time + 5 minutes as initial value when picker opens
    if (editWebinar) return
    setTempTime(moment().add(5, 'minutes'));
    setStartTime(moment().add(5, 'minutes'))
    setScheduleTime(moment().add(5, 'minutes').format("hh:mm A"))
  };

  const handleTimePickerClose = (isAccepted) => {
    if (!isAccepted) {
      // If dismissed without accepting, reset to original value
      setStartTime(null);
    }
  };

  const handleTimeChange = (newTime) => {
    setTempTime(newTime);
    setStartTime(newTime)
    setScheduleTime(moment(newTime).format("hh:mm A"))
  };

  const handleTimeAccept = () => {
    // If time was changed, update the main state
    if (tempTime && !tempTime.isSame(moment().add(5, 'minutes'), 'minute')) {
      setStartTime(tempTime);
    } else {
      // If no change was made but OK was pressed, use current time + 5 minutes
      setStartTime(moment().add(5, 'minutes'));
    }
  };
  const handleInvite = (userId) => {
    setIsLoading(true);
    axios
      .get(adminBaseURL + "user/post/webinar/invite/" + webinarId + "/" + userId, authorize())
      .then((res) => {
        if (res.data.status) {
          const update = inviteList.map((value) => {
            if (value.id === userId) {
              return { ...value, invite: !value.invite };
            }
            return value;
          });
          setInviteList(update);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSubmit = async () => {
    // e.preventDefault();

    // Converting to unixTimeStamp
    const combinedDateTimeString = `${scheduleDate} ${scheduleTime}`;  // Remove 'T' between date and time

    // Parse the combined string using moment
    const combinedDateTime = moment(combinedDateTimeString, "YYYY-MM-DD hh:mm A");

    // Get the timestamp in milliseconds
    const start_timestamp = combinedDateTime.valueOf();  // milliseconds
    // const _start_timestamp = Math.floor(start_timestamp / 1000);
    const _start_timestamp = Math.floor(start_timestamp / 1000);

    if (timeLimit > 120) {
      toast.error(lang?.webinar_duration_greater_120_mins);
      return 0;
    }

    if (timeLimit < 0) {
      toast.error(lang?.enter_correct_time_limit);
      return 0;
    }
    const currentTime = moment().format("hh:mm A"); // Current date and time
    const selectedDateTime = moment(`${scheduleDate}T${scheduleTime}`, 'YYYY-MM-DDTHH:mm'); // Combine date and time
    // Check if the selected date and time is at least 5 minutes ahead of the current time
    if (selectedDateTime.diff(currentTime, 'minutes') < 5) {
      toast.error("Select at least 5 minutes after the current time.");
      return;
    }
    if (combinedDateTime < new Date()) {
      toast.error(lang?.start_time_less_current_time);
      return 0;
    }

    isValidFutureDate(scheduleDate);
    // setShowProgress(true)
    if(timeLimit<15){
      toast.error(lang?.webinar_duration_greater_15_mins||"Webinar duration minimun 15 minutes");
      return 0;
    }
    const data = {
      start_timestamp: _start_timestamp,
      type: "webinar",
      caption: description,
      schedule_date: scheduleDate,
      schedule_time: scheduleTime,
      category_id: "3",
      subcategory_id: category,
      title: title,
      visibility: visibility,
      duration: timeLimit,
      mediaBase64: coverImg,
    };
    setIsLoading(true);
    onHide(); // Close the popup
    try {
      const response = await axios.post(adminBaseURL + "user/post/create", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });
      // Handle the response based on status
      if (response?.data?.status) {
        setIsLoading(false); // Stop loading
        setInviteModal(true);
        setInviteSpin(true);
        setWebinarId(response.data.post.id); // Set the webinar ID
        handleInvitationList(response.data.post.id); // Handle invitation list

        // Reset the form fields
        setTitle("");
        setCategory("");
        setStartDate(null);
        setStartTime(null);
        setTimeLimit("");
        setVisibility("public");
        setDescription("");
        setSelectedImage(null);
        setCoverImg([]);

        return response; // Explicitly return the response if everything is successful
      }
    } catch (error) {
      setIsLoading(false); // Stop loading
      toast.error("Upload failed, please try again."); // Show error toast
      console.error(error); // Log error for debugging
      return error; // Return the error for further handling
    }

  };
  const handleUpdate = async () => {
    // e.preventDefault();

    // Converting to unixTimeStamp
    const combinedDateTimeString = `${scheduleDate} ${scheduleTime}`; // Remove 'T' between date and time
    // Parse the combined string using moment
    const combinedDateTime = moment(combinedDateTimeString, "YYYY-MM-DD hh:mm A");

    // Get the timestamp in milliseconds
    const start_timestamp = combinedDateTime.valueOf(); // milliseconds
    const _start_timestamp = Math.floor(start_timestamp / 1000); // Convert to seconds for the backend

    // Validate time limit
    if (timeLimit > 120) {
      toast.error(lang?.webinar_duration_greater_120_mins);
      return 0;
    }

    if (timeLimit < 0) {
      toast.error(lang?.enter_correct_time_limit);
      return 0;
    }

    const currentTime = moment().format("hh:mm A"); // Current date and time
    const selectedDateTime = moment(`${scheduleDate}T${scheduleTime}`, 'YYYY-MM-DDTHH:mm'); // Combine date and time
    // Check if the selected date and time is at least 5 minutes ahead of the current time
    if (selectedDateTime.diff(currentTime, 'minutes') < 5) {
      toast.error("Select at least 5 minutes after the current time.");
      return;
    }
    // Validate that the selected start time is not in the past
    if (combinedDateTime < new Date()) {
      toast.error(lang?.start_time_less_current_time);
      return 0;
    }

    isValidFutureDate(scheduleDate);

    const data = {
      start_timestamp: _start_timestamp,
      type: "webinar",
      caption: description,
      schedule_date: scheduleDate,
      schedule_time: scheduleTime,
      category_id: "3",
      subcategory_id: category,
      title: title,
      visibility: visibility,
      duration: timeLimit,
      webinar_id: selectedPost.original_id
    };
    if (coverImg[0]?.startsWith('data:image/')) {
      data.mediaBase64 = [coverImg[0]];  // Add the mediaBase64 field only if it's a Base64 image
    }

    setIsLoading(true);
    onHide(); // Close the popup

    try {
      const response = await axios.post(adminBaseURL + "user/post/webinar/update", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });

      // Handle the response based on status
      if (response?.data?.status) {

        setIsLoading(false); // Stop loading
        setInviteModal(true);
        setInviteSpin(true);
        setWebinarId(response.data.post.id); // Set the webinar ID
        // handleInvitationList(response.data.post.id); // Handle invitation list
        refetch()
        // Reset the form fields
        setEditWebinar(false)
        setTitle("");
        setCategory("");
        setStartDate(null);
        setStartTime(null);
        setTimeLimit("");
        setVisibility("public");
        setDescription("");
        setSelectedImage(null);
        setCoverImg([]);

        return response; // Explicitly return the response if everything is successful
      }
    } catch (error) {
      setIsLoading(false); // Stop loading
      toast.error("Update failed, please try again."); // Show error toast
      console.error(error); // Log error for debugging
      return error; // Return the error for further handling
    }
  };

  const handleTimeOpen = (type) => {

    if (type === "start_time") {
      toast.error('Please select  start date first.');
      return false; // Prevents picker from opening
    } else if (type === "end_date") {
      toast.error("Select Start Date First")
    } else if (type === "end_time") {
      toast.error("Select End Date First")
    }
    return true; // Allows picker to open
  };
  const handleInvitationList = async (webinar_id) => {
    await axios
      .get(
        adminBaseURL +
        "user/post/webinar/users/" +
        webinar_id +
        "?page=" +
        JSON.stringify(invitePage), authorize()
      )
      .then((res) => {
        if (inviteList.length) {
          setInviteList(inviteList.concat(res.data.data.data));
          setInviteData(res.data.data);
          setInvitePage(res.data.data.current_page + 1);
          setInviteSpin(false)
          return 0;
        }
        if (res.data.status) {
          setInviteList(res.data.data.data);
          setInviteData(res.data.data);
          setInvitePage(res.data.data.current_page + 1);
          setInviteSpin(false);
        } else {
          toast.error(res.data.error);
          setInviteSpin(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const searchWebinars = async (keyword) => {
    const url = `${adminBaseURL}user/post/webinar/user/search?page=${searchPage}`;  // Endpoint for search

    try {
      // Construct the parameters
      const params = {
        keyword: keyword,     // Search keyword
        post_id: 3744    // Webinar ID
      };

      // Make the GET request to the API with the query parameters
      const response = await axios.post(url, params, authorize());

      // Handle the response (example)
      if (searchList.length) {
        setSearchList(searchList.concat(response.data.data.data));
        setSearchData(response.data.data);
        setSearchPage(response.data.data.current_page + 1);
        return 0;
      }
      if (response.data.status) {
        setSearchList(response.data.data.data);
        setSearchData(response.data.data);
        setSearchPage(response.data.data.current_page + 1);
        setInviteSpin(false);
      } else {
        toast.error(response.data.error);
        setInviteSpin(false);
      }

    } catch (error) {
      console.error("Error occurred during the search:", error);
      return null;
    }
  };
  const _futureTime = startDate
    ? moment(startDate).isSame(moment(), 'day') // If the startDate is today
      ? moment().add(5, 'minutes') // For today, add 5 minutes to the current time
      : moment(startDate).startOf('day').add(5, 'minutes') // For tomorrow, add 5 minutes to midnight
    : moment().add(5, 'minutes'); // Default if startDate is null


  const handleLoadMore = async () => {
    if (isLoadingMore) return;

    setIsLoadingMore(true);
    try {
      if (isSearchMode && keyword.length > 0) {
        await searchWebinars(keyword);
      } else if (!isSearchMode) {
        await handleInvitationList(webinarId);
      }
    } finally {
      setIsLoadingMore(false);
    }
  };

  const hasMore = () => {
    if (isSearchMode && keyword.length > 0) {
      return searchData?.current_page < searchData?.last_page;
    }
    return !isSearchMode && inviteData?.current_page < inviteData?.last_page;
  };

  const resetInviteList = useCallback(() => {
    setInvitePage(1);
    setInviteList([]);
    setInviteData({});
    handleInvitationList(webinarId);
  }, [webinarId]);

  const resetSearchList = useCallback(() => {
    setSearchPage(1);
    setSearchList([]);
    setSearchData({});
  }, []);

  // Handle search mode changes
  useEffect(() => {
    if (keyword.length > 0) {
      setIsSearchMode(true);
      resetSearchList();
      searchWebinars(keyword);
    } else if (isSearchMode) {
      setIsSearchMode(false);
      resetInviteList();
    }
  }, [keyword, isSearchMode, resetSearchList, resetInviteList]);

  const debouncedSearchHandler = debounce((searchValue) => {
    setKeyword(searchValue);
  }, 500);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    if (!searchValue.trim()) {
      setKeyword('');
      setIsSearchMode(false);
      return;
    }
    debouncedSearchHandler(searchValue);
  };

  useEffect(() => {
    // Cleanup debounce on component unmount
    return () => debouncedSearchHandler.cancel();
  }, [debouncedSearchHandler]);
  const _handleSubmit = async (e) => {
    e.preventDefault();

    if (editWebinar) {
      await handleUpdate()
      return
    }

    await handleApiCall(() => handleSubmit(), { type: "webinar", webinarId }); // Call API and show progress popup
  };
  const onImageRemove = (index) => {
    // Create copies of existing arrays/objects
    const updatedImages = [...coverImg];
    const updatedFileUploadRefs = { ...fileUploadRefs };

    // Remove the image from arrays
    updatedImages.splice(index, 1);
    
    // Reset the file input value for the removed index
    if (fileUploadRefs[index+1] && fileUploadRefs[index+1].current) {
      fileUploadRefs[index+1].current.value = ''; // Clear the file input
    }
    
    // Update all states related to images
    setCoverImg(updatedImages);
    // setImagesArr(updatedImages);
    
    // Reorganize the fileUploadRefs
    const newFileUploadRefs = {};
    Object.keys(updatedFileUploadRefs)
      .filter(key => key !== index.toString())
      .forEach((key, newIndex) => {
        newFileUploadRefs[newIndex] = updatedFileUploadRefs[key];
      });
    
    setFileUploadRefs(newFileUploadRefs);
    
    // Update the number of images
    // setNumberOfImages(updatedImages.length + 1);

    // Reset settings if no images left
    if (updatedImages.length === 0) {
      setHasSettings(true);
      setAR(undefined);
      setMinAR(undefined);
      setMaxAR(undefined);
      setWidth(500);
      setHeight(500);
      setTop(0);
      setLeft(0);
      
      // Reset the cropper state
      setCropperOpen(false);
    }
  };
  const handleImageUploadClick = () => {
    const currentIndex = numberOfImages-1;
    if (fileUploadRefs[currentIndex] && fileUploadRefs[currentIndex].current) {
      // Reset the file input value before triggering the click
      fileUploadRefs[currentIndex].current.value = '';
      fileUploadRefs[currentIndex].current.click();
    }
  };
  return (
    <>
      {/* <div className="container-fluid py-4"> */}
      <div className="container">
        <h4 className="mb-4 text-muted">{editWebinar ? lang.edit : lang.create} {lang.webinar}</h4>

        <form onSubmit={_handleSubmit}>
          {/* Title */}
          <div className="mb-3">
            <label className="form-label">{lang.title}</label>
            <input
              type="text"
              className="form-control bg-light"
              placeholder={lang.webinar_title}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          {/* Category */}
          <div className="mb-3">
            <label className="form-label">{lang.select_category}</label>
            <select
              className="form-select bg-light"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">{lang?.webinar_category}</option>
              {subcategories.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>

          {/* Date and Time Row */}
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="form-label">{lang.start_date}</label>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  label={startDate ? "" : "MM/DD/YYYY"}
                  value={startDate}
                  defaultValue={tempDate}
                  minDate={moment().startOf('day')}
                  onChange={handleDateChange}

                  slotProps={{
                    textField: {
                      className: "custom-input bg-light",
                      InputLabelProps: { shrink: false },
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <GoCalendar style={{ cursor: 'pointer' }} />
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth style={{ width: '100%', height: '40px' }} />
                  )}
                />
              </LocalizationProvider>
            </div>

            <div className="col-md-6">
              <label className="form-label">{lang.start_time}</label>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                {startDate ? (
                  <MobileTimePicker
                    value={startTime}
                    label={startTime ? "" : "hh:mm:aa"}
                    minTime={_futureTime}
                    onChange={handleTimeChange}
                    onOpen={handleTimePickerOpen}
                    slotProps={{
                      textField: {
                        className: "custom-input bg-light",
                        InputLabelProps: { shrink: false },
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <GoClock style={{ cursor: 'pointer' }} />
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />) : (
                  <TextField
                    label={startTime ? "" : "hh:mm:aa"}
                    className="custom-input bg-light"
                    InputLabelProps={{ shrink: false }}
                    onClick={() => handleTimeOpen("start_time")} // Trigger toast when clicked

                    InputProps={{
                      readOnly: true, // Prevent manual typing
                      endAdornment: (
                        <InputAdornment position="end">
                          <GoClock
                            style={{ cursor: 'pointer' }}
                          // onClick={handleTimeOpen} // Trigger toast when clicked
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </LocalizationProvider>
            </div>
          </div>

          {/* Duration and Visibility Row */}
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="form-label">{lang.time_limit}</label>
              <input
                type="number"
                className="form-control bg-light"
                placeholder="Min"
                value={timeLimit}
                onChange={(e) => setTimeLimit(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">{lang?.webinar_visibility}</label>
              <select
                className="form-select bg-light"
                value={visibility}
                onChange={(e) => setVisibility(e.target.value)}
              >
                <option value="" className='text-muted' style={{color:"#6c757d"}}>{lang?.chose_webinar_comitment}</option>
                <option value="Public">{lang?.public}</option>
                <option value="Private">{lang?.private}</option>
              </select>
            </div>
          </div>

          {/* Description */}
          <div className="mb-3">
            <label className="form-label">{lang.description}</label>
            <textarea
              className="form-control bg-light"
              rows={4}
              placeholder={lang.webinar_description}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {coverImg.length > 0 && (
            <div className="custom-card mt-4 position-relative">
              {coverImg.map((img, index) => (
                <div key={index} className="upload-poll-image-container">
                  <PhotoProvider
                    speed={() => 800}
                    easing={(type) =>
                      type === 2
                        ? 'cubic-bezier(0.36, 0, 0.66, -0.56)'
                        : 'cubic-bezier(0.34, 1.56, 0.64, 1)'
                    }
                  >
                    <PhotoView src={editWebinar ? (img.startsWith('data:image/') ? img : _imagesURL + img) : img}>
                      <img
                        className="custom-image cursor"
                        src={editWebinar ? (img.startsWith('data:image/') ? img : _imagesURL + img) : img}
                        alt="Uploaded"
                        onClick={() => handleImageClick(img)}
                      />
                    </PhotoView>

                  </PhotoProvider>
                  <img

                    // className="custom-close-btn"
                    className=" c-pointer"
                    aria-label="Close"
                    src={close}
                    style={{
                      position: "absolute",
                      top: "-9px",
                      right: "-6px",
                      width: "26px",
                      height: "26px"
                    }}
                    alt="Remove"
                    onClick={() => onImageRemove(index)} // Remove specific image on click


                  />
                </div>
              ))}
            </div>
          )}
          <hr className="text-muted" style={{ marginLeft: "-37px", marginRight: "-37px" }} />

          {/* Image Upload */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              type="button"
              
              onClick={() =>
                handleImageUploadClick()
              }
              disabled={coverImg.length}
              className="btn btn-light d-flex align-items-center rounded-pill border-0"
            >
              <CiCamera size={20} className="me-2" /> {lang.image}
            </button>

            <div>
              <button
                type="button"
                style={{ color: '#848798', border: "1px solid #848798" }}
                className="btn px-3 me-2"
                onClick={()=>{onHide()
                  setEditWebinar(false)
                }}
              >
                {lang?.cancel}
              </button>
              <button type="submit" className="btn px-4" disabled={!coverImg.length || !title || !category || !startDate || !startTime || !timeLimit || !description || !visibility} style={{ backgroundColor: '#1dc1dd', color: '#fff' }}>
                {editWebinar ? lang.update : lang?.publish}
              </button>
            </div>
          </div>

        </form>
      </div>
      <PollImageModal type="webinar" getImageUrl={getImageUrl} showModal={showImageModal} setShowModal={setShowImageModal} />
      <>

        <Cropper
          images={coverImg}
          setImages={setCoverImg}
          setImagesArr={setCoverImg}
          value={coverImg}
          max={maxNumber}
          allowCrop={true}
          ar={ar}
          setAR={setAR}
          minARatio={minARatio}
          setMaxAR={setMaxAR}
          maxARatio={maxARatio}
          setMinAR={setMinAR}
          resizable={resizable}
          setResizable={setResizable}
          handleClose={handleClose}
          fileUploadRefs={fileUploadRefs}
          setFileUploadRefs={setFileUploadRefs}
          numberOfImages={numberOfImages}
          setNumberOfImages={setNumberOfImages}
          hasSettings={hasSettings}
          setHasSettings={setHasSettings}
          updateCoordinatesCheck={updateCoordinatesCheck}
          setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
          height={height}
          setHeight={setHeight}
          width={width}
          setWidth={setWidth}
          top={top}
          setTop={setTop}
          left={left}
          setLeft={setLeft}
          cropperOpen={cropperOpen}
          setCropperOpen={setCropperOpen}
        />

        <ImageShowModal
          isOpen={isImagePreviewOpen}
          toggle={toggleImagePreview}
          imageSrc={previewImage}
          />
      </>
    </>
  );
};

export default WebinarTab;
