import React, { useContext, useEffect, useState } from "react";
import contest_feb from "../../assets/images/feb_contest.png";
import calender from "../../assets/images/feb_contest_cal.png";
import { IoIosAlert } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { _imagesURL, adminBaseURL } from "../../constants/API";
import { authorize } from "../../shared/authorize";
import axios from "axios";
import { toast } from "react-toastify";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Spinner } from "reactstrap";
import { Language } from "../../App";

export const MedicalQuizContest = ({showMedicalQuiz=true,toggle=()=>false,contestMedia}) => {
    const {lang} = useContext(Language)
    const quizQuestions = [
        {
            id: 1,
            question: "Indica cuál de las siguientes NO es una buena indicación de cirugía de pterigion",
            options: [
                "Molestias intensas que no mejoran con tratamiento médico correcto",
                "Alteraciones visuales por astigmatismo asociado",
                "Cabeza del pterigion alcanza el eje pupilar",
                "Interés cosmético del paciente"
            ],
            correctAnswer: "Interés cosmético del paciente"
        },
        {
            id: 2,
            question: "Señala la falsa respecto al uso de microscopía confocal",
            options: [
                "Se trata de una prueba que requiere la instilación de colirio anestésico",
                "Es útil para el estudio del plexo nervioso subbasal",
                "Está muy extendida, disponiendo de ella numerosos centros en España",
                "Se emplea para el diagnóstico del COVID persistente"
            ],
            correctAnswer: "Está muy extendida, disponiendo de ella numerosos centros en España"
        },
        {
            id: 3,
            question: "Indica la correcta respecto a la cirugía de pterigion",
            options: [
                "No hay diferencias en cuanto a recidivas entre el uso de membrana amniótica y de autoinjerto conjuntival",
                "La técnica PERFECT apenas difiere de la estándar, pero tiene tasas de recidiva mucho menores",
                "Es importante enviar la muestra a Anatomía Patológica, por la posible existencia de lesiones escamosas tumorales",
                "No hay evidencia clara sobre la superioridad del autoinjerto conjuntival fijado con Tisseel"
            ],
            correctAnswer: "Es importante enviar la muestra a Anatomía Patológica, por la posible existencia de lesiones escamosas tumorales"
        },
        {
            id: 4,
            question: "Indica la verdadera en relación al microscopio confocal y su uso clínico",
            options: [
                "Su uso en distrofias se limita al ilustrativo, sin interés clínico",
                "Permite diferenciar entre los hongos filamentos en función de la ramificación",
                "Su uso en el diagnóstico de ameba está limitado por la experiencia y destreza del observador",
                "Es muy útil para el diagnóstico clínico de queratitis fúngica por Candida"
            ],
            correctAnswer: "Su uso en el diagnóstico de ameba está limitado por la experiencia y destreza del observador"
        }
    ];

    const initialContestData = {
        questions: quizQuestions.map(q => ({
            id: q.id,
            question: q.question,
            answer: "",
            option: "",
            status: ""
        }))
    };

   
    const [isShowData, setIsShowData] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [statusCount, setStatusCount] = useState(0);
    const [isDataEmpty, setIsDataEmpty] = useState(true);
    const [contestData, setContestData] = useState(initialContestData);
    const [submittedAnswers, setSubmittedAnswers] = useState({});
    const [selectedAnswers, setSelectedAnswers] = useState({});
    useEffect(() => {
        // Handle click outside of modal to close it
        const handleClickOutside = (event) => {
            const modalContent = document.querySelector('.modal-content');
            if (modalContent && !modalContent.contains(event.target)) {
                toggle()
            }
        };

        // Add event listener for clicks outside the modal
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleOptionSelect = (id, selectedOption, selectedAnswer, status) => {
        // Update the selected answers state
        setSelectedAnswers(prev => ({
            ...prev,
            [id]: selectedAnswer
        }));

        // Update contest data
        const updatedContestData = { ...contestData };
        const questionIndex = updatedContestData.questions.findIndex(
            (question) => question.id === id
        );

        if (questionIndex !== -1) {
            updatedContestData.questions[questionIndex] = {
                ...updatedContestData.questions[questionIndex],
                option: selectedOption,
                answer: selectedAnswer,
                status: status
            };
            setContestData(updatedContestData);
        }
    };

    const getContestData = async () => {
        try {
            setIsloading(true)
            const response = await axios.get(adminBaseURL + 'user/contest/data', authorize());
            if (response.data.status) {
                setIsShowData(response.data.is_date_show);
                const count = response.data.data.filter(item => item.status === 1).length;
                setStatusCount(count);
                setIsDataEmpty(response.data.data.length === 0);
                setIsloading(false)
                let q1 = "¿Cuál de las siguientes NO es una buena indicación de cirugía de pterigion?";
let q2 = "Señala la falsa respecto al uso de microscopía confocal:";
let q3 = "Indica la correcta respecto a la cirugía de pterigion:";
let q4 = "Indica la verdadera en relación al microscopio confocal y su uso clínico:";

// Map of predefined questions
const questionMap = {
    [q1]: "Indica cuál de las siguientes NO es una buena indicación de cirugía de pterigion",
    [q2]: "Señala la falsa respecto al uso de microscopía confocal",
    [q3]: "Indica la correcta respecto a la cirugía de pterigion",
    [q4]: "Indica la verdadera en relación al microscopio confocal y su uso clínico"
};

if (response.data.data.length > 0) {
    const answers = {};

    response.data.data.forEach(item => {
        // Check if item.question matches any predefined question (q1, q2, q3, q4)
        const mappedQuestion = questionMap[item.question] || item.question;  // If not, use item.question as is

        answers[mappedQuestion] = {
            selectedAnswer: item.answer,
            isCorrect: item.status === 1
        };
    });

    setSubmittedAnswers(answers);
}
                // if (response.data.data.length > 0) {
                //     const answers = {};
                //     response.data.data.forEach(item => {
                //         answers[item.question] = {
                //             selectedAnswer: item.answer,
                //             isCorrect: item.status === 1
                //         };
                //     });
                //     setSubmittedAnswers(answers);
                    
                // }
            }
        } catch (error) {
            setIsloading(false)
            console.error('Error fetching contest data:', error);
        }
    };

    const submitContestData = async () => {
        try {
            const allQuestionsAnswered = contestData.questions.every(
                (question) => question.answer !== "" && question.answer !== null
            );

            if (!allQuestionsAnswered) {
                toast.error(lang.complete_all_the_questions);
                return;
            }

            const updatedContestData = {
                ...contestData,
                questions: contestData.questions.map(({ id, ...rest }) => rest)
            };

            const response = await axios.post(
                adminBaseURL + 'user/contest/submit',
                updatedContestData,
                authorize()
            );

            if (response.data.status) {
                // toast.success("Quiz submitted successfully!");
                getContestData();
            }
        } catch (error) {
            console.error('Error submitting contest data:', error);
            toast.error("Failed to submit quiz");
        }
    };

    const getOptionClass = (option, question) => {
        if (!submittedAnswers[question]) return "";
        
        const isCorrectAnswer = option === quizQuestions.find(q => q.question === question).correctAnswer;
        const wasSelected = option === submittedAnswers[question].selectedAnswer;
        
        if (isCorrectAnswer) return "correct";
        if (wasSelected && !isCorrectAnswer) return "incorrect";
        return "";
    };

    useEffect(() => {
        getContestData();
    }, [isShowData]);

    return (
        <>
        {showMedicalQuiz && (
            <div className="modal d-flex justify-content-center align-items-center show"
                style={{
                    display: "block",
                    backgroundColor: "rgba(0, 0, 0, 0.5)"
                }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content d-flex flex-row"
                        style={{
                            borderRadius: "10px",
                            width: "1228px",
                            right: "216px",
                            position: "relative" // Added to contain absolute positioning
                        }}>
                        {/* Left side - Image section */}
                        <div className="d-flex flex-column justify-content-center align-items-center"
                            style={{
                                backgroundColor: "#f8f9fa",
                                flex: 1
                            }}>
                                {/* _imagesURL+ contestMedia */}
                                {/* contest_feb */}
                            <img src={contest_feb}
                                alt="Medical Contest"
                                style={{
                                    width: "640px",
                                    maxHeight: "350px",
                                    borderRadius: "10px"
                                }} />
                        </div>

                        {/* Right side - Content section */}
                        <div className="position-relative" // Added to contain the fixed button
                            style={{
                                width: "655px",
                                backgroundColor: "white",
                                borderRadius: "10px",
                            }}>
                            <div className="scroll-hide"
                                style={{
                                    height: isDataEmpty ? "calc(625px - 72px)" : "625px",
                                    overflowY: "auto",
                                     padding: "1.5rem",
                                    paddingBottom: "2rem"
                                }}>
                                {/* Header content */}
                                <h4 className="mb-3">
                                    <span><IoIosAlert size={20} color="#1dc1dd" /></span>{" "}
                                    ¿Cómo participar?
                                </h4>
                                <ol style={{ paddingLeft: "20px" }}>
                                    <li>Responda a las preguntas sobre la sesión.</li>
                                    <li>Publique un post en Medical Radar sobre cualquier tema relacionado con oftalmología.</li>
                                </ol>

                                {/* Show dates if available */}
                                {isShowData && (
                                    <>
                                        <h5 className="mt-4">
                                            <span><img src={calender} alt="Calendar" /></span>{" "}
                                            Fechas para participar:
                                        </h5>
                                        <p>Desde el miércoles 12 hasta el viernes 14 de febrero de 2025.</p>
                                    </>
                                )}
                                 {!isDataEmpty &&<>
                                <hr className="text-muted" />
                                
                                {/* Score display */}
                                <div className="d-flex justify-content-between">
                                    <p style={{ fontWeight: "500" }}>{lang.answers_submitted}</p>
                                    <p>
                                        <span style={{ fontWeight: "600" }}>{lang.correct} {statusCount} </span>
                                        {lang.out_of} <span style={{ fontWeight: "600" }}>4</span>
                                    </p>
                                </div>
                                
                                <hr className="text-muted" />
                                       </>}
                                {/* Questions section */}
                                {isLoading ? (
                                    <div className="d-flex justify-content-center">
                                        <Spinner/>
                                    </div>
                                ) : (
                                    <>
                                        {quizQuestions.map((question, qIndex) => (
                                            <div key={question.id}>
                                                <h5 className="mt-4">{question.question}</h5>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    {question.options.map((option, oIndex) => {
                                                        const optionClass = getOptionClass(option, question.question);
                                                        return (
                                                            <label 
                                                                key={oIndex} 
                                                                className={`custom-radio ${optionClass}`}
                                                                style={{
                                                                    color: optionClass === "correct" ? "#00c400" : 
                                                                           optionClass === "incorrect" ? "#ff3b30" : 
                                                                           "inherit",
                                                                    marginBottom: "10px",
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}
                                                            >
                                                                <input
                                                                    type={!isDataEmpty ? "checkbox" : "radio"}
                                                                    name={`question${question.id}`}
                                                                    disabled={!isDataEmpty}
                                                                    checked={
                                                                        isDataEmpty 
                                                                            ? selectedAnswers[question.id] === option
                                                                            : option === submittedAnswers[question.question]?.selectedAnswer ||
                                                                              option === question.correctAnswer
                                                                    }
                                                                    onChange={() => handleOptionSelect(
                                                                        question.id,
                                                                        String.fromCharCode(97 + oIndex),
                                                                        option,
                                                                        option === question.correctAnswer ? 1 : 0
                                                                    )}
                                                                    style={{ 
                                                                        marginRight: "10px",
                                                                        alignSelf: "flex-start",
                                                                        marginTop: "3px"
                                                                    }}
                                                                />
                                                                <span className={`radio-checkmark ${optionClass}`}
                                                                      style={{
                                                                          borderColor: optionClass === "correct" ? "#00c400" : "#ccc"
                                                                      }}>
                                                                    {!isDataEmpty && optionClass === "correct" && (
                                                                        <RiCheckboxCircleFill 
                                                                            style={{ 
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%, -50%)',
                                                                                color: '#00c400'
                                                                            }} 
                                                                            size={22}
                                                                        />
                                                                    )}
                                                                </span>
                                                                <span style={{ flex: 1 }}>{option}</span>
                                                            </label>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>

                            {/* Fixed Submit Button */}
                            {isDataEmpty && (
                                <div style={{
                                    padding: "1rem",
                                    background: "white",
                                    // borderTopWidth: "1px",
                                    // borderTopStyle: "solid",
                                    // borderTopColor: "#dee2e6",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                    marginTop: "auto"
                                }}>
                                    <button
                                        onClick={submitContestData}
                                        className="btn btn-dark w-100">
                                        {lang.submit}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
    );
};
