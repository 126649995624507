import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { ShimmerCircularImage, ShimmerThumbnail } from "react-shimmer-effects";

const ImageComponent = ({
  src,
  onClick,
  compClassName,
  img_width,
  img_height,
  loader_width,
  loader_height,
  roundShimmerSize,
  border,
  borderRadius,
  boxShadow,
  trending,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const loadImage = () => {
      const imageToLoad = new Image();
      imageToLoad.src = src;
      imageToLoad.onload = () => {
        setImageSrc(src);
      };
    };

    loadImage();
  }, [src]);

  return (
    <>
      {isLoading && (
        <div
          className={`${compClassName} ${isLoading ? "d-block" : "d-none"}`}
          style={{
            height: loader_height,
            width: loader_width,
          }}
        >
          {compClassName?.includes("rounded-circle") ? (
            <ShimmerCircularImage
              size={roundShimmerSize || 25}
              className="p-0 m-0"
            />
          ) : (
            <ShimmerThumbnail center={true} fitOnFrame={true} rounded />
          )}
        </div>
      )}
      <img
        src={imageSrc}
        onClick={onClick}
        className={`${compClassName} ${isLoading ? "d-none" : "d-block"}`}
        style={{border: border, boxShadow: boxShadow, width: trending ? img_width : "", borderRadius: borderRadius}}
        alt="..."
        width={img_width}
        height={img_height}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
};

export default ImageComponent;
