import { CropperRef, isEqualState, CropperProps, Cropper } from 'react-advanced-cropper';
import { Navigation } from './components/Navigation.tsx';
import { getCloserAngle } from 'advanced-cropper';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import "react-advanced-cropper/dist/style.css";
import { Stencil } from '../../Stencil.tsx';
import './DefaultCropper.scss';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

export interface DefaultCropperProps extends CropperProps {
  wrapperClassName?: string;
  updateCoordinates?: Function;
  updateCoordinatesCheck?: boolean;
  defaultSize?: ({ imageSize, visibleArea }) => { width: number; height: number };
}

export type DefaultCropperMethods = CropperRef;

export const DefaultCropper = forwardRef(({
  wrapperClassName,
  updateCoordinates,
  updateCoordinatesCheck,
  className,
  stencilProps,
  defaultSize: defaultSizeProp,
  ...props
}: DefaultCropperProps, ref) => {
  const [changed, setChanged] = useState(false);
  const cropperRef = useRef<CropperRef>(null);

  const defaultSize = defaultSizeProp || (({ imageSize, visibleArea }) => {
    return {
      width: (visibleArea || imageSize).width,
      height: (visibleArea || imageSize).height,
    };
  });

  useImperativeHandle(ref, () => ({
    ...cropperRef.current,
    refresh: () => {
      if (cropperRef.current) {
        const state = cropperRef.current.getState();
        if (state) {
          try {
            cropperRef.current.setState({
              ...state,
              stencilProps: {
                ...state.stencilProps,
                ...stencilProps,
              },
            });

            const imageSize = cropperRef.current.getImage();
            if (imageSize) {
              const defaultCoords = defaultSize({ imageSize, visibleArea: null });
              const centerX = imageSize.width / 2;
              const centerY = imageSize.height / 2;
              const newLeft = centerX - defaultCoords.width / 2;
              const newTop = centerY - defaultCoords.height / 2;

              cropperRef.current.setCoordinates({
                left: newLeft > 0 ? newLeft : 0,
                top: newTop > 0 ? newTop : 0,
                width: defaultCoords.width,
                height: defaultCoords.height,
              });

              if (updateCoordinates) {
                updateCoordinates({
                  left: newLeft > 0 ? newLeft : 0,
                  top: newTop > 0 ? newTop : 0,
                  width: defaultCoords.width,
                  height: defaultCoords.height,
                });
              }
            }
          } catch (error) {
            console.error("Error refreshing cropper:", error);
          }
        }
      }
    },
    getCoordinates: () => {
      return cropperRef.current?.getCoordinates();
    },
  }));

  const getDefaultState = () => {
    const currentState = cropperRef.current?.getState();
    const defaultState = cropperRef.current?.getDefaultState();
    return currentState && defaultState
      ? {
          ...defaultState,
          transforms: {
            ...defaultState.transforms,
            rotate: getCloserAngle(currentState.transforms.rotate, defaultState.transforms.rotate),
          },
        }
      : null;
  };

  const onRotate = (angle: number) => {
    if (!cropperRef.current) return;
    cropperRef.current.rotateImage(angle);
    setTimeout(() => {
      if (cropperRef.current && props.onInteractionEnd) {
        props.onInteractionEnd(cropperRef.current);
      }
    }, 50);
  };

  const onFlip = (horizontal: boolean, vertical: boolean) => {
    if (!cropperRef.current) return;
    cropperRef.current.flipImage(horizontal, vertical);
    setTimeout(() => {
      if (cropperRef.current && props.onInteractionEnd) {
        props.onInteractionEnd(cropperRef.current);
      }
    }, 50);
  };

  const onReset = () => {
    if (!cropperRef.current) return;
    cropperRef.current.setState(getDefaultState());
    setTimeout(() => {
      if (cropperRef.current && props.onInteractionEnd) {
        props.onInteractionEnd(cropperRef.current);
      }
    }, 50);
  };

  const onChange = useDebouncedCallback((cropper: CropperRef) => {
    setChanged(!isEqualState(cropper.getState(), getDefaultState()));
    if (updateCoordinatesCheck && updateCoordinates) {
      updateCoordinates(cropper.getCoordinates());
    }
    if (props.onInteractionEnd) {
      props.onInteractionEnd(cropper);
    }
  }, 200);

  useEffect(() => {
    if (cropperRef.current && stencilProps && stencilProps.aspectRatio) {
      const currentCoordinates = cropperRef.current.getCoordinates();
      if (currentCoordinates) {
        const centerX = currentCoordinates.left + currentCoordinates.width / 2;
        const centerY = currentCoordinates.top + currentCoordinates.height / 2;
        const newWidth = currentCoordinates.height * stencilProps.aspectRatio;
        cropperRef.current.setCoordinates({
          width: newWidth,
          height: currentCoordinates.height,
          left: centerX - newWidth / 2,
          top: currentCoordinates.top,
        });
      }
    }
  }, [stencilProps?.aspectRatio]);

  return (
    <div className={cn('default-cropper', wrapperClassName)}>
      <Cropper
        onChange={onChange}
        className={cn('default-cropper__cropper', className)}
        ref={cropperRef}
        defaultSize={defaultSize}
        stencilProps={stencilProps}
        onTransformChange={(transform) => {
          if (props.onInteractionEnd && cropperRef.current) {
            props.onInteractionEnd(cropperRef.current);
          }
        }}
        {...props}
      />
      <div className="default-cropper__navigation">
        <Navigation
          changed={changed}
          onReset={onReset}
          onFlip={onFlip}
          onRotate={onRotate}
          disabled={!cropperRef.current}
        />
      </div>
    </div>
  );
});

DefaultCropper.displayName = 'DefaultCropper';