import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { Language, LoginUserData, PostDetailContext } from "../../App";
import { _imagesURL, DeleteCommentApi, GetCommentsApi, GetRepliesApi, DeletePostApi, EditPostApi, adminBaseURL, FollowApi } from "../../constants/API";
import ImageComponent from "./ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import Like from "../../assets/images/icon/like.png";
import Liked from "../../assets/images/icon/liked.png";
import Reshare from "../../assets/images/icons/repost.svg";
import ReshareActive from "../../assets/images/icons/repost-active.svg";
import Save from "../../assets/images/icons/save.svg";
import Saved from "../../assets/images/icons/saved.svg";
import likeComment from "../../assets/images/icons/LikeComment.svg";
import likeCommentFilled from "../../assets/images/icons/LikeCommentFilled.svg";
import { BsThreeDots } from "react-icons/bs";
import { LiaReplySolid } from "react-icons/lia";
import { IoChevronDownSharp } from "react-icons/io5";
import Comment from "../../assets/images/icon/comment.png";
import { GoDotFill } from "react-icons/go";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { likePostApi, repostPostApi, reserveWebinarApi, savePostApi, usersLikedPostApi } from "../../services/feedApis";
import { useMutation, useQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroller";
import { PROFILE } from "../../constants/RoutesUrl";
import CommentCanvas from "../../pages/post-details/comment-details/CommentCanvas";
import RepostUserModal from "../feeds/RepostUserModal";
import WebinarInvited from "../webinar/WebinarInvited";
import ClinicalPoll from "../clinical-sessions/ClinicalPoll";
import { GoClock, GoScreenFull } from "react-icons/go";
import { IoCalendarOutline, IoCloseSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { ReadMore } from "./ReadMore";
import CommentList from "../comments/CommentList";
import AddComment from "../comments/AddComment";
import { authorize } from "../../shared/authorize";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import PostHeaderDropdown from "../feeds/PostHeaderDropdown";
import ShareModal from "../feeds/ShareModal";
import InviteWebinarPopup from "../feeds/postTabs/InviteWebinarPopup";
import PostForm from "../feeds/PostForm";
import PollOptionsView from "../../pages/PollOptionsView";
import PollResultsView from "../../pages/PollResultsView";
import { MdOutlineReport } from "react-icons/md";
import ReportModal from "../../pages/ReportModal";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import { UnixToRelative } from "../notification/Notification";

export default function PostDetailModal({ isOpen, onClose, type }) {
  const navigate = useNavigate();
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  const { postDetail, updatePostDetail, postModalClose } = useContext(PostDetailContext);
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const [commentModal, setCommentModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fileToView, setFileToView] = useState({ type: null, media: null });
  const [isLikedUsersModal, setIsLikedUsersModal] = useState(false);
  const [width, setWidth] = useState("47%");
  const [hideText, setHideText] = useState(false);
  const [comment, setComment] = useState("");
  const [replyingTo, setReplyingTo] = useState("");
  const [postComments, setPostComments] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const [parentId, setParentId] = useState("");
  const [replyId, setReplyId] = useState("");
  const [commentReplies, setCommentReplies] = useState({});
  const [showReplies, setShowReplies] = useState({});
  const [loader, setLoader] = useState(false);
  const [postDropDown, setPostDropDown] = useState(false);
  const [_isOpen, setIsOpen] = useState(false);
  const [dropdownId, setDropDownId] = useState(null);
  const [reportModal, setReportModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [editPostInput, setEditPostInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inviteWebinar, setInviteWebinar] = useState(false)
  const [webinarType, setWebinarType] = useState(null)
  const [editWebinar, setEditWebinar] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
    const [showPost, setShowPost] = useState(false);
    const [pollId, setPollId] = useState(null)
    const [modalOpenPoll, setModalOpenPoll] = useState(false);
      const [selectedImage, setSelectedImage] = useState(null) //Selected Image Preview in AddComment Section
  useEffect(() => {
    const handleResize = () => {
      setHideText(window.innerWidth < 990);
      setWidth(window.innerWidth < 1200 ? "60%" : "47%");
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("chrome") > -1 && userAgent.indexOf("edge") === -1) {
      setIsChrome(true);
    }
  }, []);

  const openModal = (file, type) => {
    setFileToView({ ...fileToView, type: type, media: file });
    setPdfModalOpen(true);
  };

  const closeModal = () => {
    setPdfModalOpen(false);
    setFileToView(null);
  };

  const handleLike = useMutation({
    mutationFn: () => likePostApi({ post_id: postDetail.original_id }),
    onError: (error) => toast.error(error.message),
    onMutate: () => {
      // updateLikesCount()
    },
    onSuccess: (data) => {
      if (!data.status) toast.error(data.action);
    },
  });

  const handleSave = useMutation({
    mutationFn: () => savePostApi({ post_id: postDetail.original_id }),
    onError: (error) => toast.error(error.message),
    onMutate: () => {
      postDetail.isSave = !postDetail.isSave;
      updatePostDetail(postDetail);
    },
    onSuccess: (data) => {
      if (!data.status) toast.error(data.action);
    },
  });

  const handleReserveWebinar = useCallback(async () => {
    try {
      postDetail.isReserve = !postDetail.isReserve;
      updatePostDetail(postDetail);
      await reserveWebinarApi({ post_id: postDetail.original_id });
    } catch (error) {
      console.error(error);
    }
  }, [postDetail]);

  const handleRepost = useMutation(
    () => repostPostApi({ post_id: postDetail.original_id }),
    {
      onError: (error) => toast.error(error.message),
      onMutate: () => {
        postDetail.isShare = !postDetail.isShare;
        updatePostDetail(postDetail);
      },
      onSuccess: (data) => {
        if (!data.status) toast.error(data.action);
      },
    }
  );

  const handleDeletePost = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${DeletePostApi}${postDetail.original_id}`, authorize());
      if (response.data.status) {
        setDeleteModal(false);
        onClose();
        toast.success("Post deleted successfully!");
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPost = async () => {
    setIsLoading(true);
    try {
      const editData = { caption: editPostInput, post_id: dropdownId };
      const response = await axios.post(EditPostApi, editData, authorize());
      if (response.data.status) {
        setEditModal(false);
        postDetail.caption = editPostInput;
        updatePostDetail(postDetail);
        toast.success("Post updated successfully!");
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  function convertTimestampToFormattedTime(timestamp) {
    const date = new Date(timestamp * 1000);
    const timeString = date.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
    const dateString = date.toLocaleDateString("en-US", {
      weekday: "long",
      day: "2-digit",
      year: "numeric",
      month: "long",
    });
    return `${timeString} on ${dateString}`;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const updateCommentsCount = (postId, change) => {
    if (postDetail?.id === postId) {
      postDetail.comments_count += change;
      updatePostDetail(postDetail);
    }
  };

  const updateLikesCount = () => {
    postDetail.likes_count = postDetail.isLike
      ? postDetail?.likes_count - 1
      : postDetail?.likes_count + 1;
    postDetail.isLike = !postDetail.isLike;
    updatePostDetail(postDetail);
  };

  const handleRepostClick = () => {
    if (type !== "poll") {
      if (!isOwner) {
        handleRepost.mutate();
      } else {
        setModalOpen(true);
      }
    } else {
      if (isOwner) {
        setModalOpen(true);
      } else {
        if (!postDetail.isShare) {
          setModalOpen(!modalOpen);
        } else {
          handleRepost.mutate();
        }
      }
    }
  };

  function getWebinarButtonText(value) {
    const userIsHost = value.user.id === user_id;
    const status = value.status;
    const isReserve = value.isReserve;

    if (userIsHost) {
      if (status === 0) {
        return lang.not_start_yet;
      } else if (status === 2) {
        return lang.webinar_ended;
      } else {
        return lang.start_webinar;
      }
    } else {
      if (status === 0) {
        return isReserve ? lang.unreserve : lang.reserve_seat;
      } else if (status === 1) {
        return isReserve ? lang.join_webinar : lang.reserve_seat;
      } else if (status === 2) {
        return lang.webinar_ended;
      }
    }
  }

  const self_like_image = user?.data?.image;
  const isOwner = postDetail?.user?.id === user_id;
  const repostTitle = isOwner
    ? `${postDetail.shares_count} ${lang?.repost}`
    : `${postDetail.isShare ? lang?.reposted : lang?.repost}`;

  const handleGetComments = useCallback(
    async (post_id) => {
      setCommentLoading(true);
      const id = post_id || postDetail.original_id;
      try {
        const response = await axios.get(`${GetCommentsApi}${id}?page=1`, authorize());
        if (response.data.status) {
          setPostComments(response?.data?.data);
        }
        setCommentLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [postDetail.original_id, user_id]
  );

  const handleGetReplies = useCallback(
    async (parentId) => {
      // If replies are already shown, hide them (optional toggle behavior)
      if (showReplies[parentId]) {
        setShowReplies((prev) => ({
          ...prev,
          [parentId]: false,
        }));
        return;
      }

      setLoader((prev) => ({ ...prev, [parentId]: true }));
      try {
        const response = await axios.get(`${GetRepliesApi}${parentId}`, authorize());
        if (response.data.status) {
          setCommentReplies((prev) => ({
            ...prev,
            [parentId]: response.data.data,
          }));
          setShowReplies((prev) => ({
            ...prev,
            [parentId]: true,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoader((prev) => ({ ...prev, [parentId]: false }));
      }
    },
    [showReplies]
  );

  // useEffect(() => {
  //   if (postComments.data?.length > 0) {
  //     postComments.data.forEach((comment) => {
  //       const parentId = comment.id; // Assuming each comment has an 'id' field
  //       if (!commentReplies[parentId] && !showReplies[parentId]) {
  //         // Fetch replies only if not already fetched
  //         handleGetReplies(parentId);
  //       }
  //     });
  //   }
  // }, [postComments, commentReplies, showReplies, handleGetReplies]);

  // Example: Trigger handleGetComments on mount or when postDetail changes
 
  useEffect(() => {
    if (postDetail.original_id) {
      handleGetComments();
    }
  }, [postDetail.original_id, handleGetComments]);

  const closeBtn = (
    <Button
      style={{
        color: "black",
        backgroundColor: "#e6e6e6",
        border: "2px solid #c4c4c4",
        borderRadius: "15%",
        width: "30px",
        height: "30px",
        margin: postDetail?.type === "poll"  ? (postDetail?.media?.length === 0 ? "5px" : "9px") : "20px",      
        padding: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={()=>{
        onClose()
        editWebinarTab(false)
        setSelectedPost(null)
      }}
    >
      <FaTimes size={16} />
    </Button>
  );

  const dropdownRef = useRef(null);

  const handleDotsClick = (e) => {
    e.stopPropagation();
    setPostDropDown((prev) => !prev);
    setDropDownId(postDetail?.original_id);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setPostDropDown(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFollow = useCallback(async (id) => {
    try {

      const res = await axios.get(`${FollowApi}${id}`, authorize());

      if (res.data.status) {
        postDetail.user.follow = !postDetail.user.follow;
        updatePostDetail(postDetail);
      } else {
        console.log(res.data.error);
      }
    } catch (error) {
      console.error('Error following user:', error);
    } finally {
      setIsLoading(false);
    }
  }, [postDetail, user_id]);
 
  const handlePostSelect = (postDetail) => {
    setSelectedPost(postDetail);
  };
  const editWebinarTab = (edit) => {
    setEditWebinar(edit)
  }
  const handlePollAnswer = async (post_id, answer) => {
    console.info("Poll Answer", post_id, answer);
    try {
      // Store original state in case we need to revert
      const originalPostDetail = { ...postDetail };
      
      // Optimistically update postDetail directly
      postDetail.id = post_id;
      postDetail.right = answer + 1;
      postDetail.votes_count = postDetail.votes_count + 1;
      updatePostDetail({ ...postDetail }); // Pass copy to ensure update is triggered
      
      // Make the API request
      const response = await axios.post(
        `${adminBaseURL}user/post/poll/answer/submit`,
        {
          post_id,
          answer: answer + 1,
        },
        authorize()
      );
      
      if (!response.data.status) {
        toast.error(response.data.action);
        // Revert to original state
        // updatePostDetail(originalPostDetail);
      } else {
        // Update with server data directly on the postDetail object
        postDetail.percs = response.data.data; // Update with actual percs from API
        updatePostDetail({ ...postDetail }); // Pass copy to ensure update is triggered
      }
    } catch (error) {
      toast.error(error.message);
      // Revert optimistic update on error
      updatePostDetail({ ...postDetail }); // Use the current postDetail
    }
  };

  const removePollAnswer = async (id) => {
    try {
      // Keep a copy of the current state before modifications
      const originalPostDetail = { ...postDetail };
      
      // Optimistically update postDetail directly
      postDetail.id = id;
      postDetail.right = 0;
      postDetail.votes_count = postDetail.votes_count - 1;
      updatePostDetail({ ...postDetail }); // Pass copy to ensure update is triggered
      
      // Make the API request
      const response = await axios.get(
        `${adminBaseURL}user/post/poll/answer/remove/${id}`,
        authorize()
      );
      
      if (!response.data.status) {
        toast.error(response.data.action);
        // Revert optimistic update using our saved copy
        updatePostDetail(originalPostDetail);
      }
      // No additional update needed on success
    } catch (error) {
      toast.error(error.message);
      // Revert optimistic update on error using our saved copy
      // updatePostDetail(originalPostDetail);
    }
  };

        const toggleModal = useCallback((id) => {
          setPollId(id);
          setModalOpenPoll((prev) => !prev);
        }, []);

const handlePostReport = useCallback(async (reportInput) => {
    if(!reportInput){
      toast.error("Please enter a report");
      return;
    }
    setIsLoading(true);
    try {
      const reportData = {
        feature_id: dropdownId,
        user_id,
        report: reportInput,
        type: "post",
      };
      const response = await axios.post(`${adminBaseURL}app/report`, reportData,authorize);
      if (response.data.status) {
        toast.success("Reported Successfully!");
        setReportModal(false);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [dropdownId, user_id]);



  return postDetail?.original_id ? (
    <>
       <Modal
        isOpen={isOpen}
        toggle={onClose}
        centered
        size={
          postDetail?.type === "poll"
            ? postDetail?.media?.length === 0
              ? "lg" // When poll and no media
              : undefined // When poll with media
            : "xl" // When not a poll
        }
        className="post_detail_modal"
        style={{
          borderRadius: "20px",
          ...(postDetail?.type === "poll" &&
            postDetail?.media?.length !== 0 && {
              width: "1400px",
              maxWidth: "fit-content",
            }),
        }}
        backdrop="static"
      >
        <div
          className="d-flex flex-column flex-lg-row bg-white w-100"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              backgroundColor: "#f5f5f5",
              width:
              postDetail?.type === "poll" && postDetail?.media?.length === 0
                ? "0"
                : window.innerWidth < 992
                ? "100%"
                : "65%",
              borderRadius: "20px 0 0 20px",
            }}
          >
            {postDetail?.media?.length !== 0 &&  closeBtn }
            {postDetail?.type === "image" ||
            postDetail.type === "webinar" ||
            postDetail.type === "poll" ||
            postDetail?.type === "clinical-image" ? (
              <Carousel
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <IoIosArrowDropleftCircle
                    className="top-0 bottom-0 rounded-circle position-absolute m-auto ms-3 start-0 cursor"
                    size={30}
                    style={{ zIndex: "1", background: '#1DC1DD' }}
                    color="white"
                    onClick={onClickHandler}
                  />
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <IoIosArrowDroprightCircle
                                  className="top-0 bottom-0 rounded-circle position-absolute m-auto end-0 me-3 cursor"
                                  size={30}
                                  style={{background: '#1DC1DD'}}
                                  color="white"
                                  onClick={onClickHandler}
                                />
                  )
                }
                className={
                  postDetail?.type === "poll" && postDetail?.media?.length === 0 ? "d-none" : "d-block"
                }
              >
                {postDetail?.media.map((data, index) => (
                  <img
                    key={index}
                    src={_imagesURL + data}
                    alt="..."
                    style={{
                      borderRadius: "5px 0px 0px 5px",
                      height: "90vh",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                ))}
              </Carousel>
            ) : postDetail?.type === "video" ? (
              <video
                src={_imagesURL + postDetail?.media[0]}
                controls
                autoPlay
                alt="..."
                style={{
                  borderRadius: "5px 0px 0px 5px",
                  height: "90vh",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            ) : null}
          </div>
          <div
            style={{
              width:
                postDetail?.type === "poll" && postDetail?.media?.length === 0 ? "100%" : width,
            }}
            className="position-relative"
          >
            <div className="d-flex p-2 pe-3 border-bottom border-inherit align-items-center justify-content-between">
              <div className="d-flex gap-2">
              {postDetail?.type === "poll" && postDetail?.media?.length === 0 &&  closeBtn }
                <div
                  className="bg-white rounded-circle align-self-center cursor"
                  style={{
                    padding: "2px",
                    width: "40px",
                    height: "40px",
                    border: "1px solid rgba(29, 193, 221, 1)",
                  }}
                  onClick={() => { postModalClose(); navigate(`/profile/${postDetail?.user?.id}`); }}
                >
                  <ImageComponent
                    src={
                      postDetail?.user?.image
                        ? _imagesURL + postDetail.user.image
                        : Avatar
                    }
                    img_height="100%"
                    img_width="100%"
                    compClassName="rounded-circle"
                    roundShimmerSize={34}
                  />
                </div>
                <div className="align-self-center">
                  <h1
                    className="fs-14 roboto-bold hover-blue mb-0 text-ellips-162px cursor"
                    onClick={() => {
                      postModalClose();
                      navigate(`/profile/${postDetail?.user?.id}`);
                    }}
                  >
                    {postDetail?.user?.name}
                  </h1>
                  <p className="mb-0 fs-12 roboto-regular"
                  style={{
                    color: "#434343",
                    opacity: "80%",
                  }}>
                    {lang.posted} <UnixToRelative unixTimestamp={postDetail?.time} />
                  </p>
                </div>
              </div>
              <div className="position-relative" ref={dropdownRef}>
                <BsThreeDots className="fs-24 cursor" onClick={handleDotsClick} />
                
                {postDropDown && (
                  <div isOpen={postDropDown}>
                  <PostHeaderDropdown
                    postDetails={postDetail}
                    postDropDown={postDropDown}
                    dropDownId={dropdownId}
                    type={postDetail.type}
                    isSave={postDetail.isSave}
                    isShare={postDetail.isShare}
                    shares_count={postDetail.shares_count}
                    isFollow={postDetail.user.follow}
                    isOwner={isOwner}
                    user_id={user_id}
                    lang={lang}
                    handleSave={handleSave.mutate}
                    handleRepost={handleRepost.mutate}
                    handleFollow={handleFollow}
                    setReportModal={setReportModal}
                    setEditModal={setEditModal}
                    setDeleteModal={setDeleteModal}
                    setShowPost={setShowPost}
                    editWebinarTab={editWebinarTab}
                    handlePostSelect={handlePostSelect}
                    setInviteWebinar={setInviteWebinar}
                    setWebinarType={setWebinarType}
                    setShareModal={setShareModal}
                    setIsOpen={setIsOpen}
                    isOpen={_isOpen}
                    toggleRepostModal={() => setModalOpen(!modalOpen)}
                    from="postModal"
                    setEditPostInput={setEditPostInput}
                    closeDetailModal={onClose}
                  />
                  </div>
                )}
                
              </div>
            </div>
            <div
              className={`p-2 ${
                postDetail?.type === "webinar" || postDetail.type === "clinical-image"
                  ? null
                  : "border-bottom border-inherit"
              } ${postDetail?.type === "poll" && "scroll-hide"}`}
              style={{maxHeight: postDetail?.type === "poll" && "250px", overflowY: postDetail?.type === "poll" && "auto"}}
            >
              {postDetail?.type === "webinar" || postDetail?.type === "clinical-image" ? (
                <div
                  className={`p-2 ${
                    postDetail?.type === "webinar" || postDetail.type === "clinical-image"
                      ? "scroll-hide"
                      : ""
                  }`}
                  style={{
                    maxHeight:
                      postDetail?.type === "webinar" || postDetail.type === "clinical-image"
                        ? "130px"
                        : undefined,
                    overflowY:
                      postDetail?.type === "webinar" || postDetail.type === "clinical-image"
                        ? "auto"
                        : undefined,
                  }}
                >
                  <h4>{postDetail?.title}</h4>
                  <ReadMore text={postDetail?.caption} />
                </div>
              ) : (
                postDetail.type !== "poll" && 
                  <div className="scroll-hide overflowY-auto" style={{ maxHeight: "130px" }}>
                    <ReadMore text={postDetail?.caption} />
                  </div>
                
              )}
                {postDetail.type === "poll" && (
                <>
                    <h3>{postDetail.title}</h3>
                    {postDetail.right === 0 && user_id !== postDetail.user.id ? (
                      <PollOptionsView
                        value={postDetail}
                        handlePollAnswer={handlePollAnswer}
                        toggleModal={toggleModal}
                      />
                    ) : (
                      <PollResultsView
                        value={postDetail}
                        toggleModal={toggleModal}
                        removePollAnswer={removePollAnswer}
                      />
                    )}
                </>
              )}
            </div>
            {postDetail?.type !== "webinar" && (
              <CommentList
                postComments={postComments}
                setPostComments={setPostComments}
                handleGetComments={handleGetComments}
                handleGetReplies={handleGetReplies}
                setCommentReplies={setCommentReplies}
                loader={loader}
                commentReplies={commentReplies}
                commentLoading={commentLoading}
                setParentId={setParentId}
                setReplyId={setReplyId}
                parentId={parentId}
                setReplyingTo={setReplyingTo}
                setShowReplies={setShowReplies}
                showReplies={showReplies}
                captions ={postDetail?.caption}
                editWebinarTab={editWebinarTab}
                setSelectedPost={setSelectedPost}
                onClose={onClose}
                type={postDetail?.type}
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
              />
            )}

            <>
              {postDetail?.likes_count > 0 && (
                <div
                  className="p-2 ps-4 border-bottom border-inherit d-flex" >
                  {postDetail?.like_users_images?.length > 0 ? (
                    postDetail.like_users_images.slice(0, 3).map((data, index) => (
                      <div
                        key={index}
                        className="bg-white rounded-circle cursor"
                        onClick={() => setIsLikedUsersModal(true)}
                        style={{
                          marginLeft: "-10px",
                          padding: "1px",
                          border: "1px solid rgba(29, 193, 221, 1)",
                        }}
                      >
                        <ImageComponent
                          src={data && data !== ".." ? _imagesURL + data : Avatar}
                          img_width={25}
                          img_height={25}
                          roundShimmerSize={20}
                          compClassName="rounded-circle"
                        />
                      </div>
                    ))
                  ) : (
                    <div
                      className="bg-white rounded-circle cursor"
                      onClick={() => setIsLikedUsersModal(true)}
                      style={{
                        marginLeft: "-10px",
                        padding: "1px",
                        border: "1px solid rgba(29, 193, 221, 1)",
                      }}
                    >
                      <ImageComponent
                        src={self_like_image ? _imagesURL + self_like_image : Avatar}
                        img_width={25}
                        img_height={25}
                        roundShimmerSize={20}
                        compClassName="rounded-circle"
                      />
                    </div>
                  )}
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <p className="ps-2 fs-14 fs-md-12 roboto-medium mb-0 align-self-center text-ellips-162px hover-blue cursor" onClick={() => setIsLikedUsersModal(true)}>
                      {postDetail?.likes_count > 3
                        ? `& ${postDetail?.likes_count - 3} ${lang?.other_have_liked}`
                        : lang?.have_liked}
                    </p>
                    {postDetail?.type !== "webinar" && postDetail?.comments_count > 0 &&  (
                      <p className="mb-0 fw-bold fs-14 fs-md-12">
                        {postDetail?.comments_count > 0 ? postDetail?.comments_count : ""}{" "}
                        <span>{lang?.comments}</span>
                      </p>
                    )}
                  </div>
                </div>
              )}

              {postDetail?.type !== "webinar" && (
                <div>
                  <>
                    <div className="d-flex p-3 border-bottom border-inherit justify-content-between align-items-center">
                      <div className="d-flex gap-lg-5 gap-md-6 gap-sm-3">
                        {/* <div onClick={handleLike.mutate} className="d-flex gap-1 cursor">
                          <img
                            width={18}
                            height={18}
                            src={postDetail?.isLike ? Liked : Like}
                            alt="..."
                          />
                          <p className="mb-0 fw-bold fs-14 fs-md-12">
                            {postDetail?.likes_count > 0 && postDetail?.likes_count}{" "}
                            {postDetail?.likes_count > 1 ? lang?.likes : lang?.like}
                          </p>
                        </div> */}
                        <div
                          onClick={() => {
                            handleLike.mutate();
                            updateLikesCount();
                          }}
                          className="d-flex align-items-center gap-1 cursor"
                        >
                          <img
                            width={18}
                            height={18}
                            src={postDetail?.isLike ? Liked : Like}
                            alt="..."
                          />
                          <div className="d-flex align-items-center">
                            {postDetail?.likes_count > 0 && (
                              <span className="mb-0 fw-bold fs-14 fs-md-12">
                                {postDetail?.likes_count}
                              </span>
                            )}
                            <span className="mb-0 fw-bold fs-14 fs-md-12 ms-1" style={{whiteSpace: 'nowrap'}}>
                              {postDetail?.likes_count > 1 ? lang?.likes : lang?.like}
                            </span>
                          </div>
                        </div>

                        <div className="d-flex gap-1 cursor justify-content-center">
                          <img title="comment" width={18} height={18} src={Comment} alt="..." />
                          <p className="mb-0 fw-bold fs-14 fs-md-12" style={{whiteSpace: 'nowrap'}}>
                            {postDetail?.comments_count > 0 ? postDetail?.comments_count : ""}{" "}
                            <span >
                              {" "}
                              {lang?.comments}
                            </span>
                          </p>
                        </div>
                        <div onClick={handleRepostClick} className="d-flex gap-1 cursor">
                          <img
                            src={postDetail?.isShare ? ReshareActive : Reshare}
                            width={18}
                            height={18}
                            alt="..."
                          />
                          <p
                            className={`mb-0 fw-bold fs-14 fs-md-12 ${
                              postDetail?.isShare && "text-blue"
                            }`}
                            style={{whiteSpace: 'nowrap'}}
                          >
                            {repostTitle}
                          </p>
                        </div>
                        <div onClick={handleSave.mutate} className="d-flex gap-1 cursor">
                          <img
                            className="w-lg-23px"
                            title={
                              postDetail?.isSave ? lang?.save_description : lang?.unsave_description
                            }
                            src={postDetail?.isSave ? Saved : Save}
                            width={18}
                            height={18}
                            alt="..."
                          />
                          <p
                            className={`mb-0 fw-bold fs-14 fs-md-12 ${
                              postDetail?.isSave && "text-blue"
                            }`}
                            style={{whiteSpace: 'nowrap'}}
                          >
                            {postDetail?.isSave ? lang?.saved : lang?.save}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              )}

              {postDetail?.type === "webinar" ? (
                <>
                  <Row className="px-3 my-2">
                    <Col className="d-flex align-self-center gap-3 mb-2">
                      <div className="d-flex gap-2">
                        <GoClock className="text-warning" />
                        <small className="mb-0 text-warning roboto-medium">
                          {postDetail?.schedule_time}
                        </small>
                      </div>
                      <div className="d-flex gap-2">
                        <IoCalendarOutline className="text-warning" />
                        <small className="mb-0 text-warning roboto-medium">
                          {formatDate(postDetail?.schedule_date)}
                        </small>
                      </div>
                    </Col>
                    <WebinarInvited webinarId={postDetail?.original_id} />
                  </Row>
                  <Row className="px-3 my-2">
                    <Col className="align-self-center">
                      <button
                        className={`px-4 py-2 fw-bold w-100 rounded ${
                          postDetail?.status === 2 && !postDetail?.isReserve
                            ? "bg-white border-danger text-danger"
                            : "follow-btn border-0 text-white"
                        }`}
                        onClick={() => {
                          if (postDetail?.user.id === splashData?.user?.id) {
                            if (postDetail?.status === 0) return;
                            if (postDetail?.status === 2) return toast.info(lang.webinar_ended);
                            window.open(
                              postDetail?.isHost
                                ? postDetail?.meeting_host_url
                                : postDetail?.meeting_url,
                              "_blank"
                            );
                          } else {
                            if (postDetail?.status === 0) return handleReserveWebinar();
                            if (postDetail?.status === 1) {
                              if (postDetail?.isReserve) {
                                window.open(
                                  postDetail?.meeting_url || postDetail?.meeting_host_url,
                                  "_blank"
                                );
                              } else {
                                return handleReserveWebinar();
                              }
                            } else {
                              return toast.info(lang.webinar_is_ended);
                            }
                          }
                        }}
                      >
                        {getWebinarButtonText(postDetail)}
                      </button>
                    </Col>
                  </Row>
                </>
              ) : postDetail?.type === "clinical-image" ? (
                <>
                  <ClinicalPoll
                    clinicalId={postDetail?.original_id}
                    onClose={onClose}
                    customHeight={postDetail?.likes_count > 0 ? "customHeight" : undefined}
                  />
                  {postDetail?.pdf && (
                    <div className="px-3 position-absolute w-100" style={{ bottom: "10px" }}>
                      <button
                        className="border-0 px-3 py-1 text-white bg-danger rounded roboto-medium w-100 mt-1"
                        onClick={() => openModal(postDetail?.pdf, "pdf")}
                      >
                        {lang?.view_Case_Study}
                      </button>
                    </div>
                  )}
                </>
              ) : null}
            </>
            { postDetail?.type !== "webinar" && 
                  <AddComment postId={postDetail.original_id} setSelectedImage={setSelectedImage} selectedImage={selectedImage} setParentId={setParentId} replyId={replyId} handleGetReplies={handleGetReplies} parentId={parentId} comment={comment} setComment={setComment} replyingTo={replyingTo} setReplyingTo={setReplyingTo} handleGetComments={handleGetComments} commentLoading={commentLoading} />
              }
          </div>
        </div>
      </Modal>
     {isLikedUsersModal&& <UsersLikedPostModal
        isOpen={isLikedUsersModal}
        onClose={() => setIsLikedUsersModal(false)}
        postId={postDetail?.original_id}
      />}
      {commentModal && (
        <CommentCanvas
          postModal={commentModal}
          setPostModal={setCommentModal}
          postId={postDetail?.id}
          onAddComment={updateCommentsCount}
          onRemoveComment={updateCommentsCount}
        />
      )}
      {modalOpen && (
        <RepostUserModal
          toggleModal={() => setModalOpen(!modalOpen)}
          modal={modalOpen}
          postId={postDetail.original_id}
        />
      )}
      {pdfModalOpen && (
        <Modal
          isOpen={pdfModalOpen}
          centered
          toggle={closeModal}
          size={isFullScreen ? "lg" : "lg"}
          className={isFullScreen ? "fullscreen-modal" : ""}
          style={isFullScreen ? { maxWidth: "100vw", maxHeight: "100vh", margin: 0 } : {}}
        >
          <ModalBody className="modal-body-view">
            {fileToView && fileToView?.type === "pdf" && (
              <div
                className={`position-relative custom_pdf_viewer ${
                  isFullScreen ? "full-screen" : ""
                }`}
                style={{ transition: "all 0.3s ease-in-out" }}
              >
                <div className="position-relative custom_pdf_viewer">
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: isFullScreen ? "46px" : "40px",
                      background: "white",
                      zIndex: 1000,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "bold",
                          color: "#333",
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        Case Study PDF
                      </h4>
                    </div>
                    <hr />
                  </div>
                  <iframe
                    title="PDF Viewer"
                    src={`${_imagesURL + fileToView?.media}#toolbar=0&navpanes=0&scrollbar=0&view=FitH&messages=0&print=0&download=0`}
                    width="100%"
                    height="500px"
                    className={isChrome ? "mt-5" : ""}
                    style={{ border: "none" }}
                  />
                  <span
                    title={isFullScreen ? "Exit full screen" : "Full Screen"}
                    onClick={toggleFullScreen}
                    className="position-absolute cursor text-white px-2 py-1"
                    style={{ borderRadius: "5px", top: "0px", right: "0px", zIndex: 99999 }}
                  >
                    {isFullScreen ? (
                      <IoCloseSharp className="fs-3 text-black" />
                    ) : (
                      <GoScreenFull className="fs-3 text-black" />
                    )}
                  </span>
                </div>
              </div>
            )}
          </ModalBody>
        </Modal>
      )}
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered zIndex={9999}>
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">{lang?.do_want_delete_post}</h2>
            <p className="fs-16 fs-md-15 roboto-regular">{lang?.after_deleting_post_see_post}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setDeleteModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeletePost}
            >
              {isLoading ? <Spinner size={"sm"} /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={editModal} toggle={() => setEditModal(false)} centered zIndex={9999}>
        <ModalHeader>{lang?.edit_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.what_your_mind}</h6>
          <textarea
            rows="7"
            className="form-control my-3"
            value={editPostInput}
            onChange={(e) => setEditPostInput(e.target.value)}
          ></textarea>
          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setEditModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              disabled={!editPostInput}
              onClick={handleEditPost}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.update}
            </button>
          </div>
        </ModalBody>
      </Modal>
     {reportModal && <ReportModal
            isOpen={reportModal}
            toggle={setReportModal}
            handleReport={handlePostReport}
            isLoading={isLoading}
            heading={lang.report_post} 
          />}
          
        {inviteWebinar && <InviteWebinarPopup
              isOpen={inviteWebinar}
              webinarId={postDetail?.original_id}
              toggleModal={() => {
                setInviteWebinar(!inviteWebinar);
                setWebinarType(null)
              }}
              type={webinarType}
            />}
            {shareModal &&
              <ShareModal
                isOpen={shareModal}
                toggle={() => setShareModal(!shareModal)}
                type={type}
                postId={postDetail.original_id}
              />
            }
      
            {modalOpen && <RepostUserModal toggleModal={()=>setModalOpen(!modalOpen)} modal={modalOpen} postId={postDetail?.original_id} />}
             {showPost&& <PostForm selectedPost={selectedPost} editWebinar={editWebinar} setEditWebinar={setEditWebinar} showPost={showPost} setShowPost={setShowPost} refetch={()=>{}} />}
    </>
  ) : null;
}

export function UsersLikedPostModal({ isOpen, onClose, postId }) {
  const navigate = useNavigate();
  const { lang } = useContext(Language);
  const { postModalClose } = useContext(PostDetailContext);

  const [page, setPage] = useState(1);

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["usersLikedPostApi", postId, page],
    () => usersLikedPostApi({ post_id: postId, page })
  );

  if (isError) {
    toast.error(error.message);
  }

  useEffect(() => {
    refetch();
  }, [postId, page]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>{lang?.liked_users}</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Col className="my-4 text-center">
            <Spinner />
          </Col>
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => setPage(page + 1)}
            hasMore={data?.data?.current_page !== data?.data?.last_page}
            loader={
              <Col className="text-center">
                <Spinner />
              </Col>
            }
            useWindow={false}
          >
            {data?.data?.data.map((value, index) => (
              <div
                key={index}
                onClick={() => {
                  onClose();
                  postModalClose();
                  navigate(PROFILE + "/" + value.id);
                }}
                className="d-flex justify-content-between p-2 rounded text-dark manue-hover cursor"
              >
                <div className="d-flex gap-2">
                  <div
                    className="bg-white dropdown-avatar-shadow rounded align-self-center"
                    style={{ padding: "1.8px" }}
                  >
                    <ImageComponent
                      src={value?.image ? _imagesURL + value.image : Avatar}
                      compClassName="img-fluid rounded"
                      img_width={60}
                      img_height={60}
                    />
                  </div>
                  <div className="align-self-center">
                    <div className="roboto-bold text-dark fs-17">{value.name}</div>
                    <p className="roboto-regular mb-0 fs-12">{value.user_name}</p>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </ModalBody>
    </Modal>
  );
}